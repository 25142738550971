import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AppProvider } from "./context/AppProvider";
import LoginPage from "./pages/Loginpage";
import AccountPage from "./pages/AccountPage";
import Deliveries from "./pages/Deliveries";
import { auth } from "./firebase";
import { AuthContextProvider } from "./context/AuthContext";
import HomePage from "./pages/HomePage";
import ViewOrder from "./pages/ViewOrder";
import About from "./pages/About";
import Footer from "./components/Footer";
import ForgotPassword from "./components/ForgotPassword";
import { useTheme } from "./context/ThemeContext";

// Manager-only pages
import NewOrder from "./pages/NewOrder";
import EditOrder from "./pages/EditOrder";
import NewCustomer from "./pages/NewCustomer";
import SearchOrders from "./pages/SearchOrders";
import SearchInvoices from "./pages/SearchInvoices";
import NewInvoice from "./pages/NewInvoice";
import ViewInvoice from "./pages/ViewInvoice";
import RegisterPage from "./pages/RegisterPage";
import Users from "./pages/Users";
import ArchivedOrders from "./pages/ArchivedOrders";
import SearchForwarders from "./pages/SearchForwarders";

// Web Master only pages
import Issues from "./pages/Issues";

function App() {
  return (
    <AppProvider>
      <AuthContextProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route exact path="/" element={<Navigate to="/dashboard" />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/new_order"
                element={
                  <PrivateRoute>
                    <NewOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit/:orderId"
                element={
                  <PrivateRoute>
                    <EditOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/new_customer"
                element={
                  <PrivateRoute>
                    <NewCustomer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/new_invoice/:orderId"
                element={
                  <PrivateRoute>
                    <NewInvoice />
                  </PrivateRoute>
                }
              />

              <Route
                path="/view_invoice/:orderId"
                element={
                  <PrivateRoute>
                    <ViewInvoice />
                  </PrivateRoute>
                }
              />
              <Route
                path="/view_order/:orderId"
                element={
                  <PrivateRoute>
                    <ViewOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search_orders"
                element={
                  <PrivateRoute>
                    <SearchOrders />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search_invoices"
                element={
                  <PrivateRoute>
                    <SearchInvoices />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search_forwarders"
                element={
                  <PrivateRoute>
                    <SearchForwarders />
                  </PrivateRoute>
                }
              />
              <Route
                path="/deliveries"
                element={
                  <PrivateRoute>
                    <Deliveries />
                  </PrivateRoute>
                }
              />
              <Route
                path="/deliveries_archive"
                element={
                  <PrivateRoute>
                    <ArchivedOrders />
                  </PrivateRoute>
                }
              />

              <Route
                path="/register"
                element={
                  <PrivateRoute>
                    <RegisterPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                }
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path="/about"
                element={
                  <PrivateRoute>
                    <About />
                  </PrivateRoute>
                }
              />
              <Route
                path="/issues"
                element={
                  <PrivateRoute>
                    <Issues />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </AuthContextProvider>
    </AppProvider>
  );
}

// PrivateRoute component to handle authentication logic
function PrivateRoute({ children }) {
  const isAuthenticated = !!auth.currentUser;

  return isAuthenticated ? (
    React.cloneElement(children)
  ) : (
    <Navigate to="/login" />
  );
}

export default App;
