export const areaFees = {
  "Boca Raton": [
    "33427",
    "33428",
    "33429",
    "33431",
    "33432",
    "33433",
    "33434",
    "33464",
    "33481",
    "33486",
    "33487",
    "33488",
    "33496",
    "33497",
    "33498",
    "33499",
  ],
  "Palm Beach": [
    "33401",
    "33402",
    "33403",
    "33404",
    "33405",
    "33406",
    "33407",
    "33408",
    "33409",
    "33410",
    "33411",
    "33412",
    "33413",
    "33414",
    "33415",
    "33416",
    "33417",
    "33418",
    "33419",
    "33420",
    "33421",
    "33422",
    "33424",
    "33425",
    "33426",
    "33427",
    "33428",
    "33429",
    "33430",
    "33431",
    "33432",
    "33433",
    "33434",
    "33435",
    "33436",
    "33437",
    "33438",
    "33440",
    "33444",
    "33445",
    "33446",
    "33448",
    "33449",
    "33454",
    "33458",
    "33459",
    "33460",
    "33461",
    "33462",
    "33463",
    "33464",
    "33465",
    "33466",
    "33467",
    "33468",
    "33469",
    "33470",
    "33472",
    "33473",
    "33474",
    "33476",
    "33477",
    "33478",
    "33480",
    "33481",
    "33483",
    "33484",
    "33486",
    "33487",
    "33488",
    "33493",
    "33496",
    "33497",
    "33498",
    "33499",
  ],
  Kendall: [
    "33116",
    "33156",
    "33157",
    "33158",
    "33170",
    "33176",
    "33177",
    "33186",
    "33187",
    "33193",
    "33196",
    "33256",
  ],
};
