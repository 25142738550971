import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Form as BootstrapForm, Container, Row, Col } from "react-bootstrap";
import { Card, Elevation, Icon, Button, Switch } from "@blueprintjs/core";
import { useAuth } from "../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";

const Account = () => {
  const { user, getUserData, updateEmail, updatePassword, updateProfilePhoto } =
    useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.email) {
          await getUserData(user.email);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handlePasswordEdit = () => {
    setShowPasswordForm(true);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCancelEdit = () => {
    setShowPasswordForm(false);
    setNewPassword("");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // Prevent default form submission
    e.preventDefault();

    // Upload the selected file
    if (selectedFile) {
      await updateProfilePhoto(selectedFile);
    }

    if (newPassword.current.value !== confirmPassword.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");
    if (email.current.value !== user.email) {
      promises.push(updateEmail(email.current.value));
    }
    if (newPassword.current.value) {
      promises.push(updatePassword(newPassword.current.value));
    }

    Promise.all(promises)
      .then(() => {
        navigate("/account");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });

    try {
      setError("");
      setLoading(true);
      // Check if a new password is entered before updating
      if (values.newPassword) {
        await updatePassword(user.email, values.newPassword);

        setShowAlert(true); // Show alert on successful password update
      }
    } catch (error) {
      setError("Failed to update account");
    } finally {
      setSubmitting(false); // For Formik
    }
    setLoading(false);
  };

  return (
    <div>
      <Container className="p-3" style={{ minHeight: "100vh" }}>
        <Formik
          initialValues={{ newPassword: "", confirmPassword: "" }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, isSubmitting }) => (
            <BootstrapForm className="pt-3">
              <Row>
                <Card className="d-flex flex-wrap dashboard-col">
                  <div>
                    <h1>
                      {user ? user.firstName + "'s" : "My"}{" "}
                      <Icon icon="person" intent="primary" size={25} /> Account
                    </h1>
                    <img
                      src={
                        user && user.photoURL
                          ? user.photoURL
                          : "https://cdn.pixabay.com/photo/2021/01/20/20/30/alone-boy-5935497_1280.jpg"
                      }
                      alt="Profile picture"
                      height={200}
                      width={200}
                    />
                  </div>
                  <div className="align-content-center">
                    <h5>Profile Settings</h5>
                    <p>Dark Mode:</p>
                    <Switch disabled />
                  </div>
                </Card>

                <Row className="mb-3">
                  <Col md={3} sm="2">
                    <strong>
                      <BootstrapForm.Label>First Name</BootstrapForm.Label>
                    </strong>
                  </Col>
                  <Col md={9} sm="10">
                    <p>{user ? user.firstName : ""}</p>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={3} sm="2">
                    <strong>
                      <BootstrapForm.Label>Last Name</BootstrapForm.Label>
                    </strong>
                  </Col>
                  <Col md={9} sm="10">
                    <p>{user ? user.lastName : ""}</p>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={3} sm="2">
                    <strong>
                      <BootstrapForm.Label>Email</BootstrapForm.Label>
                    </strong>
                  </Col>
                  <Col md={9} sm="10">
                    <p>{user ? user.email : ""}</p>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={3} sm="2">
                    <strong>
                      <BootstrapForm.Label>Role</BootstrapForm.Label>
                    </strong>
                  </Col>
                  <Col md={9} sm="10">
                    <p>{user ? user.role : ""}</p>
                  </Col>
                </Row>
              </Row>

              {/* Change Password Button */}
              {!showPasswordForm && (
                <Row className="mb-3">
                  <Col md={3} sm="2"></Col>
                  <Col md={6} sm="8">
                    <Button
                      intent="primary"
                      icon="edit"
                      onClick={handlePasswordEdit}
                    >
                      Edit Profile
                    </Button>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              )}

              {/* Change Password Form */}
              {showPasswordForm && (
                <>
                  <Row className="mb-3">
                    <Col md={3} sm="2">
                      <strong>New Password</strong>
                    </Col>
                    <Col md={6} sm="8">
                      <BootstrapForm.Control
                        type="password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3} sm="2">
                      <strong>Confirm Password</strong>
                    </Col>
                    <Col md={6} sm="8">
                      <BootstrapForm.Control
                        type="password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3} sm="2">
                      <strong>Update Email</strong>
                    </Col>
                    <Col md={6} sm="8">
                      <BootstrapForm.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  {/* File input for selecting a new profile photo */}
                  <Row>
                    <Col md={3} sm="2">
                      <strong>Profile Photo (disabled for now)</strong>
                    </Col>
                    <Col md={9} sm="10">
                      <BootstrapForm.Group
                        controlId="formFile"
                        className="mb-3"
                      >
                        <BootstrapForm.Control
                          type="file"
                          disabled
                          onChange={handleFileChange}
                        />
                      </BootstrapForm.Group>
                    </Col>
                  </Row>

                  {/* Submit button for updating profile photo */}
                  <Row className="mb-3">
                    <Col md={3} sm="2"></Col>
                    <Col md={6} sm="8">
                      <Button
                        intent="primary"
                        type="submit"
                        disabled={isSubmitting || !selectedFile}
                      >
                        Update Profile Photo
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={3} sm="2"></Col>
                    <Col md={6} sm="8">
                      <Button
                        intent="warning"
                        outlined={true}
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        intent="success"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save Changes
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </BootstrapForm>
          )}
        </Formik>
        {showAlert && (
          <Alert
            variant="success"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Password updated successfully!
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default Account;
