import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <Container className="mt-3 mb-3">
      <div className="d-flex flex-wrap justify-content-between">
        <small>&copy; 2024. Hephium, LLC.</small>
        <small>Licensed to Quick Delivery, LLC.</small>
      </div>
    </Container>
  );
}

export default Footer;
