import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { getLateOrders } from "../../javascript/api";
import formatDate from "../../utility/formatDate";
import Pagination10 from "../Pagination10";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardList,
  Section,
  SectionCard,
  Elevation,
  Icon,
  Tooltip,
  Spinner,
} from "@blueprintjs/core";

const MyOverDueOrders = () => {
  const [myOverDueOrders, setMyOverDueOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(3);
  const containerRef = useRef(null); // Ref for the container element
  const [loading, setLoading] = useState(true); // Track loading state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyOverDueOrders = async () => {
      try {
        const orders = await getLateOrders();
        setMyOverDueOrders(orders);
        setLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error("Error fetching logged-in user's orders:", error);
      }
    };

    fetchMyOverDueOrders();
  }, []);

  // Calculate the indexes of the orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = myOverDueOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const resultCount =
    myOverDueOrders.length > 0
      ? `${Math.min(indexOfFirstOrder + 1, myOverDueOrders.length)}-${Math.min(
          indexOfLastOrder,
          myOverDueOrders.length
        )} of ${myOverDueOrders.length}`
      : "";

  return (
    <>
      <div ref={containerRef}>
        {loading ? ( // Display loading indicator while data is being fetched
          <>
            Loading... <Spinner intent="primary" />
          </>
        ) : myOverDueOrders.length === 0 ? ( // Check if there are no deliveries
          <Card>
            <div className="text-center">
              <p>You have no late deliveries.</p>
            </div>
          </Card>
        ) : (
          <>
            {currentOrders.map((order) => (
              <Section
                key={order.id}
                title={`Order: ${order.orderNumber}`}
                compact={true}
                className="mb-2"
                collapsible={true}
                rightElement={
                  <Button
                    minimal={true}
                    intent="primary"
                    onClick={() => {
                      navigate(`/view_order/${order.id}`);
                    }}
                    text="View Order"
                  />
                }
              >
                <SectionCard>
                  <Row>
                    <Col md={6} sm={12}>
                      <p>
                        <strong>Status: </strong>
                        {order.status}{" "}
                      </p>
                    </Col>

                    <Col md={6} sm={12}>
                      <p>
                        <strong>Delivery Due: </strong>
                        {formatDate(order.deliveryDate)}{" "}
                        <Badge bg="warning" text="dark" pill>
                          Late
                        </Badge>
                      </p>
                    </Col>

                    <Col md={12} sm={12}>
                      <p>
                        <strong>Forwarder Company Name: </strong>
                        {order.selectedForwarder.forwarderCompanyName}
                      </p>
                    </Col>

                    <Col md={6} sm={12}>
                      <p>
                        <strong>Forwarder First Name: </strong>{" "}
                        {order.forwarderFirstName}
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <strong>Forwarder Last Name: </strong>
                        {order.forwarderLastName}
                      </p>
                    </Col>

                    <Col md={12} sm={12}>
                      <p>
                        <strong>Forwarder Address: </strong>
                        {order.selectedForwarder.forwarderAddress}
                      </p>
                    </Col>
                    <Col md={12} sm={12}>
                      <p>
                        {order.selectedForwarder.forwarderAddressCity},{" "}
                        {order.selectedForwarder.forwarderAddressState}{" "}
                        {order.selectedForwarder.forwarderAddressZip}
                      </p>
                    </Col>

                    <Col md={6} sm={12}>
                      <p>
                        <strong>Primary Phone: </strong>
                        {order.selectedForwarder.forwarderPrimaryPhone}
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <strong>Secondary Phone: </strong>
                        {order.selectedForwarder.forwarderSecondaryyPhone}
                      </p>
                    </Col>

                    <Col>
                      <p>
                        <strong>Order Notes: </strong>
                        {order.notes}
                      </p>
                    </Col>
                  </Row>

                  {/* Add more order details as needed */}
                </SectionCard>
              </Section>
            ))}
          </>
        )}

        <div className="container">
          {resultCount && <p>Showing results {resultCount}</p>}
          {myOverDueOrders.length > ordersPerPage && (
            <Pagination10
              currentPage={currentPage}
              ordersPerPage={ordersPerPage}
              totalOrders={myOverDueOrders.length}
              paginate={paginate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MyOverDueOrders;
