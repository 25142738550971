import React from "react";
import MyOrders from "../../components/driverView/MyOrders";
import OrderCount from "./OrderCount";
import MyOverDueOrders from "./MyOverDueOrders";
import MyUpcomingDeliveries from "./MyUpcomingDeliveries";
import { Container, Row, Col } from "react-bootstrap";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import { useAuth } from "../../context/AuthContext";

function Home() {
  const { user } = useAuth();

  return (
    <Container fluid className="pt-3" style={{ minHeight: "100vh" }}>
      <div className="text-center">
        <h2>{user.firstName}'s Dashboard</h2>
      </div>
      <Row>
        <Col>
          <div>
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <h4 className=" text-center">Todays Deliveries </h4>

              <OrderCount user={user} />
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <h4 className="text-center">Late Deliveries</h4>
              <MyOverDueOrders user={user} />
            </Card>
          </div>
        </Col>
        <Col>
          <div>
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <h4 className="text-center">Upcoming Deliveries</h4>

              <MyUpcomingDeliveries user={user} />
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
