import React, { useEffect, useState, useRef } from "react";
import { ButtonGroup, ListGroup } from "react-bootstrap";
import { getDeliveredOrders } from "../../javascript/api";
import formatDate from "../../utility/formatDate";
import Pagination10 from "../Pagination10";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardList,
  Elevation,
  Icon,
  Tooltip,
  Section,
  SectionCard,
} from "@blueprintjs/core";

const DeliveredOrders = () => {
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(3);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeliveredOrders = async () => {
      try {
        const orders = await getDeliveredOrders();
        setDeliveredOrders(orders);
      } catch (error) {
        console.error("Error fetching delivered orders:", error);
      }
    };

    fetchDeliveredOrders();
  }, []);

  const handleCreateInvoice = (order) => {
    if (order) {
      // Navigate to the invoice page with the order ID as a parameter
      navigate(`/new_invoice/${order.id}`);
    } else {
      console.error("Invalid or missing order data for creating an invoice.");
    }
  };

  // Calculate the indexes of the orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = deliveredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the container element
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const resultCount =
    deliveredOrders.length > 0
      ? `${Math.min(indexOfFirstOrder + 1, deliveredOrders.length)}-${Math.min(
          indexOfLastOrder,
          deliveredOrders.length
        )} of ${deliveredOrders.length}`
      : "";

  return (
    <>
      <div ref={containerRef}>
        {currentOrders.length === 0 ? ( // Check if there are no overdue orders
          <Card>
            <div className="text-center">
              <p>There are no orders awaiting invoicing.</p>
            </div>
          </Card>
        ) : (
          <>
            {currentOrders.map((order) => (
              <Section
                key={order.id}
                title={`Order Number: ${order.orderNumber}`}
                collapsible={true}
                compact={true}
                className="mb-2"
                rightElement={
                  <Button
                    minimal={true}
                    intent="primary"
                    onClick={() => navigate(`/view_order/${order.id}`)}
                    text="View Order"
                  />
                }
              >
                <SectionCard>
                  <p>
                    <strong>Status: </strong>
                    {order.status}
                  </p>

                  <p>
                    <strong>Delivery Date: </strong>
                    {formatDate(order.deliveryDate)}
                  </p>

                  <p>
                    <strong>Amount Due: </strong>$
                    {order.calculatedRates.totalCharge}
                  </p>
                </SectionCard>
                <ButtonGroup>
                  <Button
                    onClick={() => handleCreateInvoice(order)}
                    intent="success"
                    icon="document"
                    minimal={true}
                  >
                    Create Invoice
                  </Button>
                </ButtonGroup>
              </Section>
            ))}
          </>
        )}

        <div className="container">
          {resultCount && <p>Showing results {resultCount}</p>}
          {deliveredOrders.length > ordersPerPage && (
            <Pagination10
              currentPage={currentPage}
              ordersPerPage={ordersPerPage}
              totalOrders={deliveredOrders.length}
              paginate={paginate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveredOrders;
