import { React, useState, useEffect, useMemo } from "react";
import { useAuth } from "../../context/AuthContext";
import { Container, Row, Col } from "react-bootstrap";
import InvoicedOrders from "./InvoicedOrders";
import DeliveredOrders from "./DeliveredOrders";
import MyOverDueOrders from "../driverView/MyOverDueOrders";
import GetUsers from "./GetUsers";
import GetAdmins from "./GetAdmins";
import ReactApexChart from "react-apexcharts";
import {
  Button,
  ButtonGroup,
  Switch,
  Icon,
  Card,
  CardList,
} from "@blueprintjs/core";
import { userData, receivablesData } from "../../javascript/chartData";
import {
  getActiveDrivers,
  getBillableOrders,
  getDueInvoices,
} from "../../javascript/api";
import { useTheme } from "../../context/ThemeContext";

const ManagerDashboard = () => {
  const { user } = useAuth();
  const [userCount, setUserCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [driverCount, setDriverCount] = useState(0);
  const [billableTotal, setBillableTotal] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [totals, setTotals] = useState(0);
  const [showUserData, setShowUserData] = useState(true);
  const [showReceivablesData, setShowReceivablesData] = useState(true);
  const [showUserActions, setShowUserActions] = useState(true);
  const [showLateOrders, setShowLateOrders] = useState(true);
  const [showUnpaidInvoices, setShowUnpaidInvoices] = useState(true);
  const [showNeedInvoiced, setShowNeedInvoiced] = useState(true);
  const { isDarkMode } = useTheme();

  // Memoize chartData to avoid unnecessary recalculations
  const chartData = useMemo(
    () =>
      userData(
        userCount,
        adminCount,
        totalUsers,
        isDarkMode ? "dark" : "light"
      ),
    [userCount, adminCount, totalUsers, isDarkMode]
  );

  const receivablesChartData = useMemo(
    () =>
      receivablesData(
        totals,
        billableTotal,
        unpaidTotal,
        isDarkMode ? "dark" : "light"
      ),
    [totals, billableTotal, unpaidTotal, isDarkMode]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch active drivers count
        const count = await getActiveDrivers();
        setDriverCount(count.length);

        // Fetch billable orders
        const billableOrders = await getBillableOrders();
        let billableTotal = 0;

        // Calculate total amount from billable orders
        billableOrders.forEach((order) => {
          billableTotal += parseFloat(order.calculatedRates.totalCharge);
        });

        // Fetch unpaid invoices
        const dueInvoices = await getDueInvoices();
        let unpaidTotal = 0;

        // Calculate total amount from unpaid invoices
        dueInvoices.forEach((order) => {
          unpaidTotal += parseFloat(order.calculatedRates.totalCharge);
        });

        // Calculate grand total including both billable orders and unpaid invoices
        const grandTotal = billableTotal + unpaidTotal;

        // Set the totals state with the grand total
        setBillableTotal(billableTotal);
        setUnpaidTotal(unpaidTotal);
        setTotals(grandTotal);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userCount, adminCount]); // Update when userCount or adminCount changes

  useEffect(() => {
    // Update total users count whenever userCount or adminCount changes
    setTotalUsers(userCount + adminCount);
  }, [userCount, adminCount]); // Update totalUsers when userCount or adminCount changes

  const handleUserCount = (count) => {
    setUserCount(count);
  };

  const handleAdminCount = (count) => {
    setAdminCount(count);
  };

  // Function to toggle visibility of user data
  const toggleUserData = () => {
    setShowUserData(!showUserData);
  };

  // Function to toggle visibility of user actions
  const toggleUserActions = () => {
    setShowUserActions(!showUserActions);
  };

  // Function to toggle visibility of late orders
  const toggleLateOrders = () => {
    setShowLateOrders(!showLateOrders);
  };

  // Function to toggle visibility of unpaid invoices
  const toggleUnpaidInvoices = () => {
    setShowUnpaidInvoices(!showUnpaidInvoices);
  };

  // Function to toggle visibility of need invoiced
  const toggleNeedInvoiced = () => {
    setShowNeedInvoiced(!showNeedInvoiced);
  };

  // Function to toggle visibility of receivables
  const toggleReceivables = () => {
    setShowReceivablesData(!showReceivablesData);
  };

  return (
    <Container fluid className="pt-3" style={{ minHeight: "100vh" }}>
      <div className="text-center">
        <h2>{user.firstName}'s Dashboard</h2>
      </div>
      {/* Checkboxes to toggle visibility */}
      <div className="d-flex flex-wrap justify-content-center mb-2">
        <Switch
          label={showUserData ? "User Data" : "Show User Data"}
          checked={showUserData}
          onChange={toggleUserData}
          className="me-2"
        />
        <Switch
          label={showUserActions ? "User Actions" : "Show User Actions"}
          checked={showUserActions}
          onChange={toggleUserActions}
          className="me-2"
        />
        <Switch
          label={showLateOrders ? "Late Orders" : "Show Late Orders"}
          checked={showLateOrders}
          onChange={toggleLateOrders}
          className="me-2"
        />
        <Switch
          label={
            showUnpaidInvoices ? "Unpaid Invoices" : "Show Unpaid Invoices"
          }
          checked={showUnpaidInvoices}
          onChange={toggleUnpaidInvoices}
          className="me-2"
        />
        <Switch
          label={showNeedInvoiced ? "Need Invoiced" : "Show Need Invoiced"}
          checked={showNeedInvoiced}
          onChange={toggleNeedInvoiced}
          className="me-2"
        />
        <Switch
          label={showReceivablesData ? "Receivables" : "Show Receivables"}
          checked={showReceivablesData}
          onChange={toggleReceivables}
        />
      </div>

      <Row>
        {showUserData && (
          <Col lg={4} md={6} sm={12}>
            <Card className="dashboard-col" elevation={3}>
              <div className=" text-center ">
                <h4>Users</h4>
                <Icon icon="people" size="40" color="#0F6894" />
                <h1>{totalUsers}</h1>
              </div>
            </Card>
          </Col>
        )}
        {showUserData && (
          <Col lg={4} md={6} sm={12}>
            <Card className="dashboard-col" elevation={3}>
              <div className="text-center ">
                <h4>Drivers</h4>
                <Icon icon="truck" size="40" color="#D1980B" />
                <h1>{driverCount}</h1>
              </div>
            </Card>
          </Col>
        )}
        {showReceivablesData && (
          <Col lg={4} md={6} sm={12}>
            <Card className="dashboard-col" elevation={3}>
              <div className="text-center">
                <h4>Receivables Due</h4>
                <Icon icon="dollar" size="40" color="#238C2C" />
                <h1>${totals.toFixed(2)}</h1>
              </div>
            </Card>
          </Col>
        )}
      </Row>

      <Row className="d-flex">
        {showUserData && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">User Data</h4>
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={300}
                />
                <Button minimal={true} icon="eye-off" onClick={toggleUserData}>
                  {showUserData ? "Hide" : "Show"}
                </Button>
              </div>
            </Card>
          </Col>
        )}
        {showReceivablesData && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">Receivables Due Breakdown</h4>

                <ReactApexChart
                  options={receivablesChartData.options}
                  series={receivablesChartData.series}
                  type="donut"
                  height={300}
                />
                <Button
                  minimal={true}
                  icon="eye-off"
                  onClick={toggleReceivables}
                >
                  {showReceivablesData ? "Hide" : "Show"}
                </Button>
              </div>
            </Card>
          </Col>
        )}
        {showUserActions && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">User Actions</h4>

                <GetUsers onUserCount={handleUserCount} />
                <GetAdmins onAdminCount={handleAdminCount} />
                <Button
                  icon="eye-off"
                  minimal={true}
                  onClick={toggleUserActions}
                >
                  {showUserActions ? "Hide" : "Show"}
                </Button>
              </div>
            </Card>
          </Col>
        )}
        {showLateOrders && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">Late Orders</h4>

                <MyOverDueOrders />

                <Button
                  minimal={true}
                  icon="eye-off"
                  onClick={toggleLateOrders}
                >
                  {showLateOrders ? "Hide " : "Show "}
                </Button>
              </div>
            </Card>
          </Col>
        )}
        {showUnpaidInvoices && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">Unpaid Invoices</h4>

                <InvoicedOrders />
                <Button
                  minimal={true}
                  icon="eye-off"
                  onClick={toggleUnpaidInvoices}
                >
                  {showUnpaidInvoices ? "Hide" : "Show"}
                </Button>
              </div>
            </Card>
          </Col>
        )}
        {showNeedInvoiced && (
          <Col lg={6} md={6} sm={12} className="d-flex">
            <Card className="dashboard-col flex-grow-1" elevation={3}>
              <div>
                <h4 className="text-center">Need Invoiced</h4>

                <DeliveredOrders />
                <Button
                  minimal={true}
                  icon="eye-off"
                  onClick={toggleNeedInvoiced}
                >
                  {showNeedInvoiced ? "Hide" : "Show"}
                </Button>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ManagerDashboard;
