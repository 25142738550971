import React, { useState, useEffect } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import RateCalculator from "./RateCalculator";

import { Tooltip, Button } from "@blueprintjs/core";

const OrderPieces = ({
  pieces,
  setPieces,
  setCalculatedRates,
  forwarderAddressZip,
  selectedZipcode,
}) => {
  const [total, setTotal] = useState(0);
  const [showCubicFeetModal, setShowCubicFeetModal] = useState(false);

  // State for the calculator inputs
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [cubicFeetResult, setCubicFeetResult] = useState(null);

  useEffect(() => {
    calculateTotal();
  }, [pieces]);

  const calculateTotal = () => {
    let newTotal = 0;
    pieces.forEach((piece) => {
      newTotal += piece.volume * piece.quantity;
    });
    setTotal(newTotal);
  };

  const calculateTotalCharge = () => {
    // Implement the logic to calculate total charge based on the rate sheet
    // Use the 'pieces' prop to get the necessary information for calculation

    // Example calculation (replace it with the actual logic):
    const pickUpDeliveryCharge = 60;
    const fuelSurcharge = 5;
    const extraFeePalmBeach = 162.45;
    const tropicalShippingFee = 35;
    const claimCheck = 25;
    const liftgate = 50;
    const rejectedReturn = 60;

    const totalCharge =
      pickUpDeliveryCharge +
      fuelSurcharge +
      extraFeePalmBeach +
      tropicalShippingFee +
      claimCheck +
      liftgate +
      rejectedReturn;

    // Update the total charge in the state
    setTotal(totalCharge);
  };

  const handleAddPiece = () => {
    const lastPiece = pieces[pieces.length - 1];
    if (
      !lastPiece ||
      lastPiece.sku ||
      lastPiece.description ||
      lastPiece.price ||
      lastPiece.volume ||
      lastPiece.weight ||
      lastPiece.quantity
    )
      setPieces([
        ...pieces,
        {
          id: Date.now(),
          sku: "",
          description: "",
          price: "",
          volume: "",
          weight: "",
          quantity: "",
          isEditing: true,
        },
      ]);
  };

  const handleEditPiece = (id) => {
    const updatedPieces = pieces.map((piece) =>
      piece.id === id ? { ...piece, isEditing: true } : piece
    );
    setPieces(updatedPieces);
  };

  const handleSavePiece = (id) => {
    const updatedPieces = pieces.map((piece) =>
      piece.id === id ? { ...piece, isEditing: false } : piece
    );
    setPieces(updatedPieces);
  };

  const handleInputChange = (id, field, value) => {
    const updatedPieces = pieces.map((piece) =>
      piece.id === id ? { ...piece, [field]: value } : piece
    );
    setPieces(updatedPieces);
  };

  const handlePieceChange = (id, field, value) => {
    const updatedPieces = pieces.map((piece) =>
      piece.id === id ? { ...piece, [field]: value } : piece
    );
    setPieces(updatedPieces);
  };

  const handleDeletePiece = (id) => {
    const updatedPieces = pieces.filter((piece) => piece.id !== id);
    setPieces(updatedPieces);
  };

  // Function to handle opening and closing the cubic feet calculator modal
  const handleCubicFeetModal = () => {
    setShowCubicFeetModal(!showCubicFeetModal);
  };

  // Function to handle cubic feet calculation
  const calculateCubicFeet = () => {
    // Implement the cubic feet calculation logic here
    const lengthValue = parseFloat(length);
    const widthValue = parseFloat(width);
    const heightValue = parseFloat(height);

    if (!isNaN(lengthValue) && !isNaN(widthValue) && !isNaN(heightValue)) {
      const result = lengthValue * widthValue * heightValue;
      setCubicFeetResult(result);
    } else {
      // Handle invalid input
      setCubicFeetResult(null);
    }
  };

  return (
    <div>
      {/* Cubic Feet Calculator Modal */}
      <Modal show={showCubicFeetModal} onHide={handleCubicFeetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cubic Feet Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Length</Form.Label>
            <Form.Control
              type="number"
              value={length}
              onChange={(e) => setLength(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Width</Form.Label>
            <Form.Control
              type="number"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Height</Form.Label>
            <Form.Control
              type="number"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </Form.Group>
          <Button
            intent="primary"
            className="mt-3 mb-2"
            onClick={calculateCubicFeet}
          >
            Calculate Cubic Feet
          </Button>
          {cubicFeetResult !== null && (
            <p>
              <strong>Cubic Feet: {cubicFeetResult.toFixed(2)}</strong>
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Button
        className="mb-2"
        icon="calculator"
        intent="primary"
        onClick={handleCubicFeetModal}
      >
        Cubic Feet Calculator
      </Button>

      {pieces.map((piece) => (
        <div key={piece.id} className="col-sm-12 mb-3 ">
          <Row className="d-flex">
            <Col md={2}>
              <label className="mb-2">
                <strong>SKU</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    type="text"
                    size="sm"
                    value={piece.sku}
                    onChange={(e) =>
                      handleInputChange(piece.id, "sku", e.target.value)
                    }
                  />
                ) : (
                  piece.sku
                )}
              </div>
            </Col>
            <Col md={2}>
              <label className="mb-2">
                <strong>Description</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    size="sm"
                    type="text"
                    value={piece.description}
                    onChange={(e) =>
                      handleInputChange(piece.id, "description", e.target.value)
                    }
                  />
                ) : (
                  piece.description
                )}
              </div>
            </Col>
            <Col md={1}>
              <label className="mb-2">
                <strong>Price ($)</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    size="sm"
                    type="number"
                    value={piece.price}
                    onChange={(e) =>
                      handleInputChange(
                        piece.id,
                        "price",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                ) : (
                  piece.price
                )}
              </div>
            </Col>
            <Col md={2}>
              <label className="mb-2">
                <strong>Volume (cu ft)</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    size="sm"
                    type="number"
                    value={piece.volume}
                    onChange={(e) =>
                      handleInputChange(
                        piece.id,
                        "volume",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                ) : (
                  piece.volume
                )}
              </div>
            </Col>
            <Col md={2}>
              <label className="mb-2">
                <strong>Wt (lbs)</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    size="sm"
                    type="number"
                    value={piece.weight}
                    onChange={(e) =>
                      handleInputChange(
                        piece.id,
                        "weight",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                ) : (
                  piece.weight
                )}
              </div>
            </Col>
            <div className="col-sm-1">
              <label className="mb-2">
                <strong>Qty</strong>
              </label>
              <div>
                {piece.isEditing ? (
                  <Form.Control
                    size="sm"
                    type="number"
                    value={piece.quantity}
                    onChange={(e) =>
                      handleInputChange(
                        piece.id,
                        "quantity",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                ) : (
                  piece.quantity
                )}
              </div>
            </div>
            <div className="col-sm-2 d-flex ">
              {piece.isEditing ? (
                <div className="d-flex align-items-end">
                  <Button
                    intent="success"
                    icon="confirm"
                    outlined={true}
                    onClick={() => {
                      handleSavePiece(piece.id);
                      calculateTotalCharge(); // Recalculate total on save
                    }}
                  >
                    Save
                  </Button>{" "}
                </div>
              ) : (
                <>
                  <Button
                    intent="warning"
                    icon="edit"
                    outlined={true}
                    onClick={() => handleEditPiece(piece.id)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    intent="danger"
                    icon="trash"
                    onClick={() => handleDeletePiece(piece.id)}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </Row>
        </div>
      ))}

      <div className="row">
        <div className="col-sm-12">
          <Tooltip
            intent="primary"
            content="disabled if a new piece line is already available"
          >
            <Button
              icon="cube-add"
              outlined={true}
              onClick={handleAddPiece}
              intent="success"
              className="mb-3"
            >
              Add Piece
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default OrderPieces;
