import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import Header from "./Header";

const changePasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setMessage("");
      setErrorMessage("");
      setLoading(true);
      await resetPassword(values.email);
      setMessage("Check your inbox for further instructions");
      resetForm();
    } catch (error) {
      setErrorMessage("Failed to reset password: ", error.message);
    } finally {
      setSubmitting(false);
    }
    setLoading(false);
  };

  return (
    <div className="wrapper">
      <Container className="mt-3">
        <Header />
        <Col>
          <Row>
            {message && <Alert variant="success">{message}</Alert>}
            <Formik
              initialValues={{ email: "" }}
              validationSchema={changePasswordSchema}
              onSubmit={handleSubmit}
            >
              <Form className="p-3">
                <h1>Password Reset</h1>

                <Row className="mb-3">
                  <Field
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                    style={{ color: "red" }}
                  />
                </Row>

                <Row>
                  <Button
                    type="submit"
                    variant="primary"
                    className="mb-2"
                    disabled={loading}
                  >
                    Reset Password
                  </Button>
                </Row>
              </Form>
            </Formik>
            <div className="text-center">
              <Link to="/login">Back to login</Link>
            </div>
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default ForgotPassword;
