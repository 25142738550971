import React from "react";
import Navigation from "../components/managerView/Navigation";
import GetInvoices from "../components/managerView/GetInvoices";

const SearchInvoices = () => {
  return (
    <div>
      <Navigation />
      <div className="mt-3 container">
        <GetInvoices />
      </div>
    </div>
  );
};

export default SearchInvoices;
