import React from "react";
import Navigation from "../components/managerView/Navigation";

const Issues = () => {
  return (
    <div>
      <Navigation />
      <div className="mt-2 text-center">
        <h1>Bugs Reported</h1>
      </div>
    </div>
  );
};

export default Issues;
