// ViewOrderDetails.jsx
import React, { useEffect } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import {
  Card,
  Section,
  SectionCard,
  Elevation,
  Button,
  Divider,
  Popover,
  ButtonGroup,
} from "@blueprintjs/core";
import formatDate from "../utility/formatDate";
import { useNavigate } from "react-router-dom";

const ViewOrderDetails = ({ order, handleCaptureSignature }) => {
  const navigate = useNavigate();
  const goBack = () => {
    window.history.back();
  };

  const renderField = (label, value) => {
    if (
      value != "undefined" &&
      value != "undefined undefined" &&
      value != "undefined, undefined undefined" &&
      value != undefined
    ) {
      return (
        value && (
          <>
            {label}: {value}
            <br />
          </>
        )
      );
    }
  };

  return (
    <Container style={{ minHeight: "100vh" }} className="pb-3">
      <div className="d-flex justify-content-between pt-3">
        <h2>Order Details</h2>
        <Button
          icon="circle-arrow-left"
          id="back-button-order-details"
          minimal={true}
          text="Back"
          intent="primary"
          onClick={() => goBack()}
        />
      </div>
      <Section
        title={`Order Number: ${order.orderNumber}`}
        rightElement={`Status: ${order.status}`}
        className=" order-card-styling"
      >
        <SectionCard>
          <Col className="d-flex justify-content-end">
            <Button
              minimal={true}
              icon="edit"
              intent="primary"
              onClick={() => navigate(`/edit/${order.id}`)}
            >
              Edit Order
            </Button>
          </Col>

          <Row>
            <Col md={6} className="mb-2">
              <Section>
                <Card>
                  <p>
                    {" "}
                    <strong>Customer Info</strong>
                  </p>
                  {renderField(
                    "Customer Name",
                    `${order?.customerFirstName} ${order?.customerLastName}`
                  )}
                  {renderField("Company Name", order?.customerCompanyName)}
                  {renderField("Address", order?.customerAddress)}
                  {renderField(
                    "Address Line 2",
                    `${order?.customerAddressCity}, ${order?.customerAddressState} ${order?.customerAddressZip}`
                  )}
                </Card>
              </Section>
            </Col>
            <Col md={6} className="mb-2">
              <Section>
                <Card>
                  <p>
                    <strong>Forwarder Info</strong>
                  </p>
                  {renderField(
                    "Forwarder Name",
                    order?.forwarderFirstName
                      ? `${order?.forwarderFirstName} ${order?.forwarderLastName}`
                      : order?.selectedForwarder
                      ? `${order?.selectedForwarder.forwarderFirstName} ${order?.selectedForwarder.forwarderLastName}`
                      : "N/A"
                  )}
                  {renderField(
                    "Company Name",
                    order?.forwarderCompanyName
                      ? order?.forwarderCompanyName
                      : order?.selectedForwarder?.forwarderCompanyName
                      ? order.selectedForwarder.forwarderCompanyName
                      : "N/A"
                  )}
                  {renderField(
                    "Address",
                    order?.forwarderAddress
                      ? order?.forwarderAddress
                      : order?.selectedForwarder
                      ? order.selectedForwarder.forwarderAddress
                      : "N/A"
                  )}
                  {renderField(
                    "Address Line 2",
                    order?.forwarderAddressCity
                      ? `${order?.forwarderAddressCity}, ${order?.forwarderAddressState} ${order?.forwarderAddressZip}`
                      : order?.selectedForwarder
                      ? `${order?.selectedForwarder.forwarderAddressCity}, ${order?.selectedForwarder.forwarderAddressState} ${order?.selectedForwarder.forwarderAddressZip}`
                      : "N/A"
                  )}
                </Card>
              </Section>
            </Col>
          </Row>
          {/* Customer details section */}
          <Row>
            <Col>
              <Section className="mb-2">
                <Card>
                  <p>
                    <strong>Pieces</strong>
                  </p>
                  {order &&
                  Array.isArray(order.pieces) &&
                  order.pieces.length > 0 ? (
                    <div>
                      <Row className="d-flex flex-wrap">
                        <Col md={2} sm={12}>
                          <strong>Sku</strong>
                        </Col>
                        <Col md={2} sm={12}>
                          <strong>Description</strong>
                        </Col>
                        <Col md={2} sm={12}>
                          <strong>Volume (cu ft)</strong>
                        </Col>
                        <Col md={2} sm={12}>
                          <strong>Weight (lbs)</strong>
                        </Col>
                        <Col md={2} sm={12}>
                          <strong>Quantity</strong>
                        </Col>{" "}
                        <Col md={2} sm={12}>
                          <strong>Price</strong>
                        </Col>
                        <Divider />
                      </Row>
                      {order.pieces.map((piece, index) => (
                        <div
                          key={index}
                          style={{ border: "none", padding: "0" }}
                        >
                          {/* Access properties of each piece */}

                          <Row>
                            <Col>{piece.sku}</Col>
                            <Col>{piece.description}</Col>
                            <Col>{piece.volume}</Col>
                            <Col>{piece.weight}</Col>
                            <Col>{piece.quantity}</Col>
                            <Col>${piece.price}</Col>
                          </Row>
                          <br />
                          {/* Add more properties as needed */}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No pieces available</p>
                  )}

                  <Row>
                    <Col md={6}>
                      Total Pieces:{" "}
                      {Array.isArray(order.pieces)
                        ? parseInt(
                            order.pieces.reduce(
                              (totalQuantity, piece) =>
                                totalQuantity + parseInt(piece.quantity, 10),
                              0
                            ),
                            10
                          )
                        : 0}
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                </Card>
              </Section>

              <Card className="mb-2">
                {order.calculatedRates &&
                  Array.isArray(order.calculatedRates.fees) &&
                  order.calculatedRates.fees.length > 0 && (
                    <div>
                      <p>
                        <strong>Fees</strong>
                      </p>
                      <ul className="p-0" style={{ listStyle: "none" }}>
                        {/* Include delivery fee and total charge in the list */}
                        <li>
                          <Row>
                            <Col md={6}>Delivery Fee:</Col>
                            <Col
                              md={6}
                            >{`$${order.calculatedRates.deliveryFee.toFixed(
                              2
                            )}`}</Col>
                          </Row>
                        </li>

                        {/* Include fees from calculatedRates.fees array */}
                        {order.calculatedRates.fees.map((fee, feeIndex) => (
                          <li key={feeIndex}>
                            <Row>
                              <Col md={6}>{fee.name}:</Col>
                              <Col md={6}>{`$${fee.price.toFixed(2)}`}</Col>
                            </Row>
                            {/* Add more properties as needed */}
                          </li>
                        ))}

                        <li>
                          <Row className="mt-2">
                            <Divider />
                            <Col md={6}>
                              <p>
                                <strong>Total Charge:</strong>
                              </p>
                            </Col>
                            <Col
                              md={6}
                            >{`$${order.calculatedRates.totalCharge}`}</Col>
                          </Row>
                        </li>
                      </ul>
                    </div>
                  )}
              </Card>
            </Col>
          </Row>

          <Card>
            <Row>
              <Col>
                <p>
                  <strong>Additional Info</strong>
                </p>
                <Row>
                  <Col md={6}>Notes: {order?.notes}</Col>
                  <Col md={6}>Status: {order?.status}</Col>
                  <Col md={6}>Assigned Driver: {order?.assignedDriver}</Col>
                  <Col md={6}>
                    {order?.files ? (
                      <Button
                        variant="link"
                        onClick={() => window.open(order?.files, "_blank")}
                      >
                        View Attachment
                      </Button>
                    ) : (
                      <p>No attachments</p>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    Driver Adjustment Rate: $
                    {order.calculatedRates.driverFeeAdjustment}
                  </Col>
                  <Col md={6}>
                    Adjustment Percentage:{" "}
                    {order.calculatedRates.driverFeeMultiplier}%
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    Delivered On: {formatDate(order?.deliveryDate)}
                  </Col>
                  <Col md={6}>
                    {order.signature ? (
                      <Col>
                        Signed by:{" "}
                        <img
                          src={order.signature}
                          alt="Signature"
                          width={200}
                          height="auto"
                        />
                      </Col>
                    ) : (
                      <p> No signature on file</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </SectionCard>
      </Section>
    </Container>
  );
};

export default ViewOrderDetails;
