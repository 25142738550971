import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import EditOrderDetails from "../components/managerView/EditOrderDetails";
import Navigation from "../components/managerView/Navigation";
import { useTheme } from "../context/ThemeContext";

const EditOrder = (props) => {
  const { orderId } = useParams();
  const { isDarkMode } = useTheme();
  const [editedOrder, setEditedOrder] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderDoc = await getDoc(doc(firestore, "orders", orderId));
        if (orderDoc.exists()) {
          setEditedOrder({ id: orderId, ...orderDoc.data() });
        } else {
          console.error("Order not found");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const { activeDrivers, handleSaveChanges, handleCancelChanges } = props;

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      <Navigation />
      <div>
        <EditOrderDetails
          editedOrder={editedOrder}
          setEditedOrder={setEditedOrder}
          activeDrivers={activeDrivers}
          handleSaveChanges={handleSaveChanges}
          handleCancelChanges={handleCancelChanges}
        />
      </div>
    </div>
  );
};

export default EditOrder;
