import React from "react";
import Register from "../components/managerView/Register";

const RegisterPage = () => {
  return (
    <div className="wrapper">
      <Register />
    </div>
  );
};

export default RegisterPage;
