import { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

const FetchOrdersComponent = ({ setOrders, handleFilterOrders }) => {
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersCollection = collection(firestore, "orders");
        const ordersSnapshot = await getDocs(ordersCollection);
        const ordersData = [];

        ordersSnapshot.forEach((doc) => {
          const orderData = {
            id: doc.id,
            selectedCustomer: doc.data().selectedCustomer,
            selectedForwarder: doc.data().selectedForwarder,
            pieces: doc.data().pieces,
            calculatedRates: doc.data().calculatedRates,
            ...doc.data(),
          };

          // Access customer data from the selected fields
          const {
            selectedCustomer,
            selectedForwarder,
            pieces,
            calculatedRates,
          } = orderData;
          // ... (rest of the code remains the same)
        });

        setOrders(ordersData);
        handleFilterOrders();
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const unsubscribe = onSnapshot(
      collection(firestore, "orders"),
      (snapshot) => {
        const updatedOrders = snapshot.docs.map((doc) => {
          const orderData = {
            id: doc.id,
            selectedCustomer: doc.data().selectedCustomer,
            selectedForwarder: doc.data().selectedForwarder,
            pieces: doc.data().pieces,
            calculatedRates: doc.data().calculatedRates,
            ...doc.data(),
          };

          // Access customer data from the selectedCustomer field
          const {
            selectedCustomer,
            selectedForwarder,
            pieces,
            calculatedRates,
          } = orderData;
          // ... (rest of the code remains the same)
        });

        setOrders(updatedOrders);
        handleFilterOrders();
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return null;
};

export default FetchOrdersComponent;
