import moment from "moment";

// Utility function to format dates using Moment.js
const formatDate = (dateString) => {
  // Parse the date string and convert to Moment.js object
  const date = moment.utc(dateString);

  // Convert to local timezone (EDT/EST)
  date.toLocaleString();

  // Format the date as "Month Day, Year"
  return date.format("MMMM DD, YYYY");
};

export default formatDate;
