import React, { useState, useEffect } from "react";
import {
  Form as BootstrapForm,
  FormControl,
  Row,
  Col,
  Tabs,
  Tab,
  Container,
} from "react-bootstrap";
import {
  Icon,
  MenuItem,
  Button,
  ButtonGroup,
  Card,
  Section,
  SectionCard,
  Elevation,
  Tooltip,
  Divider,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { collection, addDoc } from "firebase/firestore";
import { firestore, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getActiveDrivers, getActiveForwarders } from "../../javascript/api";
import moment from "moment";
import OrderPieces from "./OrderPieces";
import RateCalculator from "./RateCalculator";
import SuccessToast from "../SuccessToast";
import ForwarderDetails from "./ForwarderDetails";
import useFormattedPhoneNumber from "../../utility/formatPhoneNumber";

const validationSchema = Yup.object().shape({
  orderNumber: Yup.string().required("Order number is required"),
  deliveryDate: Yup.string().required("Delivery date is required"),
});

const CreateOrder = () => {
  // Get the current date in the local time zone
  const today = new Date();
  // Get the time zone offset in milliseconds
  const timeZoneOffsetMs = today.getTimezoneOffset() * 60 * 1000;
  // Adjust the current date for the time zone offset
  const currentDate = new Date(today.getTime() - timeZoneOffsetMs)
    .toISOString()
    .split("T")[0];

  const initialFormValues = {
    createdOn: currentDate,
    orderNumber: "O-" + Date.now(),
    customerCompanyName: "",
    customerFirstName: "",
    customerLastName: "",
    customerAddress: "",
    customerAddressCity: "",
    customerAddressState: "",
    customerAddressZip: "",
    customerPrimaryPhone: "",
    customerSecondaryPhone: "",
    forwarderCompanyName: "",
    forwarderAddress: "",
    forwarderAddressCity: "",
    forwarderAddressState: "",
    forwarderAddressZip: "",
    forwarderPrimaryPhone: "",
    forwarderSecondaryPhone: "",
    deliveryDate: "",
    assignedDriver: "",
    files: null,
    notes: "",
    signature: "",
    status: "Active",
    pieces: [],
    calculatedRates: [],
    // Add other order fields
  };

  const [showToast, setShowToast] = useState(false);
  const [formData, setFormData] = useState(initialFormValues);
  const [file, setFile] = useState(null);
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedForwarder, setSelectedForwarder] = useState(null);
  const [activeForwarders, setActiveForwarders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [searchedForwarders, setSearchedForwarders] = useState([]);
  const [manualForwarderCompanyName, setManualForwarderCompanyName] =
    useState("");
  const [key, setKey] = useState("select");
  const [forwarderAddressZip, setForwarderAddressZip] = useState("");
  const handleZipcodeChange = (e) => {
    setForwarderAddressZip(e.target.value);
  };
  const [selectedZipcode, setSelectedZipcode] = useState("");
  const [formKey, setFormKey] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    // Close the modal after 1000ms (1 seconds)
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activeDriversList = await getActiveDrivers();
        const activeForwardersList = await getActiveForwarders();
        setActiveDrivers(activeDriversList);
        setActiveForwarders(activeForwardersList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchForwarders = async () => {
      try {
        setLoading(true);
        const forwarders = await getActiveForwarders();
        setSearchedForwarders(forwarders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching forwarders:", error);
        setLoading(false);
      }
    };

    fetchForwarders();
  }, []);

  useEffect(() => {
    if (selectedForwarder) {
      setSelectedZipcode(selectedForwarder.forwarderAddressZip || "");
    }
  }, [selectedForwarder]);

  // filter search for forwarders

  const handleForwarderSearch = (searchText) => {
    if (searchText.trim() === "") {
      setSearchedForwarders([]); // Clear results when the field is empty
    } else {
      const filteredForwarders = activeForwarders.filter((forwarder) =>
        forwarder.forwarderCompanyName
          .toLowerCase()
          .startsWith(searchText.toLowerCase())
      );
      setSearchedForwarders(filteredForwarders);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Handle form field changes here
  };

  // Handle forwarder selection
  const handleSelectForwarder = (forwarder) => {
    setSelectedForwarder(forwarder);
    handleChange({
      target: {
        name: "forwarderCompanyName",
        value: forwarder.forwarderCompanyName,
      },
    });
  };

  const uploadFileToStorage = async (file) => {
    try {
      const storageRef = ref(storage, `files/orders/${file.name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error("Error uploading file to storage:", error);
      throw error;
    }
  };

  const handleSubmit = async (values, formikBag) => {
    const { setSubmitting } = formikBag;
    try {
      setFormData(initialFormValues);
      setPieces([
        {
          id: Date.now(),
          sku: "",
          description: "",
          price: "",
          volume: "",
          weight: "",
          quantity: "",
          isEditing: true,
        },
      ]);

      setCalculatedRates("");

      let fileUrl = "";

      // Upload file to Firestore Storage if a file is selected
      if (file) {
        fileUrl = await uploadFileToStorage(file);
      }

      const deliveryDateUTC = moment.utc(values.deliveryDate).toISOString();

      const orderData = {
        ...values,
        deliveryDate: deliveryDateUTC,
        files: fileUrl,
        notes: notes,
        selectedForwarder:
          key === "select"
            ? selectedForwarder
            : {
                // Only set selectedForwarder for "Select" tab
                forwarderCompanyName: values.forwarderCompanyName || "",
                forwarderAddress: values.forwarderAddress || "",
                forwarderAddressCity: values.forwarderAddressCity || "",
                forwarderAddressState: values.forwarderAddressState || "",
                forwarderAddressZip: values.forwarderAddressZip || "",
                forwarderPrimaryPhone: values.forwarderPrimaryPhone || "",
                forwarderSecondaryPhone: values.forwarderSecondaryPhone || "",
              },
        pieces: pieces,
        calculatedRates: calculatedRates,
      };

      const docRef = await addDoc(collection(firestore, "orders"), orderData);

      // Reset the selected forwarder field
      setSelectedForwarder(null);
      setManualForwarderCompanyName("");
      setForwarderAddressZip("");
      setShowToast(true);
      window.scrollTo(0, 0);
      // Clear the form data after successful submission
      formikBag.resetForm();
      setNotes("");
      setFile(null);
      setFormKey((prevKey) => prevKey + 1); // Increment the key to trigger a remount
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    } catch (error) {
      console.error("Error during form submission:", error);
      console.error("Error adding document: ", error);
      setErrorMessage(error.message);
      setSubmitting(false);
    }
  };

  const [pieces, setPieces] = useState([
    {
      id: Date.now(),
      sku: "",
      description: "",
      price: "",
      volume: "",
      weight: "",
      quantity: "",
      isEditing: true,
    },
    // Add more initial pieces as needed
  ]);

  const [calculatedRates, setCalculatedRates] = useState([]);

  return (
    <Container className="pb-3" style={{ minHeight: "100vh" }}>
      <div>
        {/* Render the SuccessToast component conditionally */}
        {showToast && (
          <SuccessToast showToast={showToast} setShowToast={setShowToast} />
        )}
      </div>
      <div className="d-flex align-items-center gap-3">
        <h2 className="pt-3">
          New Order <Icon icon="folder-new" size={30} intent="primary" />
        </h2>{" "}
      </div>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <Form className=" w-auto ">
            <Section
              title="Customer Details"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <Row>
                  <Col md={6}>
                    <BootstrapForm.Group>
                      <strong>
                        <BootstrapForm.Label>Order Number</BootstrapForm.Label>
                      </strong>
                      <FormControl
                        type="text"
                        id="orderNumber"
                        name="orderNumber"
                        value={values.orderNumber}
                        className="form-control"
                        readOnly
                      />
                      <strong>
                        <ErrorMessage
                          name="orderNumber"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={6} className="position-relative">
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Company name</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerCompanyName"
                        name="customerCompanyName"
                        value={values.customerCompanyName}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerCompanyName"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>
                        Customer First Name
                      </BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerFirstName"
                        name="customerFirstName"
                        value={values.customerFirstName}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerFirstName"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={6}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>
                        Customer Last Name
                      </BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerLastName"
                        name="customerLastName"
                        value={values.customerLastName}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerLastName"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>
                        Customer Address
                      </BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerAddress"
                        name="customerAddress"
                        value={values.customerAddress}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerAddress"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>City</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerAddressCity"
                        name="customerAddressCity"
                        value={values.customerAddressCity}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerAddressCity"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={4}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>State</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerAddressState"
                        name="customerAddressState"
                        value={values.customerAddressState}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerAddressState"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={4}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Zip</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerAddressZip"
                        name="customerAddressZip"
                        value={values.customerAddressZip}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerAddressZip"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Primary Phone</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerPrimaryPhone"
                        name="customerPrimaryPhone"
                        value={values.customerPrimaryPhone}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerPrimaryPhone"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={6}>
                    <BootstrapForm.Group>
                      <BootstrapForm.Label>Secondary Phone</BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="text"
                        id="customerSecondaryPhone"
                        name="customerSecondaryPhone"
                        value={values.customerSecondaryPhone}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="customerSecondaryPhone"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </BootstrapForm.Group>
                  </Col>
                </Row>
              </SectionCard>
            </Section>

            <Section
              title="Forwarder Details"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
              rightElement={
                <Button
                  icon="plus"
                  intent="primary"
                  minimal={true}
                  onClick={openModal}
                >
                  Add Forwarder
                </Button>
              }
            >
              <SectionCard>
                <Row className="mt-2">
                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    id="uncontrolled-tabs"
                    className="mb-3 "
                    justify={true}
                  >
                    <Tab eventKey="select" title="Select">
                      <BootstrapForm.Group controlId="forwarderCompanyName">
                        <BootstrapForm.Label>
                          Select Forwarder
                        </BootstrapForm.Label>

                        <Select
                          items={searchedForwarders}
                          itemRenderer={(forwarder, { handleClick }) => (
                            <MenuItem
                              key={forwarder.id}
                              fill={true}
                              filterable={true}
                              grouped={true}
                              text={
                                <div className="d-flex">
                                  <span>
                                    {searchedForwarders.indexOf(forwarder) + 1}.{" "}
                                  </span>
                                  <strong>
                                    {forwarder.forwarderCompanyName}
                                  </strong>
                                  <Divider /> {forwarder.forwarderFirstName}{" "}
                                  {forwarder.forwarderLastName} <Divider />
                                  {forwarder.forwarderAddressCity}
                                </div>
                              }
                              onClick={() => {
                                handleClick(forwarder);
                                handleSelectForwarder(forwarder);
                              }}
                            />
                          )}
                          onItemSelect={(selectedForwarder) => {
                            handleSelectForwarder(selectedForwarder);
                          }}
                          resetOnClose={true}
                          noResults={
                            <MenuItem
                              disabled={false}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                          activeItem={selectedForwarder}
                        >
                          <Button
                            text={
                              selectedForwarder
                                ? selectedForwarder.forwarderCompanyName
                                : "Search Forwarder"
                            }
                            intent="primary"
                            rightIcon="caret-down"
                            icon="th-filtered"
                          />
                        </Select>

                        <div>
                          <ForwarderDetails
                            isOpen={isModalOpen}
                            onClose={closeModal}
                          />
                        </div>

                        {/* Display forwarder details when selected */}
                        {selectedForwarder && (
                          <div className="pt-2">
                            <p>
                              Forwarder Name:{" "}
                              {selectedForwarder.forwarderFirstName}{" "}
                              {selectedForwarder.forwarderLastName}{" "}
                            </p>
                            <p>
                              Forwarder Address:{" "}
                              {selectedForwarder.forwarderAddress}
                            </p>
                            <p>
                              Forwarder City:{" "}
                              {selectedForwarder.forwarderAddressCity}{" "}
                            </p>
                            <p>
                              Forwarder State:{" "}
                              {selectedForwarder.forwarderAddressState}{" "}
                            </p>
                            <p>
                              Forwarder Zipcode:{" "}
                              {selectedForwarder.forwarderAddressZip}
                            </p>
                            <p>
                              Primary Phone:{" "}
                              {selectedForwarder.forwarderPrimaryPhone}
                            </p>
                            <p>
                              Secondary Phone:{" "}
                              {selectedForwarder.forwarderSecondaryPhone}
                            </p>
                            {/* Add other forwarder details as needed */}
                          </div>
                        )}
                        {/* Display "No forwarders found" message */}
                        {!searchedForwarders.length && !selectedForwarder && (
                          <div className="mt-2">
                            <BootstrapForm.Text muted>
                              No forwarders found, add forwarder or use the
                              manual tab
                            </BootstrapForm.Text>

                            <ForwarderDetails
                              isOpen={isModalOpen}
                              onClose={closeModal}
                            />
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </Tab>
                    <Tab eventKey="manual" title="Manual">
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Forwarder Company name
                        </BootstrapForm.Label>
                        <Field
                          size="sm"
                          type="text"
                          id="forwarderCompanyName"
                          name="forwarderCompanyName"
                          value={manualForwarderCompanyName}
                          className="form-control"
                          onChange={(e) => {
                            setManualForwarderCompanyName(e.target.value);
                            handleChange(e);
                          }}
                        />
                        <strong>
                          <ErrorMessage
                            name="forwarderCompanyName"
                            component="div"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </strong>
                      </BootstrapForm.Group>
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Forwarder Address
                        </BootstrapForm.Label>
                        <Field
                          size="sm"
                          type="text"
                          id="forwarderAddress"
                          name="forwarderAddress"
                          value={values.forwarderAddress}
                          className="form-control"
                        />
                        <strong>
                          <ErrorMessage
                            name="forwarderAddress"
                            component="div"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </strong>
                      </BootstrapForm.Group>
                      <Row md={3}>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>City</BootstrapForm.Label>
                          <Field
                            size="sm"
                            type="text"
                            id="forwarderAddressCity"
                            name="forwarderAddressCity"
                            value={values.forwarderAddressCity}
                            className="form-control"
                          />
                          <strong>
                            <ErrorMessage
                              name="forwarderAddressCity"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </strong>
                        </BootstrapForm.Group>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>State</BootstrapForm.Label>
                          <Field
                            size="sm"
                            type="text"
                            id="forwarderAddressState"
                            name="forwarderAddressState"
                            value={values.forwarderAddressState}
                            className="form-control"
                          />
                          <strong>
                            <ErrorMessage
                              name="forwarderAddressState"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </strong>
                        </BootstrapForm.Group>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Zip</BootstrapForm.Label>
                          <Field
                            size="sm"
                            type="text"
                            id="forwarderAddressZip"
                            name="forwarderAddressZip"
                            value={forwarderAddressZip}
                            onChange={(e) => {
                              handleZipcodeChange(e);
                              handleChange(e);
                            }}
                            className="form-control"
                          />
                          <strong>
                            <ErrorMessage
                              name="forwarderAddressZip"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </strong>
                        </BootstrapForm.Group>
                      </Row>
                      <Row md={2}>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Primary Phone
                          </BootstrapForm.Label>
                          <Field
                            size="sm"
                            type="text"
                            id="forwarderPrimaryPhone"
                            name="forwarderPrimaryPhone"
                            value={values.forwarderPrimaryPhone}
                            className="form-control"
                          />
                          <strong>
                            <ErrorMessage
                              name="forwarderPrimaryPhone"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </strong>
                        </BootstrapForm.Group>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Secondary Phone
                          </BootstrapForm.Label>
                          <Field
                            size="sm"
                            type="text"
                            id="forwarderSecondaryPhone"
                            name="forwarderSecondaryPhone"
                            value={values.forwarderSecondaryPhone}
                            className="form-control"
                          />
                          <strong>
                            <ErrorMessage
                              name="forwarderSecondaryPhone"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </strong>
                        </BootstrapForm.Group>
                      </Row>
                    </Tab>
                  </Tabs>
                </Row>
              </SectionCard>
            </Section>

            <Section
              title="Pieces"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <Row>
                  <OrderPieces
                    pieces={pieces}
                    setPieces={setPieces}
                    setCalculatedRates={setCalculatedRates}
                    forwarderAddressZip={forwarderAddressZip}
                    selectedZipcode={selectedZipcode}
                    setSelectedZipcode={setSelectedZipcode}
                    key={formKey}
                  />
                </Row>
              </SectionCard>
            </Section>

            <Section
              title="Rates & Fees"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <RateCalculator
                  pieces={pieces}
                  setPieces={setPieces}
                  setCalculatedRates={setCalculatedRates}
                  forwarderAddressZip={forwarderAddressZip}
                  selectedZipcode={selectedZipcode}
                  setSelectedZipcode={setSelectedZipcode}
                  key={formKey}
                />
              </SectionCard>
            </Section>

            <Section
              title="Delivery Details"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <div className="mb-3">
                  <Row>
                    <Col md={4}>
                      <BootstrapForm.Label htmlFor="deliveryDate">
                        Scheduled Delivery Date
                      </BootstrapForm.Label>
                      <Field
                        size="sm"
                        type="date"
                        id="deliveryDate"
                        name="deliveryDate"
                        value={values.deliveryDate}
                        className="form-control"
                      />
                      <strong>
                        <ErrorMessage
                          name="deliveryDate"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>
                    <Col md={4}>
                      <BootstrapForm.Group
                        controlId="assignedDriver"
                        className="mt-2"
                      >
                        <BootstrapForm.Label>Assign Driver</BootstrapForm.Label>
                        <Field
                          size="sm"
                          as="select"
                          style={{ cursor: "pointer" }}
                          name="assignedDriver"
                          id="assignedDriver"
                          value={values.assignedDriver}
                          className="form-select"
                        >
                          <option value="" disabled>
                            Select a driver
                          </option>
                          {activeDrivers.map((driver) => (
                            <option key={driver.id} value={driver.name}>
                              {driver.name}
                            </option>
                          ))}
                        </Field>
                        <strong>
                          <ErrorMessage
                            name="assignedDriver"
                            component="div"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </strong>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={4}>
                      <BootstrapForm.Group
                        controlId="formFile"
                        className="mb-3 mt-2"
                      >
                        <BootstrapForm.Label>
                          Upload a document
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          size="sm"
                          type="file"
                          name="files"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            handleChange(e);
                          }}
                        />
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col md={6}>
                    <BootstrapForm.Group
                      className="mb-3"
                      controlId="Form.ControlTextarea1"
                    >
                      <BootstrapForm.Label>Notes</BootstrapForm.Label>
                      <BootstrapForm.Control
                        size="sm"
                        as="textarea"
                        rows={3}
                        value={values.notes}
                        name="notes"
                        onChange={(e) => {
                          handleChange(e);
                          setNotes(e.target.value);
                        }}
                      />
                    </BootstrapForm.Group>
                  </Col>
                  <Col md={6}>
                    <BootstrapForm.Group controlId="status">
                      <BootstrapForm.Label>Order Status</BootstrapForm.Label>
                      <Field
                        size="sm"
                        as="select"
                        style={{ cursor: "pointer" }}
                        name="status"
                        id="status"
                        value={values.status}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Status
                        </option>
                        <option value="Active">Active</option>
                        <option value="Delivered">
                          Delivered (Awaiting Invoice)
                        </option>
                        <option value="Invoiced">
                          Awaiting Payment (Invoiced)
                        </option>
                        <option value="Closed">Closed</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="error"
                      />
                    </BootstrapForm.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  {/* Empty signature field */}
                  <FormControl
                    type="text"
                    id="signature"
                    name="signature"
                    style={{ display: "none" }}
                  />
                </Row>
              </SectionCard>
            </Section>
            <div className="col-md-5 ">
              <Button
                type="submit"
                minimal={true}
                outlined={true}
                intent="success"
                rightIcon="send-message"
                className="mb-2"
              >
                Save & Submit Order
              </Button>
            </div>

            {errorMessage && <div className="error">{errorMessage}</div>}
          </Form>
        )}
      </Formik>{" "}
    </Container>
  );
};

export default CreateOrder;
