import React from "react";
import Navigation from "../components/managerView/Navigation";
import GetOrders from "../components/GetOrders";
import { useTheme } from "../context/ThemeContext";

const SearchOrders = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      <Navigation />
      <GetOrders />
    </div>
  );
};

export default SearchOrders;
