import React, { useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { Card, Elevation, Divider, Icon } from "@blueprintjs/core";
import formatDate from "../../utility/formatDate";

const ViewSelectedOrder = ({ selectedOrder }) => {
  return (
    <Container className="pb-3" style={{ minHeight: "100vh" }}>
      <div className="mt-3 text-center">
        <h2>
          Order Details <Icon icon="id-number" intent="primary" size={35} />{" "}
        </h2>
      </div>
      <Card elevation={Elevation.TWO} compact={true}>
        <p>
          <strong>
            Customer Info <Icon icon="mugshot" intent="primary" size={16} />
          </strong>
        </p>
        <Card className="dashboard-col">
          <div>
            <Row>
              <Col md={6}>
                <p>Order Number: {selectedOrder?.orderNumber}</p>
              </Col>
              <Col md={6}>
                <p>Delivery Date: {formatDate(selectedOrder?.deliveryDate)}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>Customer First Name: {selectedOrder?.customerFirstName}</p>
              </Col>
              <Col md={6}>
                <p>Customer Last Name: {selectedOrder?.customerLastName}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>
                  Customer Address: {selectedOrder?.customerAddress} <br />{" "}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  {selectedOrder?.customerAddressCity},{" "}
                  {selectedOrder?.customerAddressState}{" "}
                  {selectedOrder?.customerAddressZip}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>
                  Customer Primary Phone: {selectedOrder?.customerPrimaryPhone}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  Customer Secondary Phone:{" "}
                  {selectedOrder?.customerSecondaryPhone}
                </p>
              </Col>
            </Row>
          </div>
        </Card>
        <p>
          <strong>
            Forwarder Info <Icon icon="truck" intent="primary" size={16} />
          </strong>
        </p>
        <Card className="dashboard-col">
          <div>
            <Row>
              <Col md={6}>
                <p>
                  Forwarder Company Name:{" "}
                  {selectedOrder.selectedForwarder?.forwarderCompanyName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>
                  Forwarder Address:{" "}
                  {selectedOrder.selectedForwarder?.forwarderAddress}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  {selectedOrder.selectedForwarder?.forwarderAddressCity},{" "}
                  {selectedOrder.selectedForwarder?.forwarderAddressState}{" "}
                  {selectedOrder.selectedForwarder?.forwarderAddressZip}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>
                  Forwarder Primary Phone:{" "}
                  {selectedOrder.selectedForwarder?.forwarderPrimaryPhone}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  Forwarder Secondary Phone:{" "}
                  {selectedOrder.selectedForwarder?.forwarderSecondaryPhone}
                </p>
              </Col>
            </Row>
          </div>
        </Card>
        <p>
          <strong>
            Additional Details{" "}
            <Icon icon="list-detail-view" intent="primary" size={16} />
          </strong>
        </p>
        <Card className="dashboard-col">
          <div>
            <Row>
              <Col md={6}>
                <p>Notes: {selectedOrder.notes}</p>
              </Col>
              <Col md={6}>
                <p>Status: {selectedOrder.status}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>Assigned Driver: {selectedOrder.assignedDriver}</p>
              </Col>
              <Col md={6}>
                {selectedOrder.files ? (
                  <Button
                    intent="primary"
                    onClick={() => window.open(selectedOrder.files, "_blank")}
                  >
                    View Attachment
                  </Button>
                ) : (
                  <p>No attachments</p>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                Driver Fee: ${selectedOrder.calculatedRates.driverFeeAdjustment}
              </Col>
              <Col md={6}>
                <p>Signature: </p>
                {selectedOrder.signature ? (
                  <img
                    src={selectedOrder.signature}
                    alt="Signature"
                    width={200}
                  />
                ) : (
                  <p>No Signature captured</p>
                )}
              </Col>
            </Row>
          </div>
        </Card>
      </Card>
      {/* Display other order details for viewing */}
    </Container>
  );
};

export default ViewSelectedOrder;
