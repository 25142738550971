import React, { useState, useEffect } from "react";
import Navigation from "../components/managerView/Navigation";
import CreateInvoice from "../components/managerView/CreateInvoice";
import { getBillableOrders } from "../javascript/api";
import { useTheme } from "../context/ThemeContext";

const NewInvoice = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchSelectedOrder = async () => {
      try {
        const orders = await getBillableOrders();
        // Assuming you want the first order as the selectedOrder
        setSelectedOrder(orders[0]);
      } catch (error) {
        console.error("Error fetching billable orders:", error);
      }
    };

    fetchSelectedOrder();
  }, []);

  return (
    <>
      <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
        <Navigation />
        <CreateInvoice selectedOrder={selectedOrder} />
      </div>
    </>
  );
};

export default NewInvoice;
