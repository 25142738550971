import React, { createContext, useContext, useState } from "react";

// Create a context to hold global state
const AppContext = createContext();

// Custom hook to consume the context
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  // Define your global state variables and functions here
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const updateOrderDeliveryAttempts = (orderId, updatedDeliveryAttempts) => {
    // Update the delivery attempts of the selected order
    const updatedSelectedOrder = { ...selectedOrder };
    if (updatedSelectedOrder.id === orderId) {
      updatedSelectedOrder.deliveryAttempts = updatedDeliveryAttempts;
      setSelectedOrder(updatedSelectedOrder);
    }

    // Update the delivery attempts of the orders list
    const updatedOrders = orders.map((order) => {
      if (order.id === orderId) {
        return {
          ...order,
          deliveryAttempts: updatedDeliveryAttempts,
        };
      }
      return order;
    });

    // Update the orders state with the modified order
    setOrders(updatedOrders);
  };

  // Include other global state and functions as needed

  return (
    <AppContext.Provider
      value={{
        // Provide global state and functions through context value
        orders,
        setOrders,
        selectedOrder,
        setSelectedOrder,
        updateOrderDeliveryAttempts,
        // Include other global state and functions here
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
