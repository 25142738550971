import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./Header";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      await signIn(values.email, values.password, values.role);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error.message);
    } finally {
      setSubmitting(false);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Header />
      <Col>
        <Row className="justify-content-center">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <h1>Login</h1>
              <p>Sign in to your account.</p>
              <Row className="mb-3">
                <Field
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  className="form-control"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </Row>
              <Row className="mb-3">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />

                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </Row>
              {errorMessage && <div className="error">{errorMessage}</div>}

              <Row>
                <Button
                  type="submit"
                  variant="primary"
                  className="mb-2"
                  disabled={loading}
                >
                  Login
                </Button>
              </Row>
              <div className="text-center">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
            </Form>
          </Formik>
        </Row>
      </Col>
    </Container>
  );
};

export default Login;
