import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OrderCardComponent from "./OrderCardComponent";
import { doc, onSnapshot } from "firebase/firestore";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import { firestore } from "../firebase";

const OrdersListComponent = ({
  filteredOrders,
  handleViewDetails,
  handleEditOrder,
  handleDeleteOrder,
  handleCaptureSignature,
  handleAttemptDelivery,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Subscribe to real-time updates for each order
    const unsubscribe = filteredOrders.map((order) =>
      onSnapshot(doc(firestore, "orders", order.id), (snapshot) => {
        if (snapshot.exists()) {
          // Handle the order update here if needed
        } else {
          console.error(`Order document with ID ${order.id} does not exist`);
        }
      })
    );

    // Cleanup function
    return () => {
      // Unsubscribe from all order snapshots
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [filteredOrders]); // Re-run effect whenever filteredOrders changes

  const handleCreateInvoice = (order) => {
    if (order) {
      // Navigate to the invoice page with the order ID as a parameter
      navigate(`/new_invoice/${order.id}`);
    } else {
      console.error("Invalid or missing order data for creating an invoice.");
    }
  };

  return (
    <div>
      {filteredOrders.length === 0 ? (
        <div className="text-center">
          <Card elevation={Elevation.TWO}>
            <p>
              No orders <Icon icon="search" intent="primary" /> found.
            </p>
          </Card>
        </div>
      ) : (
        filteredOrders.map((order) => (
          <OrderCardComponent
            key={order.id}
            order={order}
            handleViewDetails={handleViewDetails}
            handleEditOrder={handleEditOrder}
            handleDeleteOrder={handleDeleteOrder}
            handleCaptureSignature={handleCaptureSignature}
            handleAttemptDelivery={handleAttemptDelivery}
            handleCreateInvoice={handleCreateInvoice}
          />
        ))
      )}
    </div>
  );
};

export default OrdersListComponent;
