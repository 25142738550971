import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Image,
  CardTitle,
} from "react-bootstrap";
import {
  Icon,
  Card,
  Divider,
  Checkbox,
  Section,
  SectionCard,
  CardList,
} from "@blueprintjs/core";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import formatDate from "../../utility/formatDate";
import { useReactToPrint } from "react-to-print";

const CreateInvoice = ({ selectedOrder }) => {
  const [markAsPaid, setMarkAsPaid] = useState(false);
  const [markAsInvoiced, setMarkAsInvoiced] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const componentRef = React.useRef();

  const renderField = (label, value) => {
    if (
      value != "undefined" &&
      value != "undefined undefined" &&
      value != "undefined, undefined undefined" &&
      value != undefined
    ) {
      return (
        value && (
          <>
            {label}: {value}
            <br />
          </>
        )
      );
    }
  };

  // Function to print the invoice
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleMarkAsInvoicedChange = async () => {
    try {
      const newStatus = !markAsInvoiced ? "Invoiced" : "Delivered";

      setOrderStatus(newStatus);
      setMarkAsInvoiced(!markAsInvoiced);

      // Update Firestore document with the new status
      await updateOrderStatus(newStatus);
    } catch (error) {
      console.error("Error marking as invoiced:", error);
    }
  };

  const handleMarkAsPaidChange = async () => {
    try {
      const newStatus = !markAsPaid ? "Closed" : "Invoiced";

      setOrderStatus(newStatus);
      setMarkAsPaid(!markAsPaid);

      // Update Firestore document with the new status
      await updateOrderStatus(newStatus);
    } catch (error) {
      console.error("Error marking as paid:", error);
    }
  };

  const updateOrderStatus = async (newStatus) => {
    if (!selectedOrder || !selectedOrder.id) {
      throw new Error("Invalid or missing order data");
    }

    const orderRef = doc(firestore, "orders", selectedOrder.id);
    await updateDoc(orderRef, { status: newStatus });
  };

  // Fetch initial order status from Firestore
  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const orderDoc = await getDoc(
          doc(firestore, "orders", selectedOrder.id)
        );
        if (orderDoc.exists()) {
          const data = orderDoc.data();
          if (data && data.status) {
            setOrderStatus(data.status);
            if (data.status === "Invoiced") {
              setMarkAsInvoiced(true);
            }
            if (data.status === "Complete") {
              setMarkAsPaid(true);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching order status:", error);
      }
    };

    if (selectedOrder && selectedOrder.id) {
      fetchOrderStatus();
    }
  }, [selectedOrder]);

  return (
    <div ref={componentRef}>
      <Container className="pb-3" style={{ minHeight: "100vh" }}>
        <Row>
          <div className="mt-3 d-flex">
            <Col>
              <h1>Invoice </h1>
            </Col>
            <Col className="mb-2 d-flex justify-content-end ">
              <div className="hide-on-print">
                <Button onClick={handlePrint}>
                  <Icon icon="print" color="#fff" />
                </Button>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <Col md={6} className=" mb-2">
            <Image src="/truck.png" rounded style={{ width: "70px" }} /> Quick
            Delivery, INC
          </Col>

          <Col className="mb-2">
            <Section title={`Invoice: ${selectedOrder?.id}`}>
              <SectionCard>
                Order Number: {selectedOrder?.orderNumber}
              </SectionCard>
            </Section>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-2">
            <Section title="Customer Info">
              <SectionCard>
                {renderField(
                  "Customer Name",
                  `${selectedOrder?.customerFirstName} ${selectedOrder?.customerLastName}`
                )}
                {renderField(
                  "Company Name",
                  selectedOrder?.customerCompanyName
                )}
                {renderField("Address", selectedOrder?.customerAddress)}
                {renderField(
                  "Address Line 2",
                  `${selectedOrder?.customerAddressCity}, ${selectedOrder?.customerAddressState} ${selectedOrder?.customerAddressZip}`
                )}
              </SectionCard>
            </Section>
          </Col>
          <Col md={6} className="mb-2">
            <Section title="Forwarder Info">
              <SectionCard>
                {renderField(
                  "Forwarder Name",
                  selectedOrder?.forwarderFirstName
                    ? `${selectedOrder?.forwarderFirstName} ${selectedOrder?.forwarderLastName}`
                    : selectedOrder?.selectedForwarder
                    ? `${selectedOrder?.selectedForwarder.forwarderFirstName} ${selectedOrder?.selectedForwarder.forwarderLastName}`
                    : "N/A"
                )}
                {renderField(
                  "Company Name",
                  selectedOrder?.forwarderCompanyName
                    ? selectedOrder?.forwarderCompanyName
                    : selectedOrder?.selectedForwarder?.forwarderCompanyName
                    ? selectedOrder.selectedForwarder.forwarderCompanyName
                    : "N/A"
                )}
                {renderField(
                  "Address",
                  selectedOrder?.forwarderAddress
                    ? selectedOrder?.forwarderAddress
                    : selectedOrder?.selectedForwarder
                    ? selectedOrder.selectedForwarder.forwarderAddress
                    : "N/A"
                )}
                {renderField(
                  "Address Line 2",
                  selectedOrder?.forwarderAddressCity
                    ? `${selectedOrder?.forwarderAddressCity}, ${selectedOrder?.forwarderAddressState} ${selectedOrder?.forwarderAddressZip}`
                    : selectedOrder?.selectedForwarder
                    ? `${selectedOrder?.selectedForwarder.forwarderAddressCity}, ${selectedOrder?.selectedForwarder.forwarderAddressState} ${selectedOrder?.selectedForwarder.forwarderAddressZip}`
                    : "N/A"
                )}
              </SectionCard>
            </Section>
          </Col>
        </Row>
        {/* Customer details section */}
        <Row>
          <Col className="mb-2">
            <Section title="Pieces" className="mb-2">
              <SectionCard>
                {selectedOrder &&
                Array.isArray(selectedOrder.pieces) &&
                selectedOrder.pieces.length > 0 ? (
                  <Card>
                    <Row className="d-flex flex-wrap">
                      <Col md={2} sm={12}>
                        <strong>Sku</strong>
                      </Col>
                      <Col md={2} sm={12}>
                        <strong>Description</strong>
                      </Col>
                      <Col md={2} sm={12}>
                        <strong>Volume (cu ft)</strong>
                      </Col>
                      <Col md={2} sm={12}>
                        <strong>Weight (lbs)</strong>
                      </Col>
                      <Col md={2} sm={12}>
                        <strong>Quantity</strong>
                      </Col>{" "}
                      <Col md={2} sm={12}>
                        <strong>Price</strong>
                      </Col>
                      <Divider />
                    </Row>
                    {selectedOrder.pieces.map((piece, index) => (
                      <div key={index}>
                        <Row>
                          <Col>{piece.sku}</Col>
                          <Col>{piece.description}</Col>
                          <Col>{piece.volume}</Col>
                          <Col>{piece.weight}</Col>
                          <Col>{piece.quantity}</Col>
                          <Col>${piece.price}</Col>
                        </Row>
                        {/* Add more properties as needed */}
                      </div>
                    ))}
                  </Card>
                ) : (
                  <p>No pieces available</p>
                )}
                <br />
                <Row className="mt-3">
                  <Col md={6} className="d-flex">
                    Delivered On: {formatDate(selectedOrder?.deliveryDate)}
                  </Col>
                  <Col md={6} className="d-flex">
                    Signed By:
                    <img
                      src={selectedOrder?.signature}
                      alt="Signature"
                      width={175}
                    />
                  </Col>
                </Row>
              </SectionCard>
            </Section>

            <Section title="Fees" className="mb-2">
              <SectionCard>
                <CardList>
                  {selectedOrder &&
                  Array.isArray(selectedOrder.calculatedRates.fees) &&
                  selectedOrder.calculatedRates.fees.length > 0 ? (
                    <Card className="d-flex gap-3 flex-wrap">
                      <p>
                        Delivery Fee: $
                        {selectedOrder?.calculatedRates.deliveryFee.toFixed(2)}
                      </p>
                      {selectedOrder.calculatedRates.fees.map((fee, index) => (
                        <Row key={index}>
                          <Col>
                            <p>
                              {fee.name}: ${fee.price.toFixed(2)}
                            </p>
                          </Col>
                        </Row>
                      ))}

                      <p>
                        <strong>
                          Total Charge: $
                          {selectedOrder?.calculatedRates.totalCharge}
                        </strong>
                      </p>
                    </Card>
                  ) : (
                    <p>No fees available</p>
                  )}
                </CardList>
              </SectionCard>
            </Section>
          </Col>
        </Row>

        <Card className="hide-on-print mb-2">
          <Row>
            <Col>
              <Checkbox
                type="checkbox"
                label="Invoice Sent"
                checked={markAsInvoiced}
                onChange={handleMarkAsInvoicedChange}
              />
            </Col>
            <Col>
              <Checkbox
                type="checkbox"
                label="Paid"
                checked={markAsPaid}
                onChange={handleMarkAsPaidChange}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
};

export default CreateInvoice;
