import React from "react";
import Navigation from "../components/managerView/Navigation";
import Account from "../components/Account";
import NavigationDriver from "../components/driverView/NavigationDriver";
import { useAuth } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext";

const AccountPage = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  // Check if user has the role property before accessing it
  const role = user && user.role;

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {/* Render based on user role */}
      {role === "Manager" || role === "Admin" ? (
        <>
          <Navigation />
          <Account />
        </>
      ) : (
        <>
          <NavigationDriver />
          <Account />
        </>
      )}
    </div>
  );
};

export default AccountPage;
