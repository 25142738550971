import React, { useState, useEffect, useMemo } from "react";
import { Form, Accordion, Col, Row, Container } from "react-bootstrap";
import { areaFees } from "../../javascript/rateZipcodeFees";
import { Select } from "@blueprintjs/select";
import { Collapse, Icon, Tooltip, Button, MenuItem } from "@blueprintjs/core";

let areaName;

const RateCalculator = ({
  pieces,
  setCalculatedRates,
  forwarderAddressZip,
  selectedZipcode,
  formSubmitted,
}) => {
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [fuelSurcharge, setFuelSurcharge] = useState(0);
  const [fees, setFees] = useState([{ name: "", price: 0 }]);
  const [customFee, setCustomFee] = useState({ name: "", price: 0 });
  const [waitingTimeFee, setWaitingTimeFee] = useState(0);
  const [waitingTimeHours, setWaitingTimeHours] = useState(0);
  const [additionalFee, setAdditionalFee] = useState(0);
  const [driverFeeCollapsed, setDriverFeeCollapsed] = useState(true);

  useEffect(() => {
    calculateRates();
  }, [
    pieces,
    waitingTimeHours,
    waitingTimeFee,
    forwarderAddressZip,
    selectedZipcode,
    fees,
    fuelSurcharge,
  ]);

  useEffect(() => {
    if (formSubmitted) {
      // Reset state here
      setDeliveryFee(0);
      setFuelSurcharge(0);
      setFees([{ name: "", price: 0 }]);
      setCustomFee({ name: "", price: 0 });
      setWaitingTimeFee(0);
      setWaitingTimeHours(0);
      setAdditionalFee(0);

      // Reset formSubmitted to false after resetting state
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const calculateRates = () => {
    let calculatedDeliveryFee = 0;

    if (pieces.length > 0) {
      const totalVolume = pieces.reduce(
        (acc, piece) => acc + piece.volume * piece.quantity,
        0
      );

      if (totalVolume >= 1 && totalVolume <= 170) {
        calculatedDeliveryFee = 60;
      } else {
        calculatedDeliveryFee = Math.max(
          (totalVolume - 170) * 10.4 * 0.04 + 60,
          0
        );
      }
    }

    setDeliveryFee(calculatedDeliveryFee);

    // Set flat fuel surcharge
    const flatFuelSurcharge = 5;
    setFuelSurcharge(flatFuelSurcharge);

    // Calculate waiting time fee (assuming $35 per hour)
    const calculatedWaitingTimeFee = waitingTimeHours * 35;
    setWaitingTimeFee(calculatedWaitingTimeFee);

    // Add a fixed fee if the forwarder's zip code matches
    let calculatedAdditionalFee = 0;
    const zipcodeToUse = selectedZipcode || forwarderAddressZip;

    if (zipcodeToUse) {
      // Iterate over each area and check if the entered zip code is included
      Object.entries(areaFees).forEach(([area, zipcodes]) => {
        if (zipcodes.includes(zipcodeToUse)) {
          switch (area) {
            case "Kendall":
              calculatedAdditionalFee = 100.0;
              areaName = area;
              break;
            case "Palm Beach":
              calculatedAdditionalFee = 162.45;
              areaName = area;
              break;
            case "Boca Raton":
              calculatedAdditionalFee = 162.45;
              areaName = area;
              break;
            // Add more cases for other areas as needed
            default:
              calculatedAdditionalFee = 0.0; // Default fee
              areaName = "Non Specific";
              break;
          }
        }
      });
    }

    setAdditionalFee(calculatedAdditionalFee);

    // Update the calculated rates in the parent component's state
    const calculatedRates = {
      deliveryFee,
      totalCharge: calculateTotalCharge().toFixed(2),
      fees: [
        ...fees,
        { name: "Fuel Surcharge", price: fuelSurcharge },
        { name: "Waiting Time Fee", price: waitingTimeFee },
        areaName
          ? { name: `${areaName} Area Fee`, price: calculatedAdditionalFee }
          : null,
      ].filter((fee) => fee), // Remove null entries

      driverFeeMultiplier: driverFeeMultiplier.toFixed(2),
      driverFeeAdjustment: driverFeeAdjustment.toFixed(2),
      // Add more rates as needed
    };

    setCalculatedRates(calculatedRates);
  };

  const calculateTotalCharge = () => {
    const totalCharge =
      deliveryFee +
      fuelSurcharge +
      fees.reduce((acc, fee) => acc + fee.price, 0) +
      (parseFloat(customFee.price) || 0) +
      waitingTimeFee +
      additionalFee;

    return totalCharge;
  };

  const handleFeeChange = (event, index) => {
    const selectedFeeName = event.target.value;
    let selectedFeePrice = 0;

    // Set the price based on the selected fee
    switch (selectedFeeName) {
      case "Administration Fee":
        selectedFeePrice = 25;
        break;
      case "Liftgate Fee":
        selectedFeePrice = 50;
        break;
      case "Tropical Shipping":
        selectedFeePrice = 35;
        break;
      case "Seacore Island":
        selectedFeePrice = 50;
        break;
      // Add other fee options as needed
      default:
        selectedFeePrice = 0;
    }

    setFees((prevFees) => {
      const updatedFees = [...prevFees];
      updatedFees[index] = { name: selectedFeeName, price: selectedFeePrice };
      return updatedFees;
    });
  };

  const handleAddFee = () => {
    setFees([...fees, { name: "", price: 0 }]);
  };

  const handleCustomFeeChange = (e, field) => {
    const value = e.target.value;
    setCustomFee((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddCustomFee = () => {
    if (
      customFee.name &&
      customFee.price !== null &&
      customFee.price !== undefined
    ) {
      // Create a new fee object with custom fee details
      const newCustomFee = {
        name: customFee.name,
        price: parseFloat(customFee.price),
      };

      // Add the new custom fee to the list of fees
      setFees((prevFees) => [...prevFees, newCustomFee]);

      // Reset the custom fee input fields
      setCustomFee({ name: "", price: 0 });
      document.getElementById("customFeeName").value = "";
      document.getElementById("customFeePrice").value = "";
    }
  };

  const handleWaitingTimeChange = (e) => {
    const value = e.target.value;
    setWaitingTimeHours(parseFloat(value) || 0);
  };

  const handleDeleteFee = (index) => {
    const updatedFees = [...fees];
    updatedFees.splice(index, 1);
    setFees(updatedFees);
  };

  // Calculate the administration fee
  const administrationFee = useMemo(() => {
    return fees.find((fee) => fee.name === "Administration Fee")?.price || 0;
  }, [fees]);

  // Define the driver fee multipliers based on areaName
  const driverFeeMultiplier = useMemo(() => {
    if (!areaName) return 0.61; // Default multiplier if areaName is not set

    const isBocaRatonOrPalmBeach =
      areaName === "Boca Raton" || areaName === "Palm Beach";
    return isBocaRatonOrPalmBeach ? 0.8 : 0.61;
  }, [areaName]);

  // Calculate the driver fee adjustment
  const driverFeeAdjustment = useMemo(() => {
    const totalChargeWithoutSurchargeAndAdminFee =
      calculateTotalCharge() - fuelSurcharge - administrationFee;
    const adjustedCharge =
      driverFeeMultiplier * totalChargeWithoutSurchargeAndAdminFee + 5;
    return adjustedCharge;
  }, [
    driverFeeMultiplier,
    calculateTotalCharge,
    fuelSurcharge,
    administrationFee,
  ]);

  return (
    <>
      <Row className="mb-2">
        <Col colSpan="5">Delivery Fee</Col>
        <Col colSpan="2">${deliveryFee.toFixed(2)}</Col>
      </Row>
      <Row className="mb-2">
        <Col colSpan="5">Fuel Surcharge</Col>
        <Col colSpan="2">${fuelSurcharge.toFixed(2)}</Col>
      </Row>

      {waitingTimeHours > 0 && (
        <Row className="mb-2">
          <Col colSpan="5">Waiting Time Fee</Col>
          <Col colSpan="2">${waitingTimeFee.toFixed(2)}</Col>
        </Row>
      )}

      {forwarderAddressZip && additionalFee > 0 && (
        <Row className="mb-2">
          <Col colSpan="5">{areaName} Area Fee</Col>
          <Col colSpan="2">${additionalFee.toFixed(2)}</Col>
        </Row>
      )}
      {selectedZipcode && additionalFee > 0 && (
        <Row className="mb-2">
          <Col colSpan="5">{areaName} Area Fee</Col>
          <Col colSpan="2">${additionalFee.toFixed(2)}</Col>
        </Row>
      )}

      {/* Add a dropdown for fee selection */}
      {fees.map((fee, index) => (
        <Row key={`fee-${index}`} className="mb-2">
          <Col colSpan="3">
            <Form.Group controlId={`feeSelect-${index}`}>
              <Form.Control
                as="select"
                size="sm"
                style={{
                  appearance: "menulist",
                  width: "auto",
                  cursor: "pointer",
                }}
                onChange={(e) => handleFeeChange(e, index)}
                value={fee.name || ""}
              >
                <option value="">Select Fee</option>
                <option value="Administration Fee">Administration Fee</option>
                <option value="Liftgate Fee">Liftgate Fee</option>
                <option value="Tropical Shipping">Tropical Shipping</option>
                <option value="Seacore Island">Seacore Island</option>
                {fees.map((fee, i) => (
                  <option key={i} value={fee.name}>
                    {fee.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {/* Display the fee and its price */}
          {fee.name && (
            <>
              <Col colSpan="2"></Col>
              <Col colSpan="1">${fee.price.toFixed(2)}</Col>
              {/* Button to delete the fee */}
              <Col colSpan="2">
                <Icon
                  icon="trash"
                  intent="danger"
                  type="button"
                  onClick={() => handleDeleteFee(index)}
                />
              </Col>
            </>
          )}
        </Row>
      ))}

      {/* Button to add a new fee */}
      <Col md={6}>
        <Button
          intent="success"
          icon="insert"
          outlined={true}
          onClick={handleAddFee}
        >
          Add Fee
        </Button>
      </Col>

      {/* Custom fee input row */}
      <Row className="mt-2 mb-2">
        <Col md={4}>
          <Form.Group controlId={`customFeeName`}>
            <Form.Label>Custom Fee Name</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              placeholder="Enter custom fee name"
              onChange={(e) => handleCustomFeeChange(e, "name")}
            />
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId={`customFeePrice`}>
            <Form.Label>Custom Fee Price</Form.Label>
            <Form.Control
              type="number"
              size="sm"
              placeholder="Enter custom fee price"
              onChange={(e) => handleCustomFeeChange(e, "price")}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <Button
            icon="confirm"
            onClick={handleAddCustomFee}
            intent="success"
            outlined={true}
          >
            Save
          </Button>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={3}>
          <Form.Group controlId={`waitingTimeHours`}>
            <Form.Label>Waiting Time (hours)</Form.Label>
            <Form.Control
              type="number"
              size="sm"
              placeholder="Enter waiting time hours"
              onChange={(e) => handleWaitingTimeChange(e)}
            />
          </Form.Group>
        </Col>
        <Col md={3}></Col>
      </Row>

      <Row className="mb-2">
        <Col md={6}>Total Charge</Col>
        <Col md={6}>${calculateTotalCharge().toFixed(2)}</Col>
      </Row>
      <div className="row">
        <div className="col-sm-12 pt-2">
          <Button
            intent="primary"
            icon="truck"
            onClick={() => setDriverFeeCollapsed(!driverFeeCollapsed)}
            className="mb-2"
          >
            {driverFeeCollapsed
              ? "Open Driver Fee Adjustment"
              : "Close Driver Fee Adjustment"}
          </Button>
          <Collapse isOpen={!driverFeeCollapsed}>
            <div className="row">
              <div className="col-sm-6">
                <strong>
                  <p>Rate</p>
                </strong>
                <p className="text-muted">Area based</p>
              </div>
              <div className="col-sm-6">
                <p>{driverFeeMultiplier.toFixed(2)}%</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <strong>
                  <p>Adjustment</p>
                </strong>
                <p className="text-muted">Including fuel surcharge</p>
              </div>
              <div className="col-sm-6">
                <p>${driverFeeAdjustment.toFixed(2)}</p>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default RateCalculator;
