import React, { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword as authSignInWithEmailAndPassword,
  signOut as authSignOut,
  onAuthStateChanged as authOnAuthStateChanged,
  sendPasswordResetEmail as authSendPasswordResetEmail,
} from "firebase/auth";
import { auth, firestore, functions, httpsCallable } from "../firebase";

import { collection, getDocs, query, where } from "firebase/firestore";
import { Container } from "react-bootstrap";
import { Alert, Spinner } from "@blueprintjs/core";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [authReady, setAuthReady] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const initialUserState = {
    email: "",
    displayName: "",
    firstName: "",
    lastName: "",
    password: "",
    profilePhotoUrl: "",
    role: "",
    status: "",
    assignedOrders: [],
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    // Initialize auth state only once when the component mounts

    const unsubscribe = authOnAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const currentUserData = await getUserData(currentUser.email);
        setUser({ ...currentUserData, email: currentUser.email });
      } else {
        setUser({});
      }
      // Set authReady to true after initializing auth state
      setAuthReady(true);
    });

    // Unsubscribe from the auth state listener when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    let inactivityTimer;

    if (user && user.email) {
      // Set up a timer to show the alert 55 minutes after the last activity
      inactivityTimer = setTimeout(() => {
        setShowAlert(true);
      }, 55 * 60 * 1000); // 55 minutes
    }

    return () => clearTimeout(inactivityTimer); // Clear the timer on component unmount or when the user becomes active again
  }, [user]); // Re-run the effect whenever user data changes

  useEffect(() => {
    if (showAlert) {
      const logoutTimer = setTimeout(() => {
        logoutUser();
      }, 5 * 60 * 1000); // 5 minutes

      return () => clearTimeout(logoutTimer);
    }
  }, [showAlert]); // Re-run the effect whenever showAlert changes

  const handleContinueSession = () => {
    // Reset the inactivity timer
    setShowAlert(false);
  };

  const handleLogout = () => {
    // Log out the user
    logoutUser();
  };

  const logoutUser = () => {
    authSignOut(auth).then(() => {
      setUser({});
      setAuthReady(true);
    });
  };

  function resetPassword(email) {
    return authSendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  const signIn = async (email, password) => {
    try {
      const userCredential = await authSignInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const currentUser = userCredential.user;

      // Fetch additional user details from Firestore
      const currentUserData = await getUserData(email);

      setUser({ ...currentUserData, email });
      setAuthReady(true);

      return currentUser;
    } catch (error) {
      console.error("Sign-in error:", error.message);
      throw error;
    }
  };

  const logout = () => {
    authSignOut(auth).then(() => {
      setUser({});
      setAuthReady(true); // Set authReady to true after successful sign-out
    });
  };

  const getUserData = async (email, additionalData = false) => {
    try {
      const userCollection = collection(firestore, "users");
      const querySnapshot = await getDocs(
        query(userCollection, where("email", "==", email))
      );

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        if (additionalData) {
          return {
            firstName: userData.firstName,
            lastName: userData.lastName,
          };
        } else {
          setUser({ ...initialUserState, ...userData });
          return userData;
        }
      } else {
        console.log("User does not exist in Firestore.");
        return {};
      }
    } catch (error) {
      console.error("Error fetching user data from Firestore:", error.message);
      throw error;
    }
  };

  const value = {
    user,
    currentUser,
    logout,
    signIn,
    resetPassword,
    getUserData,
    updateEmail,
    updatePassword,
  };

  // Provide context only if authentication is ready
  return (
    <UserContext.Provider value={value}>
      {authReady ? (
        <>
          {children}
          <Alert
            isOpen={showAlert}
            onClose={() => setShowAlert(false)}
            intent="warning"
            confirmButtonText="Continue Session"
            cancelButtonText="Logout"
            onConfirm={handleContinueSession}
            onCancel={handleLogout}
          >
            Your session will expire in 5 minutes. Would you like to continue?
          </Alert>
        </>
      ) : (
        <Container>
          <div className="d-flex mt-3 align-content-center justify-content-center">
            <span>Loading... </span> <Spinner intent="primary" role="status" />
          </div>
        </Container>
      )}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
