import React from "react";
import GetForwardersList from "../components/managerView/GetForwardersList";
import Navigation from "../components/managerView/Navigation";

const SearchForwarders = () => {
  return (
    <>
      <Navigation />
      <div className="container mt-3">
        <GetForwardersList />
      </div>
    </>
  );
};

export default SearchForwarders;
