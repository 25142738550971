import React from "react";
import Navigation from "../components/managerView/Navigation";
import CreateOrder from "../components/managerView/CreateOrder";
import { useTheme } from "../context/ThemeContext";

const NewOrder = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark " : ""}>
      <Navigation />
      <CreateOrder />
    </div>
  );
};

export default NewOrder;
