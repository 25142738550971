import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getInvoicedOrders } from "../../javascript/api";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import {
  Card,
  Elevation,
  Button,
  AnchorButton,
  InputGroup,
  Icon,
  Divider,
} from "@blueprintjs/core";
import formatDate from "../../utility/formatDate";
import Pagination10 from "../Pagination10";

const GetInvoices = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(6); // Set the number of orders per page
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersData = await getInvoicedOrders();
        setOrders(ordersData);
        setFilteredOrders(ordersData); // Initialize filtered orders with all orders
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // Filter orders based on search query whenever it changes
    const filtered = orders.filter((order) =>
      order.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOrders(filtered);
  }, [searchQuery, orders]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  // Pagination logic
  const indexOfLastDelivery = currentPage * ordersPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstDelivery,
    indexOfLastDelivery
  );

  // Conditionally render results count
  const resultCount =
    filteredOrders.length === 0
      ? "0"
      : `${Math.min(
          indexOfFirstDelivery + 1,
          filteredOrders.length
        )}-${Math.min(indexOfLastDelivery, filteredOrders.length)} of ${
          filteredOrders.length
        }`;

  return (
    <div className="pt-3 mb-3">
      <InputGroup
        large
        leftIcon="search"
        placeholder="Search by order number..."
        value={searchQuery}
        onChange={handleSearchInputChange}
        style={{ marginBottom: "1rem" }}
      />
      <p>Showing results {resultCount}</p>
      {currentOrders.length === 0 ? (
        <Card elevation={Elevation.TWO}>
          <p>No invoices available.</p>
        </Card>
      ) : (
        <>
          <Row>
            {currentOrders.map((order) => (
              <Col key={order.id} xs={12} md={6} className="order-card-styling">
                <Card elevation={Elevation.TWO}>
                  <h5>
                    <Row className="d-flex ">
                      <Col className="d-flex ">
                        <p>
                          <strong>Amount Due </strong>
                        </p>
                      </Col>

                      <Col className="d-flex justify-content-end">
                        <p>
                          <strong className="text-success">
                            ${order.calculatedRates.totalCharge}
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  </h5>
                  <Row className="d-flex order-card-styling">
                    <Col>
                      <p>
                        <strong>Invoice #</strong>
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>{order.orderNumber}</p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <strong>Status</strong>
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>{order.status}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <strong>Delivered On </strong>{" "}
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>{formatDate(order.deliveryDate)}</p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <strong>Forwarder Company Name </strong>{" "}
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>{order.selectedForwarder.forwarderCompanyName}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <strong>Forwarder Name </strong>{" "}
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>
                        {order.forwarderFirstName} {order.forwarderLastName}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <strong>Forwarder Address </strong>{" "}
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>
                        {order.selectedForwarder.forwarderAddress} <br />
                        {order.selectedForwarder.forwarderAddressCity},{" "}
                        {order.selectedForwarder.forwarderAddressState}{" "}
                        {order.selectedForwarder.forwarderAddressZip}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Forwarder Primary Phone </strong>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <p>{order.selectedForwarder.forwarderPrimaryPhone}</p>
                    </Col>
                  </Row>
                  <div className="mt-2">
                    <AnchorButton
                      intent="primary"
                      href={`/view_invoice/${order.id}`}
                    >
                      View Invoice
                    </AnchorButton>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          {/* Pagination controls */}
          <Pagination10
            currentPage={currentPage}
            ordersPerPage={ordersPerPage}
            totalOrders={filteredOrders.length} // Use filtered orders length for pagination
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default GetInvoices;
