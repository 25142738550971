import React from "react";
import CreateAccount from "../components/managerView/CreateAccount";
import Users from "../components/Users";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import { useAuth } from "../context/AuthContext";
import { Container } from "react-bootstrap";
import { useTheme } from "../context/ThemeContext";

const ActiveUsers = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  // Check if user and user.role are defined before accessing user.role
  const role = user && user.role;

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {role === "Manager" || role === "Admin" ? (
        <Navigation />
      ) : role === "Driver" ? (
        <NavigationDriver />
      ) : null}
      <div className="container pt-3">
        <h2>Users</h2>
        {role === "Manager" || role === "Admin" ? (
          <div>
            <CreateAccount />
            <Users />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActiveUsers;
