import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Row, Col, Badge, ListGroup, Tab, Tabs, Button } from "react-bootstrap";
import { Card, Elevation, Icon } from "@blueprintjs/core";
import { getTodaysLoggedInUserOrders } from "../../javascript/api";

import Pagination10 from "../Pagination10";

const TodaysOrders = () => {
  const { user } = useAuth();
  const [deliveries, setDeliveries] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveriesPerPage, setDeliveriesPerPage] = useState(3);

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const dueDeliveries = await getTodaysLoggedInUserOrders(user);
        setDeliveries(dueDeliveries, dueDeliveries.length);
        return dueDeliveries.length;
      } catch (error) {
        console.error("Error fetching due deliveries:", error);
      }
    };

    fetchDeliveries();
  }, [user]);

  // Pagination logic
  const indexOfLastDelivery = currentPage * deliveriesPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage;
  const currentDeliveries = deliveries.slice(
    indexOfFirstDelivery,
    indexOfLastDelivery
  );

  return (
    <div className=" mb-3">
      {currentDeliveries.length === 0 ? (
        <Card className="text-center">
          <p>
            No <Icon icon="truck" intent="primary" /> deliveries today.
          </p>
        </Card>
      ) : (
        <Card compact={true}>
          <div className="text-center">
            <h1>{currentDeliveries.length}</h1>
          </div>
        </Card>
      )}
    </div>
  );
};

export default TodaysOrders;
