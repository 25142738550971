import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useAuth } from "../../context/AuthContext";
import Header from "../Header";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { createUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await createUser(email, password);
      navigate("/account");
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div>
      <Header />
      <div>
        <Row>
          <Form>
            <h1>Register</h1>
            <p>Create your account.</p>
            <Row>
              <Form.Label
                htmlFor="inlineFormInputGroup"
                visuallyHidden
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              >
                Email
              </Form.Label>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  required
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Group>
            </Row>
            <Row>
              <Button
                type="submit"
                variant="primary"
                onClick={handleSubmit}
                className="mb-2"
              >
                Register
              </Button>
            </Row>
          </Form>
          <p>
            Already have an account? <Link to="/login">Login here.</Link>
          </p>
        </Row>
      </div>
    </div>
  );
};

export default Register;
