import React from "react";
import Image from "react-bootstrap/Image";

const Header = () => {
  return (
    <div className="d-flex align-items-center justify-content-between gap-4 text-center">
      <h5>Quick Delivery Inc</h5>
      <Image src="truck.png" className="w-25" />
    </div>
  );
};

export default Header;
