import React, { useEffect, useState, useRef } from "react";
import { ListGroup, Badge } from "react-bootstrap";
import { Card, Elevation, Icon, Spinner } from "@blueprintjs/core";
import { getUpcomingLoggedInUserOrders } from "../../javascript/api";
import { useAuth } from "../../context/AuthContext";
import Pagination10 from "../Pagination10";
import formatDate from "../../utility/formatDate";

const MyUpcomingDeliveries = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [myUpcomingDeliveries, setMyUpcomingDeliveries] = useState([]);
  const [ordersPerPage, setOrdersPerPage] = useState(3);
  const [loading, setLoading] = useState(true); // Track loading state
  const containerRef = useRef(null); // Ref for the container element

  // Calculate the indexes of the orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = myUpcomingDeliveries.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const resultCount =
    myUpcomingDeliveries.length > 0
      ? `${Math.min(
          indexOfFirstOrder + 1,
          myUpcomingDeliveries.length
        )}-${Math.min(indexOfLastOrder, myUpcomingDeliveries.length)} of ${
          myUpcomingDeliveries.length
        }`
      : "";

  useEffect(() => {
    const fetchMyUpcomingDeliveries = async () => {
      try {
        const orders = await getUpcomingLoggedInUserOrders(user);
        setMyUpcomingDeliveries(orders);
        setLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error("Error fetching logged-in user's orders:", error);
        setLoading(false); // Set loading state to false in case of error
      }
    };

    fetchMyUpcomingDeliveries();
  }, [user]);

  return (
    <div ref={containerRef}>
      {loading ? ( // Display loading indicator while data is being fetched
        <Spinner intent="primary" />
      ) : myUpcomingDeliveries.length === 0 ? ( // Check if there are no deliveries
        <Card>
          <div className="text-center">
            <p>You have no upcoming deliveries.</p>
          </div>
        </Card>
      ) : (
        <ListGroup>
          {currentOrders.map((order) => (
            <ListGroup.Item
              key={order.id}
              className="mb-3"
              style={{ cursor: "pointer" }}
            >
              <strong>Order Number:</strong> {order.orderNumber}
              <br />
              <strong>Status:</strong> {order.status}
              <br />
              <strong>Delivery Date:</strong> {formatDate(order.deliveryDate)}{" "}
              <Badge pill>Upcoming</Badge>
              <br />
              <strong>Forwarder Company Name: </strong>{" "}
              {order.selectedForwarder.forwarderCompanyName} <br />
              <strong>Forwarder Name: </strong> {order.forwarderFirstName}{" "}
              {order.forwarderLastName} <br />
              <strong>Forwarder Address: </strong>{" "}
              {order.selectedForwarder.forwarderAddress} <br />
              {order.selectedForwarder.forwarderAddressCity}{" "}
              {order.selectedForwarder.forwarderAddressState}{" "}
              {order.selectedForwarder.forwarderAddressZip} <br />
              <strong>Forwarder Primary Phone: </strong>
              {order.selectedForwarder.forwarderPrimaryPhone}
              {/* Add more order details as needed */}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      <div className="container">
        {resultCount && <p>Showing results {resultCount}</p>}
        {myUpcomingDeliveries.length > ordersPerPage && (
          <Pagination10
            currentPage={currentPage}
            ordersPerPage={ordersPerPage}
            totalOrders={myUpcomingDeliveries.length}
            paginate={paginate}
          />
        )}
      </div>
    </div>
  );
};

export default MyUpcomingDeliveries;
