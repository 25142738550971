import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getActiveForwarders } from "../../javascript/api";
import { Container, Row, Col, Card } from "react-bootstrap";
import Pagination10 from "../Pagination10";

const GetForwardersList = () => {
  const [forwarders, setForwarders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [forwardersPerPage] = useState(6); // Set the number of orders per page

  useEffect(() => {
    const fetchForwarders = async () => {
      try {
        const forwardersData = await getActiveForwarders();
        setForwarders(forwardersData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchForwarders();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  // Pagination logic
  const indexOfLastDelivery = currentPage * forwardersPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - forwardersPerPage;
  const currentForwarders = forwarders.slice(
    indexOfFirstDelivery,
    indexOfLastDelivery
  );

  // Conditionally render results count
  const resultCount =
    forwarders.length === 0
      ? "0"
      : `${Math.min(indexOfFirstOrder + 1, forwarders.length)}-${Math.min(
          indexOfLastOrder,
          forwarders.length
        )} of ${forwarders.length}`;

  return (
    <div className="pt-3 mb-3">
      <p>Showing results {resultCount}</p>
      {currentForwarders.length === 0 ? (
        <p>No forwarders available.</p>
      ) : (
        <>
          <Row>
            {currentForwarders.map((forwarder) => (
              <Col key={forwarder.id} xs={12} md={6} lg={4}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>{forwarder.forwarderCompanyName}</Card.Title>
                    <Card.Text>
                      <Row>
                        <Col>{forwarder.forwarderFirstName}</Col>
                        <Col>{forwarder.forwarderLastName}</Col>
                      </Row>
                      <Row>
                        <Col>
                          {forwarder.forwarderAddress} <br />
                        </Col>
                        <Col>
                          {forwarder.forwarderAddressCity},{" "}
                          {forwarder.forwarderAddressState}{" "}
                          {forwarder.forwarderAddressZip}
                        </Col>
                      </Row>
                    </Card.Text>
                    <Link
                      to={`/view_forwarder/${forwarder.id}`}
                      className="btn btn-link"
                    >
                      View Forwarder
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          {/* Pagination controls */}
          <Pagination10
            currentPage={currentPage}
            forwardersPerPage={forwardersPerPage}
            totalForwarders={forwarders.length} // Use orders length for pagination
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default GetForwardersList;
