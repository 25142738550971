import React from "react";
import Navigation from "../components/managerView/Navigation";
import CustomerDetails from "../components/managerView/CustomerDetails";

const NewCustomer = () => {
  return (
    <div>
      <Navigation />
      <div className="container">
        <CustomerDetails />
      </div>
    </div>
  );
};

export default NewCustomer;
