import React, { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import {
  Row,
  Col,
  Badge,
  ListGroup,
  Tab,
  Tabs,
  Container,
} from "react-bootstrap";
import {
  Card,
  Icon,
  Elevation,
  Divider,
  Section,
  SectionCard,
} from "@blueprintjs/core";
import { getDueDeliveries } from "../javascript/api";
import DeliveryAttempts from "./DeliveryAttempts";
import formatDate from "../utility/formatDate";
import Signature from "./Signature";
import Pagination10 from "./Pagination10";
import { useTheme } from "../context/ThemeContext";

const GetDeliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [contactKey, setContactKey] = useState("forwarder");
  const [detailsKey, setDetailsKey] = useState("pieces");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderForSignature, setSelectedOrderForSignature] =
    useState(null);
  const [captureSignatureModal, setCaptureSignatureModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveriesPerPage, setDeliveriesPerPage] = useState(3);
  const { isDarkMode } = useTheme();
  const textColor = isDarkMode ? "#fff" : "#000";

  const handleCaptureSignature = (order) => {
    setSelectedOrderForSignature(order);
    setSelectedOrder(order);
    setCaptureSignatureModal(true);
  };

  const handleSaveSignature = async (signatureData) => {
    try {
      if (!selectedOrder || !selectedOrder.id) {
        console.error("Error: Invalid or missing order data");
        return;
      }

      const orderRef = doc(firestore, "orders", selectedOrder.id);
      await updateDoc(orderRef, { signature: signatureData });

      setSelectedOrder({
        ...selectedOrder,
        signature: signatureData,
      });

      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((prevDelivery) =>
          prevDelivery.id === selectedOrder.id
            ? { ...prevDelivery, signature: true }
            : prevDelivery
        )
      );

      // Update the state to indicate that the signature has been captured
      setSelectedOrder((prevOrder) => ({ ...prevOrder, signature: true }));
    } catch (error) {
      console.error("Error saving signature:", error);
    } finally {
      setCaptureSignatureModal(false);
    }
  };

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const dueDeliveries = await getDueDeliveries();
        setDeliveries(dueDeliveries);
      } catch (error) {
        console.error("Error fetching due deliveries:", error);
      }
    };

    fetchDeliveries();
  }, []);

  const isPastDue = (delivery) => {
    // Get the current date and time in the local time zone
    const today = new Date();

    // Parse delivery date string into a Date object
    const deliveryDateTime = new Date(delivery.deliveryDate);

    // Adjust delivery date time for timezone offset
    deliveryDateTime.setMinutes(
      deliveryDateTime.getMinutes() - today.getTimezoneOffset()
    );

    // Compare delivery date and time with current date and time
    return deliveryDateTime > today;
  };

  // Pagination logic
  const indexOfLastDelivery = currentPage * deliveriesPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage;
  const currentDeliveries = deliveries.slice(
    indexOfFirstDelivery,
    indexOfLastDelivery
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const resultCount = `${indexOfFirstDelivery + 1}-${
    indexOfLastDelivery > deliveries.length
      ? deliveries.length
      : indexOfLastDelivery
  } of ${deliveries.length}`;

  return (
    <Container className="pb-3" style={{ minHeight: "100vh" }}>
      <div className="mt-3">
        <h2>
          Deliveries <Icon icon="truck" intent="primary" size={30} />
        </h2>
      </div>
      <p>Showing results {resultCount}</p>
      {currentDeliveries.length === 0 ? (
        <Card elevation={Elevation.TWO}>
          <div className="text-center">
            <p>No deliveries available.</p>
          </div>
        </Card>
      ) : (
        <>
          {currentDeliveries.map((delivery) => (
            <Col key={delivery.id} className="mb-3">
              <Section
                title={`Order Number: ${delivery.orderNumber}`}
                rightElement={<Badge pill>{delivery.status}</Badge>}
              >
                <SectionCard>
                  <Row>
                    <Col>
                      <h5>Scheduled Delivery</h5>

                      <Row>
                        <Col className="mb-3">
                          <strong
                            style={{
                              color: isPastDue(delivery) ? "red" : textColor,
                            }}
                          >
                            <p>
                              Scheduled Delivery Date:{" "}
                              {formatDate(delivery.deliveryDate)}
                            </p>
                          </strong>
                          <br /> <br />
                          <Tabs
                            id="contactInfo"
                            activeKey={contactKey}
                            onSelect={(k) => setContactKey(k)}
                            className="mb-3"
                          >
                            <Tab
                              eventKey="customer"
                              title="Customer"
                              className="dashboard-col"
                            >
                              <p>
                                <strong>Customer Info</strong>
                              </p>
                              Customer Name:{" "}
                              {delivery
                                ? `${delivery.customerFirstName} ${delivery.customerLastName}`
                                : "N/A"}{" "}
                              <br />
                              Customer Address:{" "}
                              {delivery
                                ? `${delivery.customerAddress}, ${delivery.customerAddressCity}, ${delivery.customerAddressState} ${delivery.customerAddressZip}`
                                : "N/A"}
                              <br />
                              Company Name:{" "}
                              {delivery
                                ? `${delivery.customerCompanyName}`
                                : "N/A"}{" "}
                              <br />
                              Customer Primary Phone:{" "}
                              {delivery
                                ? `${delivery.customerPrimaryPhone}`
                                : "N/A"}
                              <br />
                            </Tab>
                            <Tab
                              eventKey="forwarder"
                              title="Forwarder"
                              className="dashboard-col"
                            >
                              <p>
                                <strong>Forwarder Info</strong>
                              </p>
                              Company Name:{" "}
                              {delivery.selectedForwarder
                                ? delivery.selectedForwarder
                                    .forwarderCompanyName
                                : "N/A"}
                              <br />
                              Forwarder Address:{" "}
                              {delivery.selectedForwarder
                                ? `${delivery.selectedForwarder.forwarderAddress}, ${delivery.selectedForwarder.forwarderAddressCity}, ${delivery.selectedForwarder.forwarderAddressState} ${delivery.selectedForwarder.forwarderAddressZip}`
                                : "N/A"}
                              <br />
                              Customer Primary Phone:{" "}
                              {delivery.selectedForwarder
                                ? `${delivery.selectedForwarder.forwarderPrimaryPhone}`
                                : "N/A"}
                              <br />
                            </Tab>
                          </Tabs>
                          <br />
                          <Col md={12}>
                            <Tabs
                              activeKey={detailsKey}
                              onSelect={(k) => setDetailsKey(k)}
                              id="detailsInfo"
                              className="mb-3"
                            >
                              <Tab
                                eventKey="pieces"
                                title="Pieces"
                                className="dashboard-col"
                              >
                                <p>
                                  <span>
                                    <strong>Pieces: </strong>
                                    <Badge pill>
                                      {Array.isArray(delivery.pieces)
                                        ? parseInt(
                                            delivery.pieces.reduce(
                                              (totalQuantity, piece) =>
                                                totalQuantity +
                                                parseInt(piece.quantity, 10),
                                              0
                                            ),
                                            10
                                          )
                                        : 0}
                                    </Badge>
                                  </span>
                                </p>

                                {Array.isArray(delivery.pieces) &&
                                  delivery.pieces.map((piece, index) => (
                                    <Card
                                      key={index}
                                      className="mb-2 mt-2"
                                      compact={true}
                                    >
                                      <Row>
                                        <Col md={4}>Sku: {piece.sku}</Col>
                                        <Col md={4}>
                                          Description: {piece.description}
                                        </Col>
                                        <Col md={4}>Volume: {piece.volume}</Col>
                                        <Col md={4}>Weight: {piece.weight}</Col>
                                        <Col md={4}>
                                          Quantity: {piece.quantity}
                                        </Col>
                                        <Col md={4}>
                                          Price: ${piece.price.toFixed(2)}
                                        </Col>
                                      </Row>
                                    </Card>
                                  ))}
                              </Tab>
                              <Tab
                                eventKey="fees"
                                title="Fees"
                                className="dashboard-col"
                              >
                                {delivery.calculatedRates &&
                                  Array.isArray(
                                    delivery.calculatedRates.fees
                                  ) &&
                                  delivery.calculatedRates.fees.length > 0 && (
                                    <div>
                                      <p>
                                        <strong>Fees</strong>
                                      </p>
                                      <ul
                                        className="p-0"
                                        style={{ listStyle: "none" }}
                                      >
                                        {/* Include delivery fee and total charge in the list */}
                                        <li>
                                          <Row>
                                            <Col md={6}>Delivery Fee:</Col>
                                            <Col
                                              md={6}
                                            >{`$${delivery.calculatedRates.deliveryFee.toFixed(
                                              2
                                            )}`}</Col>
                                          </Row>
                                        </li>

                                        {/* Include fees from calculatedRates.fees array */}
                                        {delivery.calculatedRates.fees.map(
                                          (fee, feeIndex) => (
                                            <li key={feeIndex}>
                                              <Row>
                                                <Col md={6}>{fee.name}:</Col>
                                                <Col
                                                  md={6}
                                                >{`$${fee.price.toFixed(
                                                  2
                                                )}`}</Col>
                                              </Row>
                                              {/* Add more properties as needed */}
                                            </li>
                                          )
                                        )}

                                        <li>
                                          <Row className="mt-2">
                                            <Divider />
                                            <Col md={6}>
                                              <p>
                                                <strong>Total Charge:</strong>
                                              </p>
                                            </Col>
                                            <Col
                                              md={6}
                                            >{`$${delivery.calculatedRates.totalCharge}`}</Col>
                                          </Row>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                              </Tab>
                            </Tabs>
                          </Col>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6} className="my-auto dashboard-col">
                      <Card elevation={Elevation.TWO} className="mb-3">
                        <h5 className="pl-3">
                          Delivery Attempts: {delivery.deliveryAttempts.length}
                        </h5>
                        {Array.isArray(delivery.deliveryAttempts) &&
                        delivery.deliveryAttempts.length >= 0 ? (
                          <DeliveryAttempts
                            order={delivery}
                            handleCaptureSignature={handleCaptureSignature}
                          />
                        ) : (
                          <p className="p-3">No delivery attempts</p>
                        )}
                        {selectedOrderForSignature && captureSignatureModal && (
                          <Signature
                            onSave={handleSaveSignature}
                            onCancel={() => setCaptureSignatureModal(false)}
                          />
                        )}
                      </Card>
                      <div className="text-center">
                        <p>Assigned Driver: {delivery.assignedDriver}</p>
                      </div>
                    </Col>
                  </Row>
                </SectionCard>
              </Section>
            </Col>
          ))}
          {/* Pagination controls */}
          <Pagination10
            currentPage={currentPage}
            ordersPerPage={deliveriesPerPage}
            totalOrders={deliveries.length} // Total number of deliveries
            paginate={paginate}
          />
        </>
      )}
    </Container>
  );
};

export default GetDeliveries;
