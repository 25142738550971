import React, { useState, useEffect } from "react";
import { Form, Col, Row, Container } from "react-bootstrap";
import {
  Button,
  ButtonGroup,
  Section,
  SectionCard,
  Card,
  Icon,
} from "@blueprintjs/core";
import SuccessToast from "../SuccessToast";
import { Formik, Field } from "formik";
import { getActiveDrivers } from "../../javascript/api";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import OrderPieces from "./OrderPieces";
import RateCalculator from "./RateCalculator";

const EditOrderDetails = (props) => {
  const [activeDrivers, setActiveDrivers] = useState([]);
  const { editedOrder: initialEditedOrder, setEditedOrder } = props;
  const [editedOrder, setEditedOrderState] = useState(null);
  const [originalOrder, setOriginalOrder] = useState(null);
  const [file, setFile] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const storage = getStorage();

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    // Fetch active drivers when the component mounts
    fetchActiveDrivers();
  }, []);

  useEffect(() => {
    // Set the editedOrder and originalOrder states when the component mounts or when the editedOrder prop changes
    setEditedOrderState(initialEditedOrder);
    setOriginalOrder(initialEditedOrder);
  }, [initialEditedOrder]);

  const fetchActiveDrivers = async () => {
    try {
      const activeDriversList = await getActiveDrivers();
      setActiveDrivers(activeDriversList);
    } catch (error) {
      console.error("Error fetching active drivers:", error);
    }
  };

  if (!editedOrder) {
    return null; // or some default behavior for null editedOrder
  }

  const handleSaveChanges = async (values) => {
    try {
      let uploadedFileUrl = editedOrder.files; // Default to existing file URL
      if (file) {
        uploadedFileUrl = await uploadFileToStorage(file, values.orderNumber);
      }
      // Update order with new values and file URL
      const updatedOrder = {
        ...values,
        files: uploadedFileUrl,
      };
      // Perform any additional save operation if needed

      // Update editedOrder with the new values and file URL
      setEditedOrder(updatedOrder);

      // Update Firestore document with the new order details
      await updateOrderInFirestore(updatedOrder);
      setShowToast(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        setShowToast(false);
      }, 6000);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const updateOrderInFirestore = async (updatedOrder) => {
    try {
      // Get a reference to the document in the "orders" collection
      const orderDocRef = doc(firestore, "orders", updatedOrder.id);

      // Update the document with the new order data
      await updateDoc(orderDocRef, updatedOrder);
    } catch (error) {
      console.error("Error updating order in Firestore:", error);
      throw error;
    }
  };

  const uploadFileToStorage = async (file, orderNumber) => {
    try {
      // Construct the path with the order number or forwarder company name
      let path;
      if (orderNumber) {
        path = `files/${orderNumber}/${file.name}`;
      } else {
        path = `files/${file.name}`; // Default path
      }
      // Upload the file to the constructed path
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error("Error uploading file to storage:", error);
      throw error;
    }
  };
  const getFileNameFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const pathArray = decodedUrl.split("/");
    const fileNameWithToken = pathArray[pathArray.length - 1];
    // Split the file name using "?" as a delimiter to remove the token or additional info
    const fileNameWithoutToken = fileNameWithToken.split("?")[0];
    // Split the file name using "/" as a delimiter to get only the actual file name
    const fileParts = fileNameWithoutToken.split("/");
    return fileParts[fileParts.length - 1];
  };
  if (!editedOrder) {
    return null; // or some default behavior for null editedOrder
  }

  return (
    <Container className="pb-3" style={{ minHeight: "100vh" }}>
      <div className="mt-3">
        {/* Render the SuccessToast component conditionally */}
        {showToast && (
          <SuccessToast showToast={showToast} setShowToast={setShowToast} />
        )}
        <div className="d-flex justify-content-between">
          <h2>Edit Order</h2>
          <Button
            icon="circle-arrow-left"
            id="order-back-button"
            minimal={true}
            text="Back"
            intent="primary"
            onClick={() => goBack()}
          />
        </div>
      </div>

      <Formik
        initialValues={editedOrder || {}}
        enableReinitialize
        onSubmit={(values) => handleSaveChanges(values)}
      >
        {({ values, handleChange }) => (
          <Form className="mb-3">
            <Section
              title="Customer Details"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard className="p-3 mb-3">
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formOrderId">
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.id || ""}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formOrderNumber">
                      <Form.Label>Order Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.orderNumber || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            orderNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formDeliveryDate">
                      <Form.Label>Delivery Date</Form.Label>
                      <Field
                        type="date"
                        name="deliveryDate"
                        className="form-control"
                        value={
                          values.deliveryDate
                            ? values.deliveryDate.split("T")[0]
                            : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setEditedOrderState({
                            ...editedOrder,
                            deliveryDate: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formcustomerAddress">
                      <Form.Label>Customer Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerAddress || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formcustomerAddressCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerAddressCity || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerAddressCity: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="formcustomerAddressState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerAddressState || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerAddressState: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formcustomerAddressZip">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerAddressZip || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerAddressZip: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formCustomerPrimaryPhone">
                      <Form.Label>Customer Primary Phone</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerPrimaryPhone || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerPrimaryPhone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formCustomerSecondaryPhone">
                      <Form.Label>Customer Secondary Phone</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.customerSecondaryPhone || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            customerSecondaryPhone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </SectionCard>
            </Section>

            <Section
              title="Forwarder Details"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formforwarderAddress">
                      <Form.Label>Forwarder Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.selectedForwarder?.forwarderAddress || ""}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderAddress: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formforwarderAddressCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          values.selectedForwarder?.forwarderAddressCity || ""
                        }
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderAddressCity: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="formforwarderAddressState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          values.selectedForwarder?.forwarderAddressState || ""
                        }
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderAddressState: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="formforwarderAddressZip">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          values.selectedForwarder?.forwarderAddressZip || ""
                        }
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderAddressZip: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formForwarderPrimaryPhone">
                      <Form.Label>Forwarder Primary Phone</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          values.selectedForwarder?.forwarderPrimaryPhone || ""
                        }
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderPrimaryPhone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formForwarderSecondaryPhone">
                      <Form.Label>Forwarder Secondary Phone</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          values.selectedForwarder?.forwarderSecondaryPhone ||
                          ""
                        }
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            forwarderSecondaryPhone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </SectionCard>
            </Section>

            <Section
              title="Pieces"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                {editedOrder.pieces.map((piece, index) => (
                  <Row key={index}>
                    <Col md={2}>
                      <Form.Group controlId={`formPiecesSku${index}`}>
                        <Form.Label>Sku</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.sku || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              sku: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId={`formPiecesDescription${index}`}>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.description || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              description: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId={`formPiecesPrice${index}`}>
                        <Form.Label>Price ($)</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.price || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              price: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <Form.Group controlId={`formPiecesQty${index}`}>
                        <Form.Label>Qty</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.quantity || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              quantity: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId={`formPiecesWeight${index}`}>
                        <Form.Label>Weight (lbs)</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.weight || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              weight: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId={`formPiecesVolume${index}`}>
                        <Form.Label>Volume (cu. ft)</Form.Label>
                        <Form.Control
                          type="text"
                          value={piece.volume || ""}
                          onChange={(e) => {
                            const updatedPieces = [...editedOrder.pieces];
                            updatedPieces[index] = {
                              ...updatedPieces[index],
                              volume: e.target.value,
                            };
                            setEditedOrder({
                              ...editedOrder,
                              pieces: updatedPieces,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ))}
              </SectionCard>
            </Section>

            <Section
              title="Additional Info"
              className="mb-2 order-card-styling"
              compact={true}
              collapsible={true}
            >
              <SectionCard>
                <Col md={12}>
                  <Form.Group controlId="formNotes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      type="textarea"
                      as="textarea"
                      name="notes"
                      value={editedOrder?.notes || ""}
                      onChange={(e) =>
                        setEditedOrder({
                          ...(editedOrder || {}),
                          notes: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload a new document</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        name="files"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {editedOrder.files ? (
                      <>
                        <Button
                          variant="link"
                          onClick={() =>
                            window.open(editedOrder.files, "_blank")
                          }
                        >
                          Expand Down Expand Up @@ -615,6 +617,19 @@
                          {getFileNameFromUrl(editedOrder.files)}
                        </Button>
                      </>
                    ) : (
                      <p>No attachments</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formAssignedDriver">
                      <Form.Label>Assigned Driver</Form.Label>
                      <Field
                        as="select"
                        name="assignedDriver"
                        className="form-select"
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            assignedDriver: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Select a driver
                        </option>
                        <option disabled>{editedOrder.assignedDriver}</option>
                        {activeDrivers?.map((driver) => (
                          <option key={driver.id} value={driver.name}>
                            {driver.name}
                          </option>
                        ))}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="status">
                      <Form.Label>Order Status</Form.Label>
                      <Field
                        size="sm"
                        as="select"
                        name="status"
                        value={editedOrder.status}
                        className="form-select"
                        onChange={(e) =>
                          setEditedOrder({
                            ...(editedOrder || {}),
                            status: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          currently marked {editedOrder.status}
                        </option>
                        <option value="Active">Active</option>
                        <option value="Delivered">
                          Delivered (Awaiting Invoice)
                        </option>
                        <option value="Invoiced">
                          Awaiting Payment (Invoiced)
                        </option>
                        <option value="Complete">Complete (Closed)</option>
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
              </SectionCard>
            </Section>
            <ButtonGroup minimal={true}>
              <Button
                onClick={() => handleSaveChanges(values)}
                intent="success"
                icon="confirm"
              >
                Save Changes
              </Button>
              <Button onClick={goBack} intent="warning">
                Cancel Changes
              </Button>
            </ButtonGroup>

            {/* Add other form fields for editing */}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditOrderDetails;
