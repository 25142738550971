import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import Modal from "react-bootstrap/Modal";
import { Button } from "@blueprintjs/core";
import { useTheme } from "../context/ThemeContext";
import { Container } from "react-bootstrap";

const Signature = ({ onSave, onCancel }) => {
  const { isDarkMode } = useTheme();
  const signatureRef = useRef();

  const handleSave = () => {
    if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      onSave(signatureData);
    }
  };

  const handleClear = () => {
    signatureRef.current.clear();
  };

  return (
    <Modal show={true} onHide={onCancel} dialogClassName="modal-fullscreen">
      <Container
        className={isDarkMode ? "bp5-dark bg-dark" : ""}
        style={{ minHeight: "100vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignaturePad
            ref={signatureRef}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            minimal={true}
            icon="trash"
            intent="warning"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            minimal={true}
            icon="clean"
            intent="danger"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            minimal={true}
            icon="saved"
            intent="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Container>
    </Modal>
  );
};

export default Signature;
