import React from "react";
import { Nav } from "react-bootstrap";
import { Button } from "@blueprintjs/core";

const Pagination10 = ({
  currentPage,
  ordersPerPage,
  totalOrders,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <Button onClick={() => paginate(number)} className="page-link">
              {number}
            </Button>
          </li>
        ))}
      </ul>
    </Nav>
  );
};

export default Pagination10;
