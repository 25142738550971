import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Card, Elevation, Divider } from "@blueprintjs/core";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import formatDate from "../../utility/formatDate";

const ViewInvoices = ({ selectedOrder }) => {
  const [orderStatus, setOrderStatus] = useState("");

  const renderField = (label, value) => {
    if (
      value != "undefined" &&
      value != "undefined undefined" &&
      value != "undefined, undefined undefined" &&
      value != undefined
    ) {
      return (
        value && (
          <>
            {label}: {value}
            <br />
          </>
        )
      );
    }
  };

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const orderDoc = await getDoc(
          doc(firestore, "orders", selectedOrder.id)
        );
        if (orderDoc.exists()) {
          const data = orderDoc.data();
          if (data && data.status) {
            setOrderStatus(data.status);
          }
        }
      } catch (error) {
        console.error("Error fetching order status:", error);
      }
    };

    if (selectedOrder && selectedOrder.id) {
      fetchOrderStatus();
    }
  }, [selectedOrder]);

  return (
    <>
      {orderStatus === "Invoiced" || orderStatus === "Paid" ? (
        <Container className="pb-3" style={{ minHeight: "100vh" }}>
          <Row>
            <Col>
              <h1>Invoice</h1>
            </Col>
          </Row>
          <Card elevation={Elevation.TWO} className="order-card-styling">
            <Row>
              <Col className="mb-2">
                <Card>
                  <h5>
                    <Row className="d-flex">
                      <Col className="d-flex">
                        Invoice # {selectedOrder.orderNumber}
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <strong>{selectedOrder.status}</strong>
                      </Col>
                    </Row>
                  </h5>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-2">
                <Card>
                  <p>
                    <strong>Customer Info</strong>
                  </p>
                  <div className="mb-2">
                    {renderField(
                      "Customer Name",
                      `${selectedOrder?.customerFirstName} ${selectedOrder?.customerLastName}`
                    )}
                  </div>
                  <div className="mb-2">
                    {renderField(
                      "Company Name",
                      selectedOrder?.customerCompanyName
                    )}
                  </div>
                  <div className="mb-2">
                    {renderField("Address", selectedOrder?.customerAddress)}
                  </div>

                  {renderField(
                    "Address Line 2",
                    `${selectedOrder?.customerAddressCity}, ${selectedOrder?.customerAddressState} ${selectedOrder?.customerAddressZip}`
                  )}
                </Card>
              </Col>
              <Col md={6} className="mb-2 ">
                <Card>
                  <div>
                    <p>
                      <strong>Forwarder Info</strong>
                    </p>
                  </div>
                  <div className="mb-2">
                    {renderField(
                      "Forwarder Name",
                      selectedOrder?.forwarderFirstName
                        ? `${selectedOrder?.forwarderFirstName} ${selectedOrder?.forwarderLastName}`
                        : selectedOrder?.selectedForwarder
                        ? `${selectedOrder?.selectedForwarder.forwarderFirstName} ${selectedOrder?.selectedForwarder.forwarderLastName}`
                        : "N/A"
                    )}
                  </div>
                  <div className="mb-2">
                    {renderField(
                      "Company Name",
                      selectedOrder?.forwarderCompanyName
                        ? selectedOrder?.forwarderCompanyName
                        : selectedOrder?.selectedForwarder?.forwarderCompanyName
                        ? selectedOrder.selectedForwarder.forwarderCompanyName
                        : "N/A"
                    )}
                  </div>
                  <div className="mb-2">
                    {renderField(
                      "Address",
                      selectedOrder?.forwarderAddress
                        ? selectedOrder?.forwarderAddress
                        : selectedOrder?.selectedForwarder
                        ? selectedOrder.selectedForwarder.forwarderAddress
                        : "N/A"
                    )}
                  </div>

                  {renderField(
                    "Address Line 2",
                    selectedOrder?.forwarderAddressCity
                      ? `${selectedOrder?.forwarderAddressCity}, ${selectedOrder?.forwarderAddressState} ${selectedOrder?.forwarderAddressZip}`
                      : selectedOrder?.selectedForwarder
                      ? `${selectedOrder?.selectedForwarder.forwarderAddressCity}, ${selectedOrder?.selectedForwarder.forwarderAddressState} ${selectedOrder?.selectedForwarder.forwarderAddressZip}`
                      : "N/A"
                  )}
                </Card>
              </Col>
            </Row>
            {/* Customer details section */}
            <Row>
              <Col className="mb-2 ">
                <Card className="mb-2 ">
                  <p>
                    <strong>Pieces</strong>
                  </p>
                  {selectedOrder &&
                  Array.isArray(selectedOrder.pieces) &&
                  selectedOrder.pieces.length > 0 ? (
                    <div>
                      <Row className="mb-2">
                        <Col>
                          <strong>Sku </strong>
                        </Col>
                        <Col>
                          <strong>Description </strong>
                        </Col>
                        <Col>
                          <strong>Volume </strong>
                        </Col>
                        <Col>
                          <strong>Weight </strong>
                        </Col>
                        <Col>
                          <strong>Qty </strong>
                        </Col>
                        <Col>
                          <strong>Price </strong>
                        </Col>
                      </Row>
                      {selectedOrder.pieces.map((piece, index) => (
                        <Row key={index} className="mb-2">
                          <Col> {piece.sku}</Col>
                          <Col> {piece.description}</Col>
                          <Col> {piece.volume}</Col>
                          <Col> {piece.weight}</Col>
                          <Col> {piece.quantity}</Col>
                          <Col> {piece.price}</Col>
                        </Row>
                      ))}
                    </div>
                  ) : (
                    <p>No pieces available</p>
                  )}

                  <Divider />
                  <Row className="mt-2">
                    <Col md={6}>
                      <p>
                        <strong>Delivered On: </strong>
                        {formatDate(selectedOrder?.deliveryDate)}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <span>
                          <strong>Signed By: </strong>
                        </span>
                        <img
                          src={selectedOrder?.signature}
                          alt="Signature"
                          width={150}
                        />
                      </p>
                    </Col>
                  </Row>
                </Card>

                <Card className="mb-2">
                  <p>
                    <strong>Fees</strong>
                  </p>

                  <p>
                    Delivery Fee - Price: $
                    {selectedOrder?.calculatedRates.deliveryFee}
                  </p>

                  {selectedOrder &&
                  Array.isArray(selectedOrder.calculatedRates.fees) &&
                  selectedOrder.calculatedRates.fees.length > 0 ? (
                    <div>
                      {selectedOrder.calculatedRates.fees.map((fee, index) => (
                        <div key={index}>
                          {/* Access properties of each fee */}
                          <p>
                            {fee.name} - Price: ${fee.price}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No fees available</p>
                  )}

                  <Row className="mt-2">
                    <Col md={6}>
                      <p>
                        <strong>
                          Total Charge: $
                          {selectedOrder?.calculatedRates.totalCharge}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
          {/* Include additional invoice details */}
        </Container>
      ) : (
        <Container>
          <Row>
            <Card elevation={Elevation.TWO}>
              <Col className="order-card-styling">
                <h1>Invoice Not Available</h1>
                <p>The invoice is not available for this order.</p>
              </Col>
            </Card>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ViewInvoices;
