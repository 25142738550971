export const userData = (userCount, adminCount, totalUsers, theme) => {
  const chartTheme = theme === "dark" ? "dark" : "light";

  return {
    options: {
      theme: {
        mode: chartTheme,
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      colors: ["#0F6894", "#D1980B", "#238C2C"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },

      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Users"],
      },
    },
    series: [
      {
        name: "Number of Users",
        data: [userCount],
      },
      {
        name: "Number of Admins",
        data: [adminCount],
      },
      {
        name: "Total Users",
        data: [totalUsers],
      },
    ],
  };
};

export const receivablesData = (totals, billableTotal, unpaidTotal, theme) => {
  const chartTheme = theme === "dark" ? "dark" : "light";

  return {
    options: {
      theme: {
        mode: chartTheme,
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            size: "55%",
            labels: {
              show: true,
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      labels: ["Unpaid Invoices", "Billable Orders"],
      colors: ["#0F6894", "#D1980B", "#238C2C"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
    },
    noData: {
      text: "No data to show",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000",
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
    },
    series: [
      parseFloat(unpaidTotal.toFixed(2)),
      parseFloat(billableTotal.toFixed(2)),
    ],

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};
