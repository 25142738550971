import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { Container } from "react-bootstrap";
import {
  Button,
  Dialog,
  Classes,
  FormGroup,
  InputGroup,
  HTMLSelect,
} from "@blueprintjs/core";
import SearchFilterComponent from "./SearchFilterComponent";
import FetchOrdersComponent from "./FetchOrdersComponent";
import OrdersListComponent from "./OrdersListComponent";
import { useNavigate } from "react-router-dom";
import { getActiveDrivers } from "../javascript/api";
import Signature from "./Signature";
import ViewOrderDetails from "./ViewOrderDetails";
import EditOrderDetails from "./managerView/EditOrderDetails";
import { useTheme } from "../context/ThemeContext";
import Pagination10 from "./Pagination10";

const GetOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [editedOrder, setEditedOrder] = useState(null);
  const [captureSignatureModal, setCaptureSignatureModal] = useState(false);
  const [selectedOrderForSignature, setSelectedOrderForSignature] =
    useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { isDarkMode } = useTheme();
  const [ordersPerPage, setOrdersPerPage] = useState(5); // Change as needed

  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveDriversAndSetupListener = async () => {
      try {
        await fetchActiveDriversData();

        const unsubscribeActiveDrivers = onSnapshot(
          collection(firestore, "activeDrivers"),
          (snapshot) => {
            const updatedActiveDrivers = snapshot.docs.map(
              (doc) => doc.data().name
            );

            setActiveDrivers(updatedActiveDrivers);
          }
        );

        return unsubscribeActiveDrivers;
      } catch (error) {
        console.error("Error fetching active drivers:", error);
      }
    };

    const unsubscribe = onSnapshot(
      collection(firestore, "orders"),
      (snapshot) => {
        const updatedOrders = snapshot.docs.map((doc) => {
          const orderData = {
            id: doc.id,
            selectedCustomer: doc.data().selectedCustomer,
            selectedForwarder: doc.data().selectedForwarder,
            pieces: doc.data().pieces,
            calculatedRates: doc.data().calculatedRates,
            ...doc.data(),
          };

          // Access customer data from the selectedCustomer field
          const { selectedCustomer } = orderData;
          if (selectedCustomer) {
            // Extract the needed customer details
            const {
              customerCompanyName,
              customerFirstName,
              customerLastName,
              // Add other customer fields as needed
            } = selectedCustomer;

            // Add the extracted customer details to the order data
            orderData.customerCompanyName = customerCompanyName;
            orderData.customerFirstName = customerFirstName;
            orderData.customerLastName = customerLastName;
            // Add other customer fields to the order data
          }

          const { selectedForwarder } = orderData;
          if (selectedForwarder) {
            // Extract the needed customer details
            const {
              forwarderCompanyName,
              forwarderFirstName,
              forwarderLastName,
              // Add other customer fields as needed
            } = selectedForwarder;

            // Add the extracted customer details to the order data
            orderData.forwarderCompanyName = forwarderCompanyName;
            orderData.forwarderFirstName = forwarderFirstName;
            orderData.forwarderLastName = forwarderLastName;
            // Add other customer fields to the order data
          }

          const { calculatedRates } = orderData;
          if (calculatedRates) {
            // Extract the needed customer details
            const {
              deliveryFee,
              totalCharge,
              fees,
              // Add other customer fields as needed
            } = calculatedRates;

            // Add the extracted customer details to the order data
            orderData.deliveryFee = deliveryFee;
            orderData.totalCharge = totalCharge;
            orderData.fees = fees;
            // Add other customer fields to the order data
          }

          return orderData;
        });

        setOrders(updatedOrders);
        handleFilterOrders(searchTerm, filterStatus);
      }
    );

    const unsubscribeActiveDrivers = onSnapshot(
      collection(firestore, "activeDrivers"),
      (snapshot) => {
        const updatedActiveDrivers = snapshot.docs.map(
          (doc) => doc.data().name
        );

        setActiveDrivers(updatedActiveDrivers);
      }
    );

    return () => {
      unsubscribe(); // Unsubscribe from real-time updates for orders when the component unmounts
      unsubscribeActiveDrivers(); // Unsubscribe from real-time updates for active drivers when the component unmounts
    };
  }, [currentPage, searchTerm, filterStatus]);

  const fetchActiveDriversData = async () => {
    try {
      const activeDriversList = await getActiveDrivers();

      setActiveDrivers(activeDriversList);
    } catch (error) {
      console.error("Error fetching active drivers:", error);
    }
  };

  const handleViewDetails = async (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleEditOrder = async (order) => {
    try {
      // Fetch active drivers data before setting editedOrder
      await fetchActiveDriversData();

      setEditedOrder({ ...order });

      setShowModal(true);

      setSelectedOrder(order);
      navigate(`/edit/${order.id}`);
    } catch (error) {
      console.error("Error fetching active drivers:", error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await deleteDoc(doc(firestore, "orders", orderId));
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.id !== orderId)
      );
      setFilteredOrders((prevFilteredOrders) =>
        prevFilteredOrders.filter((order) => order.id !== orderId)
      );
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      await updateDoc(doc(firestore, "orders", editedOrder.id), editedOrder);

      // Update orders and filteredOrders with the edited order
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === editedOrder.id ? editedOrder : order
        )
      );
      setFilteredOrders((prevFilteredOrders) =>
        prevFilteredOrders.map((order) =>
          order.id === editedOrder.id ? editedOrder : order
        )
      );

      setShowModal(false);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleCaptureSignature = (order) => {
    setSelectedOrderForSignature(order);
    setSelectedOrder(order);
    setCaptureSignatureModal(true);
  };

  const handleSaveSignature = async (signatureData) => {
    try {
      if (!selectedOrder || !selectedOrder.id) {
        console.error("Error: Invalid or missing order data");
        return;
      }

      const orderRef = doc(firestore, "orders", selectedOrder.id);
      await updateDoc(orderRef, { signature: signatureData });

      // Update the selectedOrder with the new signature data
      const updatedOrder = {
        ...selectedOrder,
        signature: signatureData,
      };

      // Update orders and filteredOrders with the updated order
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === selectedOrder.id ? updatedOrder : order
        )
      );
      setFilteredOrders((prevFilteredOrders) =>
        prevFilteredOrders.map((order) =>
          order.id === selectedOrder.id ? updatedOrder : order
        )
      );

      // Update the selectedOrderForSignature if it matches the selectedOrder
      setSelectedOrderForSignature(updatedOrder);
    } catch (error) {
      console.error("Error saving signature:", error);
    } finally {
      setCaptureSignatureModal(false);
    }
  };

  const handleAttemptDelivery = async (driverName) => {
    try {
      if (!selectedOrder || !selectedOrder.id) {
        throw new Error("Invalid or missing order data");
      }

      const orderRef = doc(firestore, "orders", selectedOrder.id);

      // Check if deliveryAttempts is defined and is an array, otherwise initialize it
      const currentDeliveryAttempts = Array.isArray(
        selectedOrder.deliveryAttempts
      )
        ? selectedOrder.deliveryAttempts
        : [];

      const deliveryAttemptData = {
        timestamp: new Date().toLocaleString(),
        driver: driverName,
      };

      const updatedDeliveryAttempts = [
        ...currentDeliveryAttempts,
        deliveryAttemptData,
      ];

      // Update the order document with the delivery attempt data
      await updateDoc(orderRef, {
        deliveryAttempts: updatedDeliveryAttempts,
      });

      // Update the local state with the updated delivery attempts
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === selectedOrder.id
            ? { ...order, deliveryAttempts: updatedDeliveryAttempts }
            : order
        )
      );
      setFilteredOrders((prevFilteredOrders) =>
        prevFilteredOrders.map((order) =>
          order.id === selectedOrder.id
            ? { ...order, deliveryAttempts: updatedDeliveryAttempts }
            : order
        )
      );
    } catch (error) {
      console.error("Error recording delivery attempt:", error);
      setError(error.message);
    }
  };

  const handleCancelChanges = () => {
    setShowModal(false);
    setEditedOrder(null);
  };

  // Handle filters

  const handleFilterOrders = (searchTerm, filterStatus) => {
    let filtered = [...orders];

    // Filter by search term
    if (searchTerm) {
      const searchRegex = new RegExp(searchTerm, "i");
      filtered = filtered.filter((order) =>
        searchRegex.test(order.orderNumber.toLowerCase())
      );
    }

    // Filter by status
    if (Array.isArray(filterStatus) && filterStatus.length > 0) {
      filtered = filtered.filter((order) =>
        filterStatus.includes(order.status)
      );
    }

    // If both search term and statuses are empty, reset to an empty array
    if (!searchTerm && (!filterStatus || filterStatus.length === 0)) {
      filtered = [];
    }

    setFilteredOrders(filtered);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  // Conditionally render results count
  const resultCount =
    filteredOrders.length === 0
      ? "0"
      : `${Math.min(
          (currentPage - 1) * ordersPerPage + 1,
          filteredOrders.length
        )}-${Math.min(currentPage * ordersPerPage, filteredOrders.length)} of ${
          filteredOrders.length
        }`;

  return (
    <Container className="pb-3" style={{ minHeight: "100vh" }}>
      <h2 className="pt-3">Search Orders</h2>
      <p>Showing results {resultCount}</p>
      <SearchFilterComponent
        onSearchChange={(searchTerm) => {
          setSearchTerm(searchTerm);
          handleFilterOrders(searchTerm, filterStatus);
        }}
        onFilterChange={(status) => {
          setFilterStatus(status);
          handleFilterOrders(searchTerm, status);
        }}
      />
      <FetchOrdersComponent
        setOrders={setOrders}
        handleFilterOrders={() => handleFilterOrders()}
      />
      <OrdersListComponent
        filteredOrders={filteredOrders.slice(
          (currentPage - 1) * ordersPerPage,
          currentPage * ordersPerPage
        )}
        handleViewDetails={handleViewDetails}
        handleEditOrder={handleEditOrder}
        handleDeleteOrder={handleDeleteOrder}
        handleCaptureSignature={(order) => {
          handleCaptureSignature(order);
          setSelectedOrder(order);
        }}
        handleAttemptDelivery={handleAttemptDelivery}
      />

      {selectedOrderForSignature && captureSignatureModal && (
        <Signature
          onSave={handleSaveSignature}
          onCancel={() => setCaptureSignatureModal(false)}
        />
      )}
      {/* Pagination controls */}
      {filteredOrders.length > 0 && ( // Only render pagination if there are orders to display
        <Pagination10
          currentPage={currentPage}
          ordersPerPage={ordersPerPage}
          totalOrders={filteredOrders.length} // Use filteredOrders length for pagination
          paginate={paginate}
        />
      )}
      <Dialog
        isOpen={showModal}
        onClose={handleCancelChanges}
        title={editedOrder ? "Edit Order" : "Quick View"}
        icon={editedOrder ? "edit" : "eye-open"}
        className={
          isDarkMode
            ? "bp5-dark w-100 no-button-display"
            : "w-100 no-button-display"
        }
        canOutsideClickClose={true}
        canEscapeKeyClose={true}
      >
        <div className={Classes.DIALOG_BODY}>
          {selectedOrder && !editedOrder ? (
            <ViewOrderDetails
              order={selectedOrder}
              handleCaptureSignature={handleCaptureSignature}
            />
          ) : (
            editedOrder && (
              <EditOrderDetails
                editedOrder={editedOrder}
                activeDrivers={activeDrivers}
                setEditedOrder={setEditedOrder}
                handleSaveChanges={handleSaveChanges}
                handleCancelChanges={handleCancelChanges}
              />
            )
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {editedOrder && (
              <>
                <Button
                  intent="danger"
                  minimal={true}
                  onClick={handleCancelChanges}
                >
                  Cancel
                </Button>
                <Button
                  intent="success"
                  minimal={true}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </Container>
  );
};

export default GetOrders;
