import React, { useState } from "react";
import BugReportForm from "../components/BugReportForm";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import { ListGroup, Row, Col, Container } from "react-bootstrap";
import {
  Button,
  Icon,
  Divider,
  Card,
  Section,
  SectionCard,
  Elevation,
} from "@blueprintjs/core";
import Badge from "react-bootstrap/Badge";
import { useAuth } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase";

const About = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  // Check if user has the role property before accessing it
  const role = user && user.role;
  /*

  const [showBugReportModal, setShowBugReportModal] = useState(false);

  const handleShowBugReportModal = () => {
    setShowBugReportModal(true);
  };

  const handleCloseBugReportModal = () => {
    setShowBugReportModal(false);
  };

  const handleSubmit = async (bugReportData) => {
    try {
      // Add bug report data to the "Issues" collection
      await addDoc(collection(firestore, "Issues"), {
        description: bugReportData.description,
        stepsToReproduce: bugReportData.stepsToReproduce,
        timestamp: new Date(), // Adding a timestamp for when the bug report was submitted
      });

      // Close the bug report modal
      handleCloseBugReportModal();
    } catch (error) {
      console.error("Error submitting bug report:", error);
      // Handle error (e.g., display error message to the user)
    }
  };
  */

  // Render Navigation component for Manager or Admin, NavigationDriver for Driver
  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {role === "Manager" || role === "Admin" ? (
        <Navigation />
      ) : role === "Driver" ? (
        <NavigationDriver />
      ) : null}
      <Container
        className={isDarkMode ? "pb-3 bp5-dark " : "pb-3"}
        style={{ minHeight: "100vh" }}
      >
        <div className="pt-3">
          <h2>App Info</h2>
        </div>
        {/* 
        <div className="d-flex justify-content-end">
          <Button
            intent="primary"
            onClick={handleShowBugReportModal}
            rightIcon="bug"
          >
            Report Bug
          </Button>
          <BugReportForm
            show={showBugReportModal}
            onHide={handleCloseBugReportModal}
            onSubmit={handleSubmit}
          />
        </div>
*/}

        <Section
          title="Current App Version: 3.0.0"
          subtitle="Release Date: 05/20/2024"
          className="order-card-styling mb-2"
          rightElement={
            <Badge bg="warning" text="dark" pill>
              *NEW*
            </Badge>
          }
        >
          <SectionCard className="mb-2">
            <div className="mb-3 text-center">
              <strong>
                <Icon icon="star" color="#FFC107" /> NEW FEATURES{" "}
                <Icon icon="star" color="#FFC107" />
              </strong>
            </div>
            <Card>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <strong>Feature:</strong>
                </Col>

                <Col md={9}>
                  <p>
                    Added delete order functionality for orders in search orders
                    (management/admin view only).
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={3}></Col>

                <Col md={9}>
                  <p>Added dark mode.</p>
                </Col>
              </Row>
              <Row>
                <Col md={3}></Col>

                <Col md={9}>
                  <p>Improved user interface and navbar.</p>
                </Col>
              </Row>
            </Card>
          </SectionCard>
        </Section>

        <Section
          title="Resolved Bugs"
          className="order-card-styling mb-2"
          rightElement={
            <Badge bg="success" pill>
              Resolved
            </Badge>
          }
        >
          <SectionCard className="mb-2">
            <div className="mb-3 text-center">
              <strong>
                <Icon icon="confirm" intent="success" /> RESOLVED BUGS in latest
                version(s)
              </strong>
            </div>

            <Card>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <strong>Bug Description:</strong>
                </Col>
                <Col md={9}>
                  <p>
                    Fixed issue of automatic signin of a newly created user.
                  </p>
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col md={3}></Col>
                <Col md={9}>
                  <p>Fixed delivery date bug on deliveries page.</p>
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col md={3}></Col>
                <Col md={9}>
                  <p>
                    Fixed routing for actions from dashboard buttons in
                    management view.
                  </p>
                </Col>
              </Row>
            </Card>
          </SectionCard>
        </Section>

        <Section
          title="Known Bugs"
          className="order-card-styling mb-2"
          rightElement={
            <Badge bg="danger" text="light" pill>
              Bugs!
            </Badge>
          }
        >
          <SectionCard className="mb-2">
            <div className="mb-3 text-center">
              <strong>
                <Icon icon="bug" intent="danger" /> KNOWN BUGS{" "}
              </strong>
            </div>
            <Card>
              <Row className="d-flex align-items-center">
                <Col md={2}>
                  <strong>Bug Description:</strong>
                </Col>
                <Col md={5}>
                  <p>
                    Can't currently edit pieces and rates due to pricing not
                    updating properly.
                  </p>
                </Col>

                <Col md={5}>
                  <p>
                    If you need to edit an orders pieces or rates, create a new
                    order.
                  </p>
                </Col>
              </Row>
            </Card>
          </SectionCard>
        </Section>

        <Section
          title="Upcoming Features"
          className="order-card-styling mb-2"
          rightElement={
            <Badge bg="info" pill>
              Upcoming
            </Badge>
          }
        >
          <SectionCard className="mb-2">
            <div className="mb-3 text-center">
              <strong>
                <Icon icon="time" intent="primary" /> UPCOMING FEATURES{" "}
              </strong>
            </div>
            <Card>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <strong>Description:</strong>
                </Col>
                <Col md={9}>
                  <p>Vendor login</p>
                </Col>
              </Row>
            </Card>
          </SectionCard>
        </Section>
      </Container>
    </div>
  );
};

export default About;
