import React from "react";
import NavigationDriver from "../components/driverView/NavigationDriver";
import Home from "../components/driverView/Home";
import ManagerDashboard from "../components/managerView/ManagerDashboard";
import Navigation from "../components/managerView/Navigation";
import { useAuth } from "../context/AuthContext";
import { Container } from "react-bootstrap";
import { useTheme } from "../context/ThemeContext";

function HomePage() {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  // Check if user and user.role are defined before accessing user.role
  const role = user && user.role;

  // Render Navigation component for Manager or Admin, NavigationDriver for Driver
  return (
    <>
      {role === "Manager" || role === "Admin" ? (
        <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
          <Navigation />
          <ManagerDashboard />
        </div>
      ) : role === "Driver" ? (
        <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
          <NavigationDriver />
          <Home />
        </div>
      ) : null}
    </>
  );
}

export default HomePage;
