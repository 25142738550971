import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { Icon } from "@blueprintjs/core";

const SuccessToast = ({ showToast, setShowToast }) => {
  return (
    <>
      <ToastContainer className="pt-3" position="top-center">
        {showToast && (
          <Toast
            onClose={() => setShowToast(false)}
            delay={6000}
            autohide
            bg="success"
          >
            <Toast.Header closeButton={false}>
              <Icon
                icon="cloud-upload"
                intent="primary"
                style={{ marginRight: "3px" }}
              />
              <strong className="me-auto" style={{ color: "#000" }}>
                Success!
              </strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body style={{ color: "#fff" }}>
              Saved successfully!
            </Toast.Body>
          </Toast>
        )}
      </ToastContainer>
    </>
  );
};

export default SuccessToast;
