import React from "react";
import GetUsers from "./managerView/GetUsers";
import GetAdmins from "./managerView/GetAdmins";

const Users = () => {
  return (
    <div className="container p-3">
      <h5 className="p-3">Current Users</h5>
      <GetUsers />
      <h5 className="p-3">Web Masters</h5>
      <GetAdmins />
    </div>
  );
};

export default Users;
