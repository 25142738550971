import React from "react";
import { Icon } from "@blueprintjs/core";
import MyOrders from "../components/driverView/MyOrders";
import { useAuth } from "../context/AuthContext";
import InvoicedOrders from "../components/managerView/InvoicedOrders";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import { useTheme } from "../context/ThemeContext";

const ArchivedOrders = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  // Check if user has the role property before accessing it
  const role = user && user.role;

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {role === "Manager" || role === "Admin" ? ( // Check user role
        <>
          <Navigation />
          <InvoicedOrders />
        </>
      ) : (
        <>
          <div>
            <NavigationDriver />
            <MyOrders user={user} />

            {/* Render other Driver components here */}
          </div>
        </>
      )}
    </div>
  );
};

export default ArchivedOrders;
