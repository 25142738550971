import React, { useState, useEffect, useRef } from "react";
import { getLoggedInUserDeliveredOrders } from "../../javascript/api";
import { ListGroup, Badge, Row, Col, Container } from "react-bootstrap";
import {
  Card,
  Section,
  SectionCard,
  Elevation,
  Icon,
  Button,
  Spinner,
  Divider,
} from "@blueprintjs/core";
import { useAuth } from "../../context/AuthContext";
import formatDate from "../../utility/formatDate";
import Pagination10 from "../Pagination10";
import { Link, useNavigate } from "react-router-dom";

const MyOrders = () => {
  const { user } = useAuth();
  const [myPastOrders, setMyPastOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(3);
  const containerRef = useRef(null); // Ref for the container element
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyPastOrders = async () => {
      try {
        const orders = await getLoggedInUserDeliveredOrders(user);
        setMyPastOrders(orders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logged-in user's orders:", error);
        setLoading(false);
      }
    };

    fetchMyPastOrders();
  }, [user]);

  // Calculate the indexes of the orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = myPastOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const resultCount =
    myPastOrders.length > 0
      ? `${Math.min(indexOfFirstOrder + 1, myPastOrders.length)}-${Math.min(
          indexOfLastOrder,
          myPastOrders.length
        )} of ${myPastOrders.length}`
      : "";

  return (
    <Container
      className="pb-3"
      style={{ minHeight: "100vh" }}
      ref={containerRef}
    >
      <div className=" mt-3 text-center">
        <h2>
          Past Delieveries <Icon icon="history" intent="primary" size={30} />
        </h2>
      </div>
      {resultCount && (
        <div>
          <p>Showing results {resultCount}</p>
          {myPastOrders.length > ordersPerPage && (
            <Pagination10
              currentPage={currentPage}
              ordersPerPage={ordersPerPage}
              totalOrders={myPastOrders.length}
              paginate={paginate}
            />
          )}
        </div>
      )}
      {loading ? (
        <Card elevation={Elevation.TWO}>
          <div className="text-center">
            <p>
              Loading... <Spinner intent="primary" />
            </p>
          </div>
        </Card>
      ) : currentOrders.length === 0 ? (
        <Card elevation={Elevation.TWO}>
          <div className="text-center">
            <p>
              You have no <Icon icon="history" intent="primary" /> past
              deliveries.
            </p>
          </div>
        </Card>
      ) : (
        <>
          {currentOrders.map((order) => (
            <Section
              key={order.id}
              title={`Order: ${order.orderNumber}`}
              compact={true}
              className="mb-2"
              collapsible={true}
              rightElement={
                <Button
                  minimal={true}
                  intent="primary"
                  onClick={() => navigate(`/view_order/${order.id}`)}
                  text="View Order"
                />
              }
            >
              <SectionCard elevation={Elevation.TWO}>
                <Card className="order-card-styling">
                  <Row>
                    <Col md={4}>
                      <p>
                        <strong>Order Number: </strong>
                        {order.orderNumber}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <strong>Delivered On:</strong>{" "}
                      {formatDate(order.deliveryDate)}{" "}
                      <Icon icon="archive" intent="primary" />
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Link to={`/view_order/${order.id}`}>
                        <Button
                          rightIcon="more"
                          intent="primary"
                          text="Info"
                          minimal={true}
                        />
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </SectionCard>
            </Section>
          ))}
        </>
      )}
    </Container>
  );
};

export default MyOrders;
