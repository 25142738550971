import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

const SearchFilterComponent = ({ onSearchChange, onFilterChange }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialSearchTerm = queryParams.get("search") || "";
  const initialStatuses = queryParams.get("statuses")
    ? queryParams.get("statuses").split(",")
    : [];

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [selectedStatuses, setSelectedStatuses] = useState(initialStatuses);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown visibility
  const [isDropdownActive, setIsDropdownActive] = useState(false); // State to control dropdown activation

  const statusOptions = ["Active", "Delivered", "Invoiced", "Closed"];

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set("search", searchTerm);
    if (selectedStatuses && selectedStatuses.length > 0)
      params.set("statuses", selectedStatuses.join(","));
    else params.delete("statuses"); // Remove statuses param if not selected
    navigate(`?${params.toString()}`);
  }, [searchTerm, selectedStatuses, navigate]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearchChange(value !== "" ? value : null); // Pass null if search term is empty
  };

  const handleStatusChange = (status) => {
    const updatedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter((s) => s !== status)
      : [...selectedStatuses, status];
    setSelectedStatuses(updatedStatuses);
    onFilterChange(updatedStatuses);
    setIsDropdownActive(false); // Deactivate dropdown after selecting/deselecting
  };

  const handleSelectAll = () => {
    setSelectedStatuses(statusOptions);
    onFilterChange(statusOptions);
    setIsDropdownActive(false); // Deactivate dropdown after selecting all
  };

  const handleDeselectAll = () => {
    setSearchTerm("");
    setSelectedStatuses([]);
    onFilterChange([]);
    setIsDropdownActive(false); // Deactivate dropdown after deselecting all
  };

  const renderStatusOption = (status) => (
    <Button
      key={status}
      text={status}
      onClick={() => handleStatusChange(status)}
      intent={selectedStatuses.includes(status) ? "success" : "default"}
      style={{ margin: "2px" }}
    />
  );

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <div className="d-flex flex-wrap align-items-center">
            <div
              style={{ marginBottom: "10px", width: "100%", maxWidth: "400px" }}
            >
              <InputGroup
                placeholder="Search by order number"
                value={searchTerm}
                onChange={handleSearchChange}
                asyncControl={true}
                rightElement={<Button icon="search" minimal />}
                style={{ width: "100%" }} // Full width by default
              />
            </div>
            <div style={{ minWidth: "150px", marginBottom: "10px" }}>
              <Select
                items={statusOptions}
                itemRenderer={renderStatusOption}
                onItemSelect={handleStatusChange}
                popoverProps={{
                  isOpen: isDropdownOpen,
                  autoFocus: isDropdownActive,
                }} // Pass isOpen prop to control visibility
                tagInputProps={{ fill: true, onRemove: handleStatusChange }}
                selectedItems={selectedStatuses}
                icon="checkbox"
                placeholder="Filter by Status"
                style={{ minWidth: "150px" }}
              >
                <div>
                  <Button
                    icon="filter"
                    text={
                      selectedStatuses.length > 0
                        ? `Selected: ${selectedStatuses.join(", ")}`
                        : "Filter by Status"
                    }
                    rightIcon="caret-down"
                    intent="primary"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown visibility
                  />

                  <Button
                    intent="primary"
                    text="Select All"
                    onClick={handleSelectAll}
                    onMouseDown={() => setIsDropdownActive(false)} // Deactivate dropdown on mouse down
                  />
                  <Button
                    intent="primary"
                    text="Deselect All"
                    onClick={handleDeselectAll}
                    onMouseDown={() => setIsDropdownActive(false)} // Deactivate dropdown on mouse down
                  />
                </div>
              </Select>
            </div>
          </div>
        </FormGroup>
      </form>
    </div>
  );
};

export default SearchFilterComponent;
