import React from "react";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import GetDeliveries from "../components/GetDeliveries";
import TodaysOrders from "../components/driverView/TodaysOrders";
import { useAuth } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext";

const Deliveries = () => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  // Check if user has the role property before accessing it
  const role = user && user.role;

  return (
    <div>
      {/* Render based on user role */}
      {role === "Manager" || role === "Admin" ? (
        <>
          <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
            <Navigation />
            <GetDeliveries />
          </div>
        </>
      ) : (
        <>
          <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
            <NavigationDriver />
            <TodaysOrders />
          </div>
        </>
      )}
    </div>
  );
};

export default Deliveries;
