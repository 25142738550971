import React, { useState, useEffect } from "react";
import { Card, Elevation, Icon, Spinner } from "@blueprintjs/core";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import ViewInvoices from "../components/managerView/ViewInvoices";
import { useParams } from "react-router-dom"; // Import useParams to access URL parameters
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext";

const ViewInvoicePage = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { orderId } = useParams(); // Access orderId from URL parameters
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  // Check if user has the role property before accessing it
  const role = user && user.role;

  useEffect(() => {
    const fetchSelectedOrder = async () => {
      try {
        const orderDoc = await getDoc(doc(firestore, "orders", orderId)); // Fetch order using orderId
        if (orderDoc.exists()) {
          const orderData = orderDoc.data();
          setSelectedOrder(orderData); // Set selectedOrder to the fetched order
        } else {
          console.error("Order not found");
        }
      } catch (error) {
        console.error("Error fetching selected order:", error);
      }
    };

    if (orderId) {
      fetchSelectedOrder();
    }
  }, [orderId]);

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {/* Render based on user role */}
      {role === "Manager" || role === "Admin" ? (
        <>
          <Navigation />
          <div>
            {selectedOrder ? (
              <ViewInvoices selectedOrder={selectedOrder} />
            ) : (
              <p>
                Loading... <Spinner intent="primary" />
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <NavigationDriver />

          <div className="text-center" style={{ color: "red" }}>
            <h1>
              Access <Icon icon="remove" size={30} intent="primary" /> Denied
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewInvoicePage;
