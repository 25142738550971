import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Form as BootstrapForm, Alert } from "react-bootstrap";
import {
  Button,
  Collapse,
  Card,
  Section,
  SectionCard,
  Elevation,
  Toast2,
} from "@blueprintjs/core";
import axios from "axios";

const CreateAccountSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  role: Yup.string().required("Role is required"),
  status: Yup.string().required("Status is required"),
});

const CreateAccount = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const clearSuccessMessage = () => {
    setSuccessMessage("");
  };

  const addUser = async (userData, { resetForm }) => {
    try {
      setErrorMessage(""); // Reset any previous error messages
      setSuccessMessage(""); // Reset any previous success messages
      setLoading(true);

      // Include displayName in userData if not provided
      if (!userData.displayName) {
        userData.displayName = `${userData.firstName} ${userData.lastName}`;
      }

      const response = await axios.post(
        "https://us-central1-quickdelivery-69d68.cloudfunctions.net/createUser",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage(response.data.message);

      // Reset form values to initial state
      resetForm();
      // Clear success message after 6 seconds
      setTimeout(clearSuccessMessage, 6000);
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
        setErrorMessage(error.response.data.error || "Error creating user");
      } else if (error.request) {
        console.error("Error request:", error.request);
        setErrorMessage("No response from server");
      } else {
        console.error("Error message:", error.message);
        setErrorMessage("Error creating user");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <Section
          title="Manage Users"
          collapsible={true}
          collapseProps={{ defaultIsOpen: false }}
          className="mb-2"
          rightElement={
            <Button minimal={true} intent="primary" onClick={handleClick}>
              {isOpen ? "Close Form" : "Create New User"}
            </Button>
          }
        >
          <SectionCard>
            <Formik
              initialValues={{
                email: "",
                displayName: "",
                firstName: "",
                lastName: "",
                password: "",
                confirmPassword: "",
                profilePhotoUrl: "",
                role: "",
                status: "",
                disabled: false,
                assignedOrders: [],
              }}
              validationSchema={CreateAccountSchema}
              onSubmit={addUser}
            >
              {({ values, handleChange }) => (
                <Form className="pt-2 w-auto">
                  <Row className="mb-3">
                    <Col md={4}>
                      <BootstrapForm.Label>First Name</BootstrapForm.Label>
                      <Field
                        type="text"
                        name="firstName"
                        as={BootstrapForm.Control}
                        placeholder="Enter first name"
                      />
                      <strong>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>

                    <Col md={4}>
                      <BootstrapForm.Label>Last Name</BootstrapForm.Label>
                      <Field
                        type="text"
                        name="lastName"
                        as={BootstrapForm.Control}
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Col>
                    <Col md={4}>
                      <BootstrapForm.Label>Email Address</BootstrapForm.Label>
                      <Field
                        type="email"
                        name="email"
                        as={BootstrapForm.Control}
                        placeholder="Enter Email Address"
                      />
                      <strong>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={4}>
                      <BootstrapForm.Label>Create Password</BootstrapForm.Label>
                      <Field
                        type="password"
                        name="password"
                        as={BootstrapForm.Control}
                        placeholder="Enter a password"
                      />
                      <strong>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>
                    <Col md={4}>
                      <BootstrapForm.Label>
                        Re-Enter Password
                      </BootstrapForm.Label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        as={BootstrapForm.Control}
                        placeholder="confirm password"
                      />
                      <strong>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>

                    <Col md={4}>
                      <BootstrapForm.Label>
                        Select User Role
                      </BootstrapForm.Label>
                      <BootstrapForm.Select
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose role
                        </option>
                        <option value="Manager">Manager</option>
                        <option value="Driver">Driver</option>
                        <option value="Admin">Web Master</option>
                      </BootstrapForm.Select>
                      <strong>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={4}>
                      <BootstrapForm.Label>User Status</BootstrapForm.Label>
                      <BootstrapForm.Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Choose user status
                        </option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </BootstrapForm.Select>
                      <strong>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </strong>
                    </Col>
                    <Col md={4}></Col>
                  </Row>

                  {/* Add other form fields similarly */}

                  <Button
                    minimal={true}
                    type="submit"
                    intent="primary"
                    icon="new-person"
                    disabled={loading}
                  >
                    Save User
                  </Button>
                </Form>
              )}
            </Formik>
          </SectionCard>
        </Section>
      </div>
    </div>
  );
};

export default CreateAccount;
