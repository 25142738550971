// ForwarderDetailsForm.jsx
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Row, Col, Modal } from "react-bootstrap";
import { Button, Card, Icon, Elevation } from "@blueprintjs/core";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import SuccessToast from "../SuccessToast";

const ForwarderDetails = ({ isOpen, onClose }) => {
  const initialValues = {
    forwarderCompanyName: "",
    forwarderFirstName: "",
    forwarderLastName: "",
    forwarderPrimaryPhone: "",
    forwarderSecondaryPhone: "",
    forwarderAddress: "",
    forwarderAddressCity: "",
    forwarderAddressState: "",
    forwarderAddressZip: "",
    status: "Active",
  };

  const validationSchema = Yup.object().shape({
    forwarderCompanyName: Yup.string().required("Company is required"),
    forwarderFirstName: Yup.string().required(
      "Forwarder first name is required"
    ),
    forwarderLastName: Yup.string().required("Forwarder last name is required"),
    forwarderAddress: Yup.string().required("Forwarder address is required"),
    forwarderAddressCity: Yup.string().required("City is required"),
    forwarderAddressState: Yup.string().required("State is required"),
    forwarderAddressZip: Yup.string().required("Zipcode is required"),
    forwarderPrimaryPhone: Yup.string().required("Primary number is required"),
    forwarderSecondaryPhone: Yup.string(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || "",
    }));
  };

  const [showToast, setShowToast] = useState(false);
  const [formData, setFormData] = useState(initialValues);

  const addForwarder = async (values, { resetForm }) => {
    try {
      setShowToast(true); // Show toast on successful submission
      setTimeout(() => {
        setShowToast(false);
      }, 5000);

      const docRef = await addDoc(collection(firestore, "forwarders"), {
        forwarderCompanyName: values.forwarderCompanyName,
        forwarderFirstName: values.forwarderFirstName,
        forwarderLastName: values.forwarderLastName,
        forwarderPrimaryPhone: values.forwarderPrimaryPhone,
        forwarderSecondaryPhone: values.forwarderSecondaryPhone,
        forwarderAddress: values.forwarderAddress,
        forwarderAddressCity: values.forwarderAddressCity,
        forwarderAddressState: values.forwarderAddressState,
        forwarderAddressZip: values.forwarderAddressZip,
        status: values.status,
      });

      // Reset form values to initial state
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error submitting forwarder details:", error);
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Forwarder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SuccessToast showToast={showToast} setShowToast={setShowToast} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            addForwarder(values, { resetForm });
          }}
        >
          {({ values, handleChange }) => (
            <Form className="mb-3">
              <Row>
                <BootstrapForm.Group
                  as={Col}
                  md="12"
                  controlId="forwarderCompanyName"
                >
                  <BootstrapForm.Label>
                    Forwarder Company Name
                  </BootstrapForm.Label>
                  <Field
                    type="text"
                    name="forwarderCompanyName"
                    value={values.forwarderCompanyName}
                    as={BootstrapForm.Control}
                  />
                  <ErrorMessage name="forwarderFirstName" component="div" />
                </BootstrapForm.Group>

                <BootstrapForm.Group
                  as={Col}
                  md="6"
                  controlId="forwarderPrimaryPhone"
                  className="mt-2"
                >
                  <BootstrapForm.Label>Primary Phone</BootstrapForm.Label>
                  <Field
                    type="text"
                    name="forwarderPrimaryPhone"
                    value={values.forwarderPrimaryPhone}
                    as={BootstrapForm.Control}
                  />
                  <ErrorMessage name="forwarderPrimaryPhone" component="div" />
                </BootstrapForm.Group>

                <BootstrapForm.Group
                  as={Col}
                  md="6"
                  controlId="forwarderSecondaryPhone"
                  className="mt-2"
                >
                  <BootstrapForm.Label>Secondary Phone</BootstrapForm.Label>
                  <Field
                    type="text"
                    name="forwarderSecondaryPhone"
                    value={values.forwarderSecondaryPhone}
                    as={BootstrapForm.Control}
                  />
                  <ErrorMessage
                    name="forwarderSecondaryPhone"
                    component="div"
                  />
                </BootstrapForm.Group>
              </Row>
              <Row className="mb-3">
                <BootstrapForm.Group
                  as={Col}
                  md="6"
                  controlId="forwarderFirstName"
                  className="mt-2"
                >
                  <BootstrapForm.Label>
                    Forwarder First Name
                  </BootstrapForm.Label>
                  <Field
                    type="text"
                    name="forwarderFirstName"
                    value={values.forwarderFirstName}
                    as={BootstrapForm.Control}
                  />
                  <ErrorMessage name="forwarderFirstName" component="div" />
                </BootstrapForm.Group>

                <BootstrapForm.Group
                  as={Col}
                  md="6"
                  controlId="forwarderLastName"
                  className="mt-2"
                >
                  <BootstrapForm.Label>Forwarder Last Name</BootstrapForm.Label>
                  <Field
                    type="text"
                    name="forwarderLastName"
                    value={values.forwarderLastName}
                    as={BootstrapForm.Control}
                  />
                  <ErrorMessage name="forwarderLastName" component="div" />
                </BootstrapForm.Group>
              </Row>

              <Row className="mb-3">
                <BootstrapForm.Group controlId="forwarderAddress">
                  <BootstrapForm.Label>Address</BootstrapForm.Label>
                  <Field
                    type="text"
                    value={values.forwarderAddress}
                    as={BootstrapForm.Control}
                    required
                  />
                  <ErrorMessage name="forwarderAddress" component="div" />
                </BootstrapForm.Group>
                <BootstrapForm.Group
                  as={Col}
                  md="4"
                  controlId="forwarderAddressCity"
                  className="mt-2"
                >
                  <BootstrapForm.Label>City</BootstrapForm.Label>
                  <Field
                    type="text"
                    value={values.forwarderAddressCity}
                    as={BootstrapForm.Control}
                    required
                  />
                  <ErrorMessage name="forwarderAddressCity" component="div" />
                </BootstrapForm.Group>
                <BootstrapForm.Group
                  as={Col}
                  md="4"
                  controlId="forwarderAddressState"
                  className="mt-2"
                >
                  <ErrorMessage name="forwarderAddressState" component="div" />
                  <BootstrapForm.Label>State</BootstrapForm.Label>
                  <Field
                    type="text"
                    value={values.forwarderAddressState}
                    as={BootstrapForm.Control}
                    required
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group
                  as={Col}
                  md="4"
                  controlId="forwarderAddressZip"
                  className="mt-2"
                >
                  <BootstrapForm.Label>Zip</BootstrapForm.Label>
                  <Field
                    type="text"
                    value={values.forwarderAddressZip}
                    as={BootstrapForm.Control}
                    required
                  />
                  <ErrorMessage name="forwarderAddressZip" component="div" />
                </BootstrapForm.Group>
              </Row>
              <Col md={4}>
                <BootstrapForm.Label>Forwarder Status</BootstrapForm.Label>
                <BootstrapForm.Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Choose forwarder status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </BootstrapForm.Select>
                <ErrorMessage name="status" component="div" className="error" />
              </Col>

              <Col className="col-md-5 mb-3 mt-3">
                <Button type="submit" intent="success" icon="confirm">
                  Save & Add Forwarder
                </Button>
              </Col>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ForwarderDetails;
