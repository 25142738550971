// api.js
import {
  collection,
  getDocs,
  query,
  where,
  and,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../firebase";

// Get the current date in the local time zone
const today = new Date();
// Get the time zone offset in milliseconds
const timeZoneOffsetMs = today.getTimezoneOffset() * 60 * 1000;
// Adjust the current date for the time zone offset
const currentDate = new Date(today.getTime() - timeZoneOffsetMs)
  .toISOString()
  .split("T")[0];
// Calculate tomorrow's date by adding one day to the current date
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
// Adjust tomorrow's date for the time zone offset
const tomorrowDate = new Date(tomorrow.getTime() - timeZoneOffsetMs)
  .toISOString()
  .split("T")[0];

const orderFields = [
  "id",
  "deliveryAttempts",
  "selectedCustomer",
  "selectedForwarder",
  "pieces",
  "calculatedRates",
  "orderNumber",
  "status",
  "signature",
  "assignedDriver",
  "customerCompanyName",
  "customerFirstName",
  "customerLastName",
  "customerAddress",
  "customerAddressCity",
  "customerAddressState",
  "customerAddressZip",
  "customerPrimaryPhone",
  "deliveryDate",
];

// **************** General APIs ****************

export const getActiveDrivers = async () => {
  try {
    const usersRef = collection(firestore, "users");
    const querySnapshot = await getDocs(
      query(
        usersRef,
        where("status", "==", "Active"),
        where("role", "==", "Driver")
      )
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: `${doc.data().firstName} ${doc.data().lastName}`,
    }));
  } catch (error) {
    console.error("Error fetching active drivers:", error);
    throw error;
  }
};

export const getInactiveUsers = async () => {
  try {
    const usersRef = collection(firestore, "users");
    const querySnapshot = await getDocs(
      query(usersRef, where("status", "==", "Inactive"))
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: `${doc.data().firstName} ${doc.data().lastName}`,
      role: `${doc.data().role}`,
      status: `${doc.data().status}`,
    }));
  } catch (error) {
    console.error("Error fetching inactive users:", error);
    throw error;
  }
};

export const getActiveCustomers = async () => {
  try {
    const customersRef = collection(firestore, "customers");
    const querySnapshot = await getDocs(
      query(customersRef, where("status", "==", "Active"))
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      customerCompanyName: `${doc.data().customerCompanyName}`,
      customerFirstName: `${doc.data().customerFirstName}`,
      customerLastName: `${doc.data().customerLastName}`,
      customerPickupAddress: `${doc.data().customerPickupAddress}`,
      customerPickupAddressCity: `${doc.data().customerPickupAddressCity}`,
      customerPickupAddressState: `${doc.data().customerPickupAddressState}`,
      customerPickupAddressZip: `${doc.data().customerPickupAddressZip}`,
      customerAddress: `${doc.data().customerAddress}`,
      customerAddressCity: `${doc.data().customerAddressCity}`,
      customerAddressState: `${doc.data().customerAddressState}`,
      customerAddressZip: `${doc.data().customerAddressZip}`,
      customerPrimaryPhone: `${doc.data().customerPrimaryPhone}`,
      customerSecondaryPhone: `${doc.data().customerSecondaryPhone}`,
      status: `${doc.data().status}`,
    }));
  } catch (error) {
    console.error("Error fetching active customers:", error);
    throw error;
  }
};

export const getInactiveCustomers = async () => {
  try {
    const customersRef = collection(firestore, "customers");
    const querySnapshot = await getDocs(
      query(customersRef, where("status", "==", "Inactive"))
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      customerCompanyName: `${doc.data().customerCompanyName}`,
      customerFirstName: `${doc.data().customerFirstName}`,
      customerLastName: `${doc.data().customerLastName}`,
      customerPickupAddress: `${doc.data().customerPickupAddress}`,
      customerPickupAddressCity: `${doc.data().customerPickupAddressCity}`,
      customerPickupAddressState: `${doc.data().customerPickupAddressState}`,
      customerPickupAddressZip: `${doc.data().customerPickupAddressZip}`,
      customerAddress: `${doc.data().customerAddress}`,
      customerAddressCity: `${doc.data().customerAddressCity}`,
      customerAddressState: `${doc.data().customerAddressState}`,
      customerAddressZip: `${doc.data().customerAddressZip}`,
      customerPrimaryPhone: `${doc.data().customerPrimaryPhone}`,
      customerSecondaryPhone: `${doc.data().customerSecondaryPhone}`,
      status: `${doc.data().status}`,
    }));
  } catch (error) {
    console.error("Error fetching inactive customers:", error);
    throw error;
  }
};

export const getActiveForwarders = async () => {
  try {
    const forwardersRef = collection(firestore, "forwarders");
    const querySnapshot = await getDocs(
      query(forwardersRef, where("status", "==", "Active"))
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      forwarderCompanyName: doc.data().forwarderCompanyName || "",
      forwarderFirstName: doc.data().forwarderFirstName || "",
      forwarderLastName: doc.data().forwarderLastName || "",
      forwarderAddress: doc.data().forwarderAddress || "",
      forwarderAddressCity: doc.data().forwarderAddressCity || "",
      forwarderAddressState: doc.data().forwarderAddressState || "",
      forwarderAddressZip: doc.data().forwarderAddressZip || "",
      forwarderPrimaryPhone: doc.data().forwarderPrimaryPhone || "",
      forwarderSecondaryPhone: doc.data().forwarderSecondaryPhone || "",
      status: doc.data().status || "",
    }));
  } catch (error) {
    console.error("Error fetching active forwarders:", error);
    throw error;
  }
};

export const getInactiveForwarders = async () => {
  try {
    const forwardersRef = collection(firestore, "forwarders");
    const querySnapshot = await getDocs(
      query(forwardersRef, where("status", "==", "Inactive"))
    );

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      forwarderCompanyName: doc.data.forwarderCompanyName || "",
      forwarderFirstName: doc.data.forwarderFirstName || "",
      forwarderLastName: doc.data.forwarderLastName || "",
      forwarderAddress: doc.data.forwarderAddress || "",
      forwarderAddressCity: doc.data.forwarderAddressCity || "",
      forwarderAddressState: doc.data.forwarderAddressState || "",
      forwarderAddressZip: doc.data.forwarderAddressZip || "",
      forwarderPrimaryPhone: doc.data.forwarderPrimaryPhone || "",
      forwarderSecondaryPhone: doc.data.forwarderSecondaryPhone || "",
      status: doc.data.status || "",
    }));
  } catch (error) {
    console.error("Error fetching inactive forwarders:", error);
    throw error;
  }
};

export const getDueDeliveries = async () => {
  try {
    // Retrieve orders from Firestore
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("deliveryDate", ">=", currentDate), // Match delivery dates equal to or after the current date
        where("deliveryDate", "<", tomorrowDate), // Match delivery dates before tomorrow
        where("status", "==", "Active")
      )
    );

    // Map query results to the required format
    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();

      return {
        id: doc.id,
        deliveryAttempts: orderData.deliveryAttempts || [],
        selectedCustomer: orderData.selectedCustomer || {},
        selectedForwarder: orderData.selectedForwarder || {},
        pieces: orderData.pieces || [],
        calculatedRates: orderData.calculatedRates || [],
        orderNumber: `${orderData.orderNumber}`,
        status: `${orderData.status}`,
        signature: `${orderData.signature}`,
        assignedDriver: `${orderData.assignedDriver}`,
        customerCompanyName: `${orderData.customerCompanyName}`,
        customerFirstName: `${orderData.customerFirstName}`,
        customerLastName: `${orderData.customerLastName}`,
        customerAddress: `${orderData.customerAddress}`,
        customerAddressCity: `${orderData.customerAddressCity}`,
        customerAddressState: `${orderData.customerAddressState}`,
        customerAddressZip: `${orderData.customerAddressZip}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone}`,
        deliveryDate: `${orderData.deliveryDate}`,
      };
    });
  } catch (error) {
    // Handle errors
    console.error("Error fetching due deliveries:", error);
    throw error;
  }
};

export const getLateOrders = async () => {
  try {
    // Retrieve orders from Firestore
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Active"),
        where("deliveryDate", "<", currentDate), // Match delivery dates due before the current date
        orderBy("deliveryDate")
      )
    );

    // Map query results to the required format
    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();

      return {
        id: doc.id,
        deliveryAttempts: orderData.deliveryAttempts || [],
        selectedCustomer: orderData.selectedCustomer || {},
        selectedForwarder: orderData.selectedForwarder || {},
        pieces: orderData.pieces || [],
        calculatedRates: orderData.calculatedRates || {},
        orderNumber: `${orderData.orderNumber || ""}`,
        status: `${orderData.status || ""}`,
        signature: `${orderData.signature || ""}`,
        assignedDriver: `${orderData.assignedDriver || ""}`,
        customerCompanyName: `${orderData.customerCompanyName || ""}`,
        customerFirstName: `${orderData.customerFirstName || ""}`,
        customerLastName: `${orderData.customerLastName || ""}`,
        customerAddress: `${orderData.customerAddress || ""}`,
        customerAddressCity: `${orderData.customerAddressCity || ""}`,
        customerAddressState: `${orderData.customerAddressState || ""}`,
        customerAddressZip: `${orderData.customerAddressZip || ""}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
        deliveryDate: `${orderData.deliveryDate || ""}`,
      };
    });
  } catch (error) {
    // Handle errors
    console.error("Error fetching late orders:", error);
    throw error;
  }
};

export const getDeliveredOrders = async () => {
  try {
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Delivered"),
        orderBy("deliveryDate")
      )
    );

    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();
      const deliveryAttempts = orderData.deliveryAttempts || [];
      const selectedCustomer = orderData.selectedCustomer || {};
      const selectedForwarder = orderData.selectedForwarder || {};
      const pieces = orderData.pieces || [];
      const calculatedRates = orderData.calculatedRates || {};
      return {
        id: doc.id,
        deliveryAttempts,
        selectedCustomer,
        selectedForwarder,
        pieces,
        calculatedRates,
        orderNumber: `${orderData.orderNumber}`,
        status: `${orderData.status}`,
        signature: `${orderData.signature}`,
        assignedDriver: `${orderData.assignedDriver}`,
        customerCompanyName: `${orderData.customerCompanyName}`,
        customerFirstName: `${orderData.customerFirstName}`,
        customerLastName: `${orderData.customerLastName}`,
        customerAddress: `${orderData.customerAddress}`,
        customerAddressCity: `${orderData.customerAddressCity}`,
        customerAddressState: `${orderData.customerAddressState}`,
        customerAddressZip: `${orderData.customerAddressZip}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone}`,
        deliveryDate: `${orderData.deliveryDate}`,
      };
    });
  } catch (error) {
    console.error("Error fetching delivered orders:", error);
    throw error;
  }
};

export const getBillableOrders = async () => {
  try {
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(ordersRef, where("status", "==", "Delivered"))
    );

    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();
      const deliveryAttempts = orderData.deliveryAttempts || [];
      const selectedCustomer = orderData.selectedCustomer || {};
      const selectedForwarder = orderData.selectedForwarder || {};
      const pieces = orderData.pieces || [];
      const calculatedRates = orderData.calculatedRates || {};
      return {
        id: doc.id,
        deliveryAttempts,
        selectedCustomer,
        selectedForwarder,
        pieces,
        calculatedRates,
        orderNumber: `${orderData.orderNumber}`,
        status: `${orderData.status}`,
        signature: `${orderData.signature}`,
        assignedDriver: `${orderData.assignedDriver}`,
        customerCompanyName: `${orderData.customerCompanyName}`,
        customerFirstName: `${orderData.customerFirstName}`,
        customerLastName: `${orderData.customerLastName}`,
        customerAddress: `${orderData.customerAddress}`,
        customerAddressCity: `${orderData.customerAddressCity}`,
        customerAddressState: `${orderData.customerAddressState}`,
        customerAddressZip: `${orderData.customerAddressZip}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone}`,
        deliveryDate: `${orderData.deliveryDate}`,
        count: calculatedRates.totalCharge.length,
      };
    });
  } catch (error) {
    console.error("Error fetching delivered orders:", error);
    throw error;
  }
};

export const getDueInvoices = async () => {
  try {
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Invoiced"),
        orderBy("deliveryDate")
      )
    );

    // Map query results to the required format
    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();

      return {
        id: doc.id,
        deliveryAttempts: orderData.deliveryAttempts || [],
        selectedCustomer: orderData.selectedCustomer || {},
        selectedForwarder: orderData.selectedForwarder || {},
        pieces: orderData.pieces || [],
        calculatedRates: orderData.calculatedRates || {},
        orderNumber: `${orderData.orderNumber || ""}`,
        status: `${orderData.status || ""}`,
        signature: `${orderData.signature || ""}`,
        assignedDriver: `${orderData.assignedDriver || ""}`,
        customerCompanyName: `${orderData.customerCompanyName || ""}`,
        customerFirstName: `${orderData.customerFirstName || ""}`,
        customerLastName: `${orderData.customerLastName || ""}`,
        customerAddress: `${orderData.customerAddress || ""}`,
        customerAddressCity: `${orderData.customerAddressCity || ""}`,
        customerAddressState: `${orderData.customerAddressState || ""}`,
        customerAddressZip: `${orderData.customerAddressZip || ""}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
        deliveryDate: `${orderData.deliveryDate || ""}`,
      };
    });
  } catch (error) {
    console.error("Error fetching due invoices:", error);
    throw error;
  }
};

export const getInvoicedOrders = async () => {
  try {
    const ordersRef = collection(firestore, "orders");
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Invoiced"),
        where("deliveryDate", ">", thirtyDaysAgo.toISOString().split("T")[0]), // Invoiced in last 30 days and unpaid
        orderBy("deliveryDate")
      )
    );

    // Map query results to the required format
    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();

      return {
        id: doc.id,
        deliveryAttempts: orderData.deliveryAttempts || [],
        selectedCustomer: orderData.selectedCustomer || {},
        selectedForwarder: orderData.selectedForwarder || {},
        pieces: orderData.pieces || [],
        calculatedRates: orderData.calculatedRates || {},
        orderNumber: `${orderData.orderNumber || ""}`,
        status: `${orderData.status || ""}`,
        signature: `${orderData.signature || ""}`,
        assignedDriver: `${orderData.assignedDriver || ""}`,
        customerCompanyName: `${orderData.customerCompanyName || ""}`,
        customerFirstName: `${orderData.customerFirstName || ""}`,
        customerLastName: `${orderData.customerLastName || ""}`,
        customerAddress: `${orderData.customerAddress || ""}`,
        customerAddressCity: `${orderData.customerAddressCity || ""}`,
        customerAddressState: `${orderData.customerAddressState || ""}`,
        customerAddressZip: `${orderData.customerAddressZip || ""}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
        deliveryDate: `${orderData.deliveryDate || ""}`,
      };
    });
  } catch (error) {
    console.error("Error fetching invoiced orders:", error);
    throw error;
  }
};

export const getCompletedOrders = async () => {
  try {
    const ordersRef = collection(firestore, "orders");
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Complete"),
        where("deliveryDate", ">", thirtyDaysAgo.toISOString().split("T")[0]), // Closed in last 30 days
        orderBy("deliveryDate")
      )
    );

    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();
      const deliveryAttempts = orderData.deliveryAttempts || [];
      const selectedCustomer = orderData.selectedCustomer || {};
      const selectedForwarder = orderData.selectedForwarder || {};
      const pieces = orderData.pieces || [];
      const calculatedRates = orderData.calculatedRates || {};
      return {
        id: doc.id,
        deliveryAttempts,
        selectedCustomer,
        selectedForwarder,
        pieces,
        calculatedRates,
        orderNumber: `${orderData.orderNumber}`,
        status: `${orderData.status}`,
        signature: `${orderData.signature}`,
        assignedDriver: `${orderData.assignedDriver}`,
        customerCompanyName: `${orderData.customerCompanyName}`,
        customerFirstName: `${orderData.customerFirstName}`,
        customerLastName: `${orderData.customerLastName}`,
        customerAddress: `${orderData.customerAddress}`,
        customerAddressCity: `${orderData.customerAddressCity}`,
        customerAddressState: `${orderData.customerAddressState}`,
        customerAddressZip: `${orderData.customerAddressZip}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone}`,
        deliveryDate: `${orderData.deliveryDate}`,
      };
    });
  } catch (error) {
    console.error("Error fetching completed orders:", error);
    throw error;
  }
};

// ******************* Driver APIs *********************

export const getLoggedInUserOrders = async (user) => {
  try {
    // Ensure user is authenticated and has a valid UID
    if (user && user.email) {
      const ordersRef = collection(firestore, "orders");
      const querySnapshot = await getDocs(
        query(
          ordersRef,
          where("status", "==", "Active"),
          where("assignedDriver", "==", `${user.firstName} ${user.lastName}`),
          orderBy("deliveryDate")
        )
      );

      // Map query results to the required format
      return querySnapshot.docs.map((doc) => {
        const orderData = doc.data();

        return {
          id: doc.id,
          deliveryAttempts: orderData.deliveryAttempts || [],
          selectedCustomer: orderData.selectedCustomer || {},
          selectedForwarder: orderData.selectedForwarder || {},
          pieces: orderData.pieces || [],
          calculatedRates: orderData.calculatedRates || {},
          orderNumber: `${orderData.orderNumber || ""}`,
          status: `${orderData.status || ""}`,
          signature: `${orderData.signature || ""}`,
          assignedDriver: `${orderData.assignedDriver || ""}`,
          customerCompanyName: `${orderData.customerCompanyName || ""}`,
          customerFirstName: `${orderData.customerFirstName || ""}`,
          customerLastName: `${orderData.customerLastName || ""}`,
          customerAddress: `${orderData.customerAddress || ""}`,
          customerAddressCity: `${orderData.customerAddressCity || ""}`,
          customerAddressState: `${orderData.customerAddressState || ""}`,
          customerAddressZip: `${orderData.customerAddressZip || ""}`,
          customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
          deliveryDate: `${orderData.deliveryDate || ""}`,
        };
      });
    } else {
      console.error("User information is missing or incomplete.");
      return []; // Return an empty array or handle the case appropriately
    }
  } catch (error) {
    console.error("Error fetching logged-in user's orders:", error);
    throw error;
  }
};

export const getTodaysLoggedInUserOrders = async (user) => {
  try {
    // Ensure user is authenticated and has a valid UID
    if (user && user.email) {
      const ordersRef = collection(firestore, "orders");
      const querySnapshot = await getDocs(
        query(
          ordersRef,
          where("status", "==", "Active"),
          where("assignedDriver", "==", `${user.firstName} ${user.lastName}`),
          where("deliveryDate", ">=", currentDate), // Match delivery dates equal to or after the current date
          where("deliveryDate", "<", tomorrowDate) // Match delivery dates before tomorrow
        )
      );

      // Map query results to the required format
      return querySnapshot.docs.map((doc) => {
        const orderData = doc.data();

        return {
          id: doc.id,
          deliveryAttempts: orderData.deliveryAttempts || [],
          selectedCustomer: orderData.selectedCustomer || {},
          selectedForwarder: orderData.selectedForwarder || {},
          pieces: orderData.pieces || [],
          calculatedRates: orderData.calculatedRates || {},
          orderNumber: `${orderData.orderNumber || ""}`,
          status: `${orderData.status || ""}`,
          signature: `${orderData.signature || ""}`,
          assignedDriver: `${orderData.assignedDriver || ""}`,
          customerCompanyName: `${orderData.customerCompanyName || ""}`,
          customerFirstName: `${orderData.customerFirstName || ""}`,
          customerLastName: `${orderData.customerLastName || ""}`,
          customerAddress: `${orderData.customerAddress || ""}`,
          customerAddressCity: `${orderData.customerAddressCity || ""}`,
          customerAddressState: `${orderData.customerAddressState || ""}`,
          customerAddressZip: `${orderData.customerAddressZip || ""}`,
          customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
          deliveryDate: `${orderData.deliveryDate || ""}`,
        };
      });
    } else {
      console.error("User information is missing or incomplete.");
      return []; // Return an empty array or handle the case appropriately
    }
  } catch (error) {
    console.error("Error fetching logged-in user's orders:", error);
    throw error;
  }
};

export const getUpcomingLoggedInUserOrders = async (user) => {
  try {
    // Ensure user is authenticated and has a valid UID
    if (user && user.email) {
      const ordersRef = collection(firestore, "orders");
      const querySnapshot = await getDocs(
        query(
          ordersRef,
          where("status", "==", "Active"),
          where("assignedDriver", "==", `${user.firstName} ${user.lastName}`),
          where("deliveryDate", ">=", tomorrowDate), // Match delivery dates due tomorrow or after
          orderBy("deliveryDate")
        )
      );

      return querySnapshot.docs.map((doc) => {
        const orderData = doc.data();

        // Format deliveryDate as Month-Day-Year
        const deliveryDate = new Date(orderData.deliveryDate);
        const formattedDeliveryDate = `${
          deliveryDate.getMonth() + 1
        }-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`;

        const deliveryAttempts = orderData.deliveryAttempts || [];
        const selectedCustomer = orderData.selectedCustomer || {};
        const selectedForwarder = orderData.selectedForwarder || {};
        const pieces = orderData.pieces || [];
        const calculatedRates = orderData.calculatedRates || [];
        return {
          id: doc.id,
          deliveryAttempts,
          selectedCustomer,
          selectedForwarder,
          pieces,
          calculatedRates,
          orderNumber: `${orderData.orderNumber}`,
          status: `${orderData.status}`,
          signature: `${orderData.signature}`,
          assignedDriver: `${orderData.assignedDriver}`,
          forwarderCompanyName: `${orderData.forwarderCompanyName}`,
          forwarderFirstName: `${orderData.forwarderFirstName}`,
          forwarderLastName: `${orderData.forwarderLastName}`,
          forwarderAddress: `${orderData.forwarderAddress}`,
          forwarderAddressCity: `${orderData.forwarderAddressCity}`,
          forwarderAddressState: `${orderData.forwarderAddressState}`,
          forwarderAddressZip: `${orderData.forwarderAddressZip}`,
          forwarderPrimaryPhone: `${orderData.forwarderPrimaryPhone}`,
          deliveryDate: formattedDeliveryDate, // Use the formatted deliveryDate
        };
      });
    } else {
      console.error("User information is missing or incomplete.");
      return []; // Return an empty array or handle the case appropriately
    }
  } catch (error) {
    console.error("Error fetching logged-in user's upcoming orders:", error);
    throw error;
  }
};

export const getLateLoggedInUserOrders = async (user) => {
  try {
    // Ensure user is authenticated and has a valid UID
    if (user && user.email) {
      const ordersRef = collection(firestore, "orders");
      const querySnapshot = await getDocs(
        query(
          ordersRef,
          where("status", "==", "Active"),
          where("assignedDriver", "==", `${user.firstName} ${user.lastName}`),
          where("deliveryDate", "<", currentDate), // Match delivery dates due before the current date
          orderBy("deliveryDate")
        )
      );

      // Map query results to the required format
      return querySnapshot.docs.map((doc) => {
        const orderData = doc.data();

        return {
          id: doc.id,
          deliveryAttempts: orderData.deliveryAttempts || [],
          selectedCustomer: orderData.selectedCustomer || {},
          selectedForwarder: orderData.selectedForwarder || {},
          pieces: orderData.pieces || [],
          calculatedRates: orderData.calculatedRates || {},
          orderNumber: `${orderData.orderNumber || ""}`,
          status: `${orderData.status || ""}`,
          signature: `${orderData.signature || ""}`,
          assignedDriver: `${orderData.assignedDriver || ""}`,
          customerCompanyName: `${orderData.customerCompanyName || ""}`,
          customerFirstName: `${orderData.customerFirstName || ""}`,
          customerLastName: `${orderData.customerLastName || ""}`,
          customerAddress: `${orderData.customerAddress || ""}`,
          customerAddressCity: `${orderData.customerAddressCity || ""}`,
          customerAddressState: `${orderData.customerAddressState || ""}`,
          customerAddressZip: `${orderData.customerAddressZip || ""}`,
          customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
          deliveryDate: `${orderData.deliveryDate || ""}`,
        };
      });
    } else {
      console.error("User information is missing or incomplete.");
      return []; // Return an empty array or handle the case appropriately
    }
  } catch (error) {
    console.error("Error fetching late logged-in user's orders:", error);
    throw error;
  }
};

export const getLoggedInUserDeliveredOrders = async (user) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  try {
    const ordersRef = collection(firestore, "orders");
    const querySnapshot = await getDocs(
      query(
        ordersRef,
        where("status", "==", "Delivered"),
        where("assignedDriver", "==", `${user.firstName} ${user.lastName}`),
        where("deliveryDate", ">", thirtyDaysAgo.toISOString().split("T")[0]), // Filter for deliveryDate within the last 30 days
        orderBy("deliveryDate")
      )
    );

    // Map query results to the required format
    return querySnapshot.docs.map((doc) => {
      const orderData = doc.data();

      return {
        id: doc.id,
        deliveryAttempts: orderData.deliveryAttempts || [],
        selectedCustomer: orderData.selectedCustomer || {},
        selectedForwarder: orderData.selectedForwarder || {},
        pieces: orderData.pieces || [],
        calculatedRates: orderData.calculatedRates || {},
        orderNumber: `${orderData.orderNumber || ""}`,
        status: `${orderData.status || ""}`,
        signature: `${orderData.signature || ""}`,
        assignedDriver: `${orderData.assignedDriver || ""}`,
        customerCompanyName: `${orderData.customerCompanyName || ""}`,
        customerFirstName: `${orderData.customerFirstName || ""}`,
        customerLastName: `${orderData.customerLastName || ""}`,
        customerAddress: `${orderData.customerAddress || ""}`,
        customerAddressCity: `${orderData.customerAddressCity || ""}`,
        customerAddressState: `${orderData.customerAddressState || ""}`,
        customerAddressZip: `${orderData.customerAddressZip || ""}`,
        customerPrimaryPhone: `${orderData.customerPrimaryPhone || ""}`,
        deliveryDate: `${orderData.deliveryDate || ""}`,
      };
    });
  } catch (error) {
    console.error("Error fetching delivered orders:", error);
    throw error;
  }
};
