import React from "react";
import Login from "../components/Login";

const Loginpage = () => {
  return (
    <div className="wrapper">
      <Login />
    </div>
  );
};

export default Loginpage;
