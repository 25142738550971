import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { Col, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Icon } from "@blueprintjs/core";
import { useAppContext } from "../context/AppProvider";

const DeliveryAttempts = ({
  order,
  handleCaptureSignature,
  handleAttemptDelivery,
}) => {
  const [showAttemptedDeliveryToast, setShowAttemptedDeliveryToast] =
    useState(false);
  const [showDeliveryToast, setShowDeliveryToast] = useState(false);
  const [error, setError] = useState(null);
  const { updateOrderDeliveryAttempts } = useAppContext();

  const handleAttemptedDelivery = async () => {
    try {
      if (!order || !order.id) {
        throw new Error("Invalid or missing order data");
      }
      let driverName = order.assignedDriver || "N/A";
      if (driverName === "N/A" && !order.assignedDriver) {
        // If assignedDriver is still not available, fetch the order details again
        const updatedOrder = await getOrderDetails(order.id);
        if (updatedOrder && updatedOrder.assignedDriver) {
          driverName = updatedOrder.assignedDriver;
        }
      }

      const orderRef = doc(firestore, "orders", order.id);

      const currentDeliveryAttempts = Array.isArray(order.deliveryAttempts)
        ? order.deliveryAttempts
        : [];

      const deliveryAttemptData = {
        timestamp: new Date().toLocaleString(),
        driver: driverName,
      };

      const updatedDeliveryAttempts = [
        ...currentDeliveryAttempts,
        deliveryAttemptData,
      ];

      await updateDoc(orderRef, {
        deliveryAttempts: updatedDeliveryAttempts,
      });

      setShowAttemptedDeliveryToast(true);

      // Call the function directly from context
      updateOrderDeliveryAttempts(order.id, driverName);
    } catch (error) {
      console.error("Error recording attempted delivery:", error);
      setError(error.message);
    }
  };

  const handleDelivered = async () => {
    try {
      if (!order.signature) {
        throw new Error("Signature required before marking order delivered.");
      }

      const updatedOrder = { id: order.id, status: "Delivered" }; // Only include fields you want to update
      await updateOrder(updatedOrder);

      setShowDeliveryToast(true);

      // You can show a success message here if needed
    } catch (error) {
      console.error("Error marking order as Delivered:", error);
      setError(error.message);
    }
  };

  const updateOrder = async (updatedOrder) => {
    try {
      const orderRef = doc(firestore, "orders", updatedOrder.id);
      await updateDoc(orderRef, updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
      setError(error.message);
    }
  };

  return (
    <div>
      <Alert
        variant="warning"
        show={showAttemptedDeliveryToast}
        onClose={() => setShowAttemptedDeliveryToast(false)}
        dismissible
      >
        Unable to deliver logged successfully!
      </Alert>
      <Alert
        variant="danger"
        show={error !== null}
        onClose={() => setError(null)}
        dismissible
      >
        {error}
      </Alert>
      <Alert
        variant="success"
        show={showDeliveryToast}
        onClose={() => setShowDeliveryToast(false)}
        dismissible
      >
        Order delivered successfully!
      </Alert>
      <Col>
        {order.deliveryAttempts && order.deliveryAttempts.length > 0 ? (
          order.deliveryAttempts.map((attempt, index) => (
            <p
              key={index}
            >{`${attempt.timestamp} - Driver: ${attempt.driver}`}</p>
          ))
        ) : (
          <p>No delivery attempts</p>
        )}
        {order.signature ? (
          <p>Signature: Signature on file</p>
        ) : (
          <Button
            variant="link"
            className="pl-0"
            onClick={() => handleCaptureSignature(order)}
          >
            Capture Signature <Icon icon="draw" intent="primary" size={20} />
          </Button>
        )}
        <div className="mt-3">
          <Button
            variant="warning"
            className="mb-2 "
            onClick={() => handleAttemptedDelivery(order)}
          >
            <Icon icon="issue" intent="danger" size={20} /> Unable to Deliver
          </Button>{" "}
          <Button
            variant="success"
            className="mb-2 "
            onClick={() => handleDelivered(order)}
          >
            <Icon icon="confirm" color="#fff" size={20} /> Mark as Delivered
          </Button>
        </div>
      </Col>
    </div>
  );
};

export default DeliveryAttempts;
