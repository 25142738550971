import React, { useState, useEffect } from "react";
import Navigation from "../components/managerView/Navigation";
import NavigationDriver from "../components/driverView/NavigationDriver";
import { useParams } from "react-router-dom"; // Import useParams to access URL parameters
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { Card, Elevation, Icon, Spinner } from "@blueprintjs/core";
import { useAuth } from "../context/AuthContext";
import ViewSelectedOrder from "../components/driverView/ViewSelectedOrder";
import ViewOrderDetails from "../components/ViewOrderDetails";
import { useTheme } from "../context/ThemeContext";

const ViewOrder = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { orderId } = useParams(); // Access orderId from URL parameters

  // Check if user has the role property before accessing it
  const role = user && user.role;

  useEffect(() => {
    const fetchSelectedOrder = async () => {
      try {
        const orderDoc = await getDoc(doc(firestore, "orders", orderId)); // Fetch order using orderId
        if (orderDoc.exists()) {
          const orderData = orderDoc.data();
          setSelectedOrder(orderData); // Set selectedOrder to the fetched order
        } else {
          console.error("Order not found");
        }
      } catch (error) {
        console.error("Error fetching selected order:", error);
      }
    };

    if (orderId) {
      fetchSelectedOrder();
    }
  }, [orderId]);

  return (
    <div className={isDarkMode ? "bp5-dark bg-dark" : ""}>
      {/* Render based on user role */}
      {role === "Manager" || role === "Admin" ? (
        <>
          <Navigation />
          <div>
            {selectedOrder ? (
              <ViewOrderDetails order={selectedOrder} />
            ) : (
              <p>
                Loading... <Spinner intent="primary" />
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <div>
            <NavigationDriver />

            {selectedOrder ? (
              <ViewSelectedOrder selectedOrder={selectedOrder} />
            ) : (
              <p>
                Loading... <Spinner intent="primary" />
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewOrder;
