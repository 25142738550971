import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Navbar,
  Drawer,
  Classes,
  Position,
  InputGroup,
} from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";

const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSmallViewport, setIsSmallViewport] = useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme(); // Updated hook

  // Function to toggle dark mode
  const handleDarkModeToggle = () => {
    toggleDarkMode(); // Call the toggleDarkMode function from useTheme
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallViewport(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial viewport size
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const navButtons = [
    {
      to: "/dashboard",
      icon: "dashboard",
      text: "Dashboard",
      intent: "primary",
    },
    {
      to: "/new_order",
      icon: "folder-new",
      text: "New Order",
      intent: "primary",
    },
    { to: "/deliveries", icon: "truck", text: "Deliveries", intent: "primary" },
    {
      to: "/search_orders",
      icon: "search",
      text: "Search Orders",
      intent: "primary",
    },
    { to: "/account", icon: "person", text: "Profile", intent: "primary" },
    { to: "/users", icon: "team", text: "Manage Users", intent: "primary" },
    { to: "/about", icon: "help", text: "About", intent: "primary" },
    {
      onClick: handleLogout,
      icon: "log-out",
      text: "Logout",
      intent: "success",
    },
  ];

  return (
    <div className="hide-on-print">
      <Navbar className={isDarkMode ? "bp5-dark" : ""}>
        <Navbar.Group align="left">
          <Navbar.Heading>
            Quick Delivery |{" "}
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align="right">
          {!isSmallViewport ? (
            <>
              {navButtons.map((button, index) => (
                <Link key={index} to={button.to}>
                  <Button
                    className="bp5-minimal"
                    icon={button.icon}
                    text={button.text}
                    onClick={button.onClick}
                    intent={button.intent}
                  />
                </Link>
              ))}
              {/* Dark mode toggle button */}
              <Button
                className="bp5-minimal"
                icon={isDarkMode ? "flash" : "moon"}
                intent="warning"
                onClick={handleDarkModeToggle}
              />
            </>
          ) : (
            <>
              <Button
                className="bp5-minimal"
                icon="menu"
                onClick={() => setIsDrawerOpen(true)}
                style={{ marginRight: "10px" }}
              />
              <Drawer
                onClose={() => setIsDrawerOpen(false)}
                isOpen={isDrawerOpen}
                title="Menu"
                position={Position.LEFT}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                className={isDarkMode ? "bp5-dark" : ""}
                style={{ zIndex: 2000 }}
              >
                <div className={Classes.DRAWER_BODY}>
                  <div className={Classes.DIALOG_BODY}>
                    {navButtons.map((button, index) => (
                      <Link key={index} to={button.to}>
                        <Button
                          className="bp5-minimal"
                          icon={button.icon}
                          text={button.text}
                          onClick={button.onClick}
                          intent={button.intent}
                        />
                      </Link>
                    ))}
                    {/* Dark mode toggle button */}
                    <Button
                      className="bp5-minimal"
                      icon={isDarkMode ? "flash" : "moon"}
                      intent="warning"
                      onClick={handleDarkModeToggle}
                    />
                  </div>
                </div>
              </Drawer>
            </>
          )}
        </Navbar.Group>
      </Navbar>
    </div>
  );
};

export default Navigation;
