import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  ListGroup,
  Badge,
  Tab,
  Tabs,
  Container,
} from "react-bootstrap";
import {
  Button,
  ButtonGroup,
  Section,
  SectionCard,
  Divider,
  Card,
  Elevation,
} from "@blueprintjs/core";
import DeliveryAttempts from "./DeliveryAttempts";
import formatDate from "../utility/formatDate";
import { firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ConfirmationModal from "./ConfirmationModal";
import { useAuth } from "../context/AuthContext";
import { useAppContext } from "../context/AppProvider";

const OrderCardComponent = ({
  order,
  handleViewDetails,
  handleEditOrder,
  handleDeleteOrder,
  handleAttemptDelivery,
  handleCaptureSignature,
  handleCreateInvoice,
}) => {
  const { user } = useAuth();
  const role = user && user.role;
  const { updateOrderDeliveryAttempts } = useAppContext();
  const [contactKey, setContactKey] = useState("forwarder");
  const [detailsKey, setDetailsKey] = useState("fees");
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const storage = getStorage();

  const handleMarkAsPaid = (order) => {
    setSelectedOrder(order);
    setShowModal(true); // Show the confirmation modal
  };

  const confirmMarkAsPaid = async () => {
    setShowModal(false); // Hide the modal
    try {
      const newStatus = "Complete";
      await updateOrderStatus(selectedOrder, newStatus);
    } catch (error) {
      console.error("Error marking as paid:", error);
    }
  };

  const updateOrderStatus = async (order, newStatus) => {
    if (!order || !order.id) {
      throw new Error("Invalid or missing order data");
    }

    const orderRef = doc(firestore, "orders", order.id);
    await updateDoc(orderRef, { status: newStatus });
  };

  const uploadFileToStorage = async () => {
    try {
      if (!file || !order || !order.orderNumber) {
        throw new Error("Invalid file or order data");
      }

      // Construct the path with the order number and file name
      const path = `files/invoices/${order.orderNumber}/${file.name}`;

      // Upload the file to the constructed path
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Update the order document with the file details
      const orderRef = doc(firestore, "orders", order.id);
      await updateDoc(orderRef, {
        invoice: {
          fileName: file.name,
          downloadURL: downloadURL,
          uploadDate: new Date().toLocaleString(),
        },
      });

      // Perform any necessary actions after successful upload
      console.log("File uploaded successfully. Download URL:", downloadURL);
      // You can add further logic here, such as displaying a success message
    } catch (error) {
      console.error("Error uploading file to storage:", error);
      // Handle error
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadFileToStorage();
  };

  const handleAttemptedDelivery = async (driverName) => {
    try {
      if (!order || !order.id) {
        throw new Error("Invalid or missing order data");
      }

      const orderRef = doc(firestore, "orders", order.id);

      // Check if deliveryAttempts is defined and is an array, otherwise initialize it
      const currentDeliveryAttempts = Array.isArray(order.deliveryAttempts)
        ? order.deliveryAttempts
        : [];

      const deliveryAttemptData = {
        timestamp: new Date().toLocaleString(),
        driver: driverName,
      };

      const updatedDeliveryAttempts = [
        ...currentDeliveryAttempts,
        deliveryAttemptData,
      ];

      // Update the order document with the delivery attempt data
      await updateDoc(orderRef, {
        deliveryAttempts: updatedDeliveryAttempts,
      });

      await updateOrderDeliveryAttempts(order.id, driverName);
    } catch (error) {
      console.error("Error recording delivery attempt:", error);
      setError(error.message);
    }
  };

  return (
    <div>
      <Section
        key={order.id}
        title={`Order Number: ${order.orderNumber}`}
        className="mb-2 order-card-styling"
        rightElement={<Badge>{order.status}</Badge>}
        compact={true}
        collapsible={true}
      >
        <SectionCard className="order-card-styling">
          <Row>
            <Col md={12}>
              <p>
                <strong>
                  Scheduled Delivery Date: {formatDate(order.deliveryDate)}
                </strong>
              </p>

              <br />
              <Tabs
                id="contactInfo"
                activeKey={contactKey}
                onSelect={(k) => setContactKey(k)}
                justify={true}
              >
                <Tab eventKey="customer" title="Customer Info">
                  <Card className="dashboard-col">
                    <p>
                      <strong>Customer Info</strong>
                    </p>
                    Customer Name:{" "}
                    {order
                      ? `${order.customerFirstName} ${order.customerLastName}`
                      : "N/A"}
                    <br />
                    Customer Address:{" "}
                    {order
                      ? `${order.customerAddress}, ${order.customerAddressCity}, ${order.customerAddressState} ${order.customerAddressZip}`
                      : "N/A"}
                    <br />
                    Company Name:{" "}
                    {order ? `${order.customerCompanyName}` : "N/A"} <br />
                    Customer Primary Phone:{" "}
                    {order ? `${order.customerPrimaryPhone}` : "N/A"}
                    <br />
                    <br />
                  </Card>
                </Tab>

                <Tab eventKey="forwarder" title="Forwarder Info">
                  <Card className="dashboard-col">
                    <p>
                      <strong>Forwarder Info</strong>
                    </p>
                    Company Name:{" "}
                    {order.forwarderCompanyName ||
                      (order.selectedForwarder &&
                        order.selectedForwarder.forwarderCompanyName) ||
                      "N/A"}
                    <br />
                    Forwarder Address:{" "}
                    {order.forwarderAddress
                      ? `${order.forwarderAddress}, ${order.forwarderAddressCity}, ${order.forwarderAddressState} ${order.forwarderAddressZip}`
                      : order.selectedForwarder
                      ? `${order.selectedForwarder.forwarderAddress}, ${order.selectedForwarder.forwarderAddressCity}, ${order.selectedForwarder.forwarderAddressState} ${order.selectedForwarder.forwarderAddressZip}`
                      : "N/A"}
                    <br />
                    Forwarder Primary Phone:{" "}
                    {order.forwarderPrimaryPhone ||
                      (order.selectedForwarder &&
                        order.selectedForwarder.forwarderPrimaryPhone) ||
                      "N/A"}
                    <br />
                    <br />
                  </Card>
                </Tab>
                <Tab eventKey="more" title="More">
                  <Card className="dashboard-col">
                    <p>Order Created: {formatDate(order.createdOn)}</p>
                    <p>Notes: {order.notes}</p>
                    <p>Assigned Driver: {order.assignedDriver}</p>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Tabs
                id="DetailsInfo"
                activeKey={detailsKey}
                onSelect={(k) => setDetailsKey(k)}
                justify={true}
              >
                <Tab eventKey="pieces" title="Pieces">
                  <Card className="dashboard-col">
                    <p>
                      <span>
                        <strong>Pieces: </strong>
                        <Badge pill>
                          {Array.isArray(order.pieces)
                            ? parseInt(
                                order.pieces.reduce(
                                  (totalQuantity, piece) =>
                                    totalQuantity +
                                    parseInt(piece.quantity, 10),
                                  0
                                ),
                                10
                              )
                            : 0}
                        </Badge>
                      </span>
                    </p>
                    {Array.isArray(order.pieces) &&
                      order.pieces.map((piece, index) => (
                        <Card
                          key={index}
                          className="dashboard-col mb-2 mt-2"
                          compact={true}
                        >
                          <Row>
                            <Col md={4}>Sku: {piece.sku}</Col>
                            <Col md={4}>Description: {piece.description}</Col>
                            <Col md={4}>Volume: {piece.volume}</Col>
                            <Col md={4}>Weight: {piece.weight}</Col>
                            <Col md={4}>Quantity: {piece.quantity}</Col>
                            <Col md={4}>Price: {piece.price}</Col>
                          </Row>
                        </Card>
                      ))}
                  </Card>
                </Tab>
                <Tab eventKey="fees" title="Fees">
                  {order.calculatedRates &&
                    Array.isArray(order.calculatedRates.fees) &&
                    order.calculatedRates.fees.length > 0 && (
                      <div>
                        <Card className="dashboard-col">
                          <p>
                            <strong>Fees</strong>
                          </p>
                          <ul className="p-0" style={{ listStyle: "none" }}>
                            {/* Include delivery fee and total charge in the list */}
                            <li>
                              <Row>
                                <Col md={6}>Delivery Fee:</Col>
                                <Col
                                  md={6}
                                >{`$${order.calculatedRates.deliveryFee.toFixed(
                                  2
                                )}`}</Col>
                              </Row>
                            </li>

                            {/* Include fees from calculatedRates.fees array */}
                            {order.calculatedRates.fees.map((fee, feeIndex) => (
                              <li key={feeIndex}>
                                <Row>
                                  <Col md={6}>{fee.name}:</Col>
                                  <Col md={6}>{`$${fee.price.toFixed(2)}`}</Col>
                                </Row>
                                {/* Add more properties as needed */}
                              </li>
                            ))}

                            <li>
                              <Row className="mt-2">
                                <Divider />
                                <Col md={6}>
                                  <p>
                                    <strong>Total Charge:</strong>
                                  </p>
                                </Col>
                                <Col
                                  md={6}
                                >{`$${order.calculatedRates.totalCharge}`}</Col>
                              </Row>
                            </li>
                          </ul>
                        </Card>
                      </div>
                    )}
                </Tab>
                <Tab eventKey="deliveryAttempts" title="Delivery Details">
                  <Card className="dashboard-col">
                    <h5>
                      Delivery Attempts:{" "}
                      {Array.isArray(order.deliveryAttempts)
                        ? order.deliveryAttempts.length
                        : 0}
                    </h5>
                    <DeliveryAttempts
                      order={order}
                      handleAttemptDelivery={handleAttemptDelivery}
                      handleAttemptedDelivery={handleAttemptedDelivery}
                      handleCaptureSignature={handleCaptureSignature}
                    />

                    <br />

                    <p>
                      <span>
                        Signature:{" "}
                        {order.signature ? (
                          <img
                            src={order.signature}
                            alt="Signature"
                            width={200}
                            className="signature"
                          />
                        ) : (
                          <p>No signature on file</p>
                        )}
                      </span>
                    </p>
                  </Card>
                </Tab>
                <Tab eventKey="invoice" title="Invoicing Info">
                  <Card className="dashboard-col">
                    <p>
                      <strong>Invoicing Info</strong>
                    </p>
                    View Invoice:{" "}
                    {order && order.invoice && order.invoice.fileName ? (
                      <a
                        href={order.invoice.downloadURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {order.invoice.fileName}
                      </a>
                    ) : (
                      "No invoice attached"
                    )}{" "}
                    <br />
                    <br />
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-3"
                        style={{ minWidth: "100px", maxWidth: "500px" }}
                      >
                        <Form.Label>Attach Invoice</Form.Label>
                        <Form.Control
                          type="file"
                          id="invoice"
                          size="sm"
                          name="invoice"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </Form.Group>
                    </Form>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <ButtonGroup fill={true} minimal={true}>
            <Button
              icon="eye-open"
              intent="primary"
              onClick={() => handleViewDetails(order)}
            >
              View
            </Button>{" "}
            <Button
              icon="edit"
              intent="warning"
              onClick={() => {
                handleEditOrder(order);
              }}
              disabled={order.status === "Invoiced" || order.status === "Paid"}
            >
              Edit
            </Button>{" "}
            <Button
              icon="trash"
              intent="danger"
              onClick={() => {
                handleDeleteOrder(order.id); // Pass orderId instead of the entire order
              }}
              disabled={role === "Driver"}
            >
              Delete
            </Button>{" "}
            {order.status === "Delivered" && (
              <Button
                intent="success"
                icon="application"
                onClick={() => handleCreateInvoice(order)}
              >
                Invoice
              </Button>
            )}
            {order.status === "Invoiced" && (
              <Button intent="success" onClick={() => handleMarkAsPaid(order)}>
                Mark Paid
              </Button>
            )}
          </ButtonGroup>
        </SectionCard>
      </Section>

      <ConfirmationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={confirmMarkAsPaid}
      />
    </div>
  );
};

export default OrderCardComponent;
