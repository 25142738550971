import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { getInvoicedOrders } from "../../javascript/api";
import { OverlayTrigger, Col, Row } from "react-bootstrap";
import {
  Button,
  Card,
  Section,
  SectionCard,
  Icon,
  Tooltip,
} from "@blueprintjs/core";
import { updateDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebase";
import Pagination10 from "../Pagination10";
import formatDate from "../../utility/formatDate";
import ConfirmationModal from "../ConfirmationModal";
import { useNavigate } from "react-router-dom";

const InvoicedOrders = () => {
  const [invoicedOrders, setInvoicedOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [ordersPerPage, setOrdersPerPage] = useState(3);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const containerRef = useRef(null); // Ref for the container element

  const handleMarkAsPaid = (order) => {
    setSelectedOrder(order);
    setShowModal(true); // Show the confirmation modal
  };

  const confirmMarkAsPaid = async () => {
    setShowModal(false); // Hide the modal
    try {
      const newStatus = "Complete";
      await updateOrderStatus(selectedOrder, newStatus);
    } catch (error) {
      console.error("Error marking as paid:", error);
    }
  };

  const updateOrderStatus = async (order, newStatus) => {
    if (!order || !order.id) {
      throw new Error("Invalid or missing order data");
    }

    const orderRef = doc(firestore, "orders", order.id);
    await updateDoc(orderRef, { status: newStatus });
  };

  useEffect(() => {
    const fetchInvoicedOrders = async () => {
      try {
        const orders = await getInvoicedOrders();
        setInvoicedOrders(orders);
      } catch (error) {
        console.error("Error fetching logged-in user's orders:", error);
      }
    };

    fetchInvoicedOrders();
  }, []);

  // Calculate the indexes of the orders to display for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = invoicedOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the top of the container element
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const resultCount =
    invoicedOrders.length > 0
      ? `${Math.min(indexOfFirstOrder + 1, invoicedOrders.length)}-${Math.min(
          indexOfLastOrder,
          invoicedOrders.length
        )} of ${invoicedOrders.length}`
      : "";

  return (
    <>
      <div ref={containerRef}>
        {currentOrders.length === 0 ? (
          <Card>
            <div className="text-center">
              <p>There are no unpaid invoices.</p>
            </div>
          </Card>
        ) : (
          <>
            {currentOrders.map((order) => (
              <Section
                key={order.id}
                title={`Amount Due: $${order.calculatedRates.totalCharge}`}
                className="mb-2"
                compact={true}
                collapsible={true}
                rightElement={
                  <Button
                    minimal={true}
                    intent="primary"
                    onClick={() => navigate(`/view_invoice/${order.id}`)}
                    text="View Invoice"
                  />
                }
              >
                <SectionCard>
                  <p>
                    <strong>Order Number: </strong>
                    <Link to={`/view_invoice/${order.id}`}>
                      {order.orderNumber}{" "}
                      <Tooltip content="View Order">
                        <Icon icon="open-application" intent="primary" />
                      </Tooltip>
                    </Link>
                  </p>
                  <p>
                    <strong>Status: </strong>
                    {order.status}
                  </p>
                  <p>
                    <strong>Delivered On: </strong>
                    {formatDate(order.deliveryDate)}
                  </p>
                  <p>
                    <strong>Forwarder Company Name: </strong>
                  </p>
                  <p>{order.selectedForwarder.forwarderCompanyName}</p>

                  <p>
                    <strong>Forwarder Name: </strong>
                    {order.forwarderFirstName} {order.forwarderLastName}
                  </p>

                  <p>
                    <strong>Forwarder Address: </strong>
                  </p>
                  <p>{order.selectedForwarder.forwarderAddress}</p>
                  <p>
                    {order.selectedForwarder.forwarderAddressCity},{" "}
                    {order.selectedForwarder.forwarderAddressState}{" "}
                    {order.selectedForwarder.forwarderAddressZip}
                  </p>
                  <p>
                    <strong>Forwarder Primary Phone: </strong>
                    {order.selectedForwarder.forwarderPrimaryPhone}
                  </p>
                  {/* Add more order details as needed */}
                  <div className="d-flex justify-content-between">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Marks order complete <br /> <strong>Caution:</strong>{" "}
                          permanent action.
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() => handleMarkAsPaid(order)}
                        intent="success"
                      >
                        Mark Paid
                      </Button>
                    </OverlayTrigger>
                  </div>
                </SectionCard>
              </Section>
            ))}
          </>
        )}

        <div className="container">
          {resultCount && <p>Showing results {resultCount}</p>}
          {invoicedOrders.length > ordersPerPage && (
            <Pagination10
              currentPage={currentPage}
              ordersPerPage={ordersPerPage}
              totalOrders={invoicedOrders.length}
              paginate={paginate}
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={confirmMarkAsPaid}
      />
    </>
  );
};

export default InvoicedOrders;
