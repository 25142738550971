import React, { useEffect, useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import ConfirmDeleteUser from "../ConfirmDeleteUser";

import {
  Button,
  Dialog,
  Classes,
  HTMLSelect,
  FormGroup,
  InputGroup,
  ButtonGroup,
  Section,
  SectionCard,
} from "@blueprintjs/core";

const GetUsers = ({ onUserCount }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        const userData = [];
        querySnapshot.forEach((doc) => {
          const user = { id: doc.id, ...doc.data() };
          if (user && user.role !== "Admin") {
            userData.push(user);
          }
        });

        setUsers(userData);
        onUserCount(userData.length);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleDeleteUser = (userId) => {
    setUserIdToDelete(userId);
    setShowConfirmationModal(true);
  };

  const cancelDeleteUser = () => {
    setShowConfirmationModal(false);
  };

  const confirmDeleteUser = async () => {
    try {
      await deleteDoc(doc(firestore, "users", userIdToDelete));
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userIdToDelete)
      );
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleEditUser = (selectedUserId) => {
    const userToEdit = users.find((user) => user.id === selectedUserId);
    setSelectedUser(userToEdit);
    setShowEditModal(true);
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      await updateDoc(doc(firestore, "users", updatedUser.id), updatedUser);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        )
      );
      setShowEditModal(false);
      setSelectedUser(null);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div>
      {users.map((user) => (
        <Section
          key={user.id}
          title={`${user.firstName} ${user.lastName}`}
          className="mb-2"
          compact={true}
          collapsible={true}
          rightElement={
            <Button
              minimal={true}
              intent="primary"
              onClick={() => handleEditUser(user.id)}
              text="Edit User"
            />
          }
        >
          <SectionCard>
            <p>
              <strong>Email: </strong>
              {user.email}
            </p>{" "}
            <p>
              <strong>Role: </strong>
              {user.role}
            </p>
            <p>
              <strong>Status: </strong>
              {user.status}
            </p>{" "}
          </SectionCard>
          <ButtonGroup minimal={true}>
            <Button
              intent="danger"
              icon="trash"
              onClick={() => handleDeleteUser(user.id)}
            >
              Delete
            </Button>
          </ButtonGroup>
        </Section>
      ))}

      {/* Edit User Modal */}

      <Dialog
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Edit User"
        icon="edit"
        className={isDarkMode ? "bp5-dark" : ""}
        canOutsideClickClose={true}
        canEscapeKeyClose={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="First Name" labelFor="editUserFirstName">
            <InputGroup
              id="editUserFirstName"
              placeholder={selectedUser?.firstName || ""}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  firstName: e.target.value,
                }))
              }
            />
          </FormGroup>
          <FormGroup label="Last Name" labelFor="editUserLastName">
            <InputGroup
              id="editUserLastName"
              placeholder={selectedUser?.lastName || ""}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  lastName: e.target.value,
                }))
              }
            />
          </FormGroup>
          <FormGroup label="Email" labelFor="editUserEmail">
            <InputGroup
              id="editUserEmail"
              type="email"
              value={selectedUser?.email || ""}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  email: e.target.value,
                }))
              }
            />
          </FormGroup>
          <FormGroup label="Role" labelFor="editUserRole">
            <HTMLSelect
              id="editUserRole"
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  role: e.target.value,
                }))
              }
            >
              <option value="Manager">Manager</option>
              <option value="Driver">Driver</option>
              <option value="Admin">Web Master</option>
            </HTMLSelect>
          </FormGroup>
          <FormGroup label="Status" labelFor="editUserStatus">
            <HTMLSelect
              id="editUserStatus"
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  status: e.target.value,
                }))
              }
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </HTMLSelect>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent="primary"
              minimal={true}
              onClick={() => setShowEditModal(false)}
            >
              Cancel
            </Button>
            <Button
              intent="success"
              minimal={true}
              onClick={() => handleUpdateUser(selectedUser)}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Dialog>
      <ConfirmDeleteUser
        show={showConfirmationModal}
        onHide={cancelDeleteUser}
        onConfirm={confirmDeleteUser}
      />
    </div>
  );
};

export default GetUsers;
