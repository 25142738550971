import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Form as BootstrapForm,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import SuccessToast from "../SuccessToast";

const CustomerDetails = () => {
  const initialValues = {
    customerCompanyName: "",
    customerFirstName: "",
    customerLastName: "",
    customerPickupAddress: "",
    customerPickupAddressCity: "",
    customerPickupAddressState: "",
    customerPickupAddressZip: "",
    customerAddress: "",
    customerAddressCity: "",
    customerAddressState: "",
    customerAddressZip: "",
    customerPrimaryPhone: "",
    customerSecondaryPhone: "",
    status: "",
  };

  const [showToast, setShowToast] = useState(false);
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || "",
    }));
  };

  const validationSchema = Yup.object().shape({
    customerCompanyName: Yup.string(),
    customerFirstName: Yup.string().required("Customer first name is required"),
    customerLastName: Yup.string().required("Customer last name is required"),
    customerPickupAddress: Yup.string().required("Pickup address is required"),
    customerPickupAddressCity: Yup.string().required("Pickup city is required"),
    customerPickupAddressState: Yup.string().required(
      "Pickup state is required"
    ),
    customerPickupAddressZip: Yup.string().required(
      "Pickup zipcode is required"
    ),
    customerAddress: Yup.string().required("Address is required"),
    customerAddressCity: Yup.string().required("City is required"),
    customerAddressState: Yup.string().required("State is required"),
    customerAddressZip: Yup.string().required("Zipcode is required"),
    customerPrimaryPhone: Yup.string().required(
      "Customer primary number is required"
    ),
    customerSecondaryPhone: Yup.string(),
  });

  const addCustomer = async (values) => {
    try {
      const docRef = await addDoc(collection(firestore, "customers"), {
        customerCompanyName: values.customerCompanyName,
        customerFirstName: values.customerFirstName,
        customerLastName: values.customerLastName,
        customerPickupAddress: values.customerPickupAddress,
        customerPickupAddressCity: values.customerPickupAddressCity,
        customerPickupAddressState: values.customerPickupAddressState,
        customerPickupAddressZip: values.customerPickupAddressZip,
        customerAddress: values.customerAddress,
        customerAddressCity: values.customerAddressCity,
        customerAddressState: values.customerAddressState,
        customerAddressZip: values.customerAddressZip,
        customerPrimaryPhone: values.customerPrimaryPhone,
        customerSecondaryPhone: values.customerSecondaryPhone,
        status: values.status,
      });

      setShowToast(true);
      setFormData(initialValues);
    } catch (error) {
      console.error("Error submitting customer details:", error);
    }
  };

  return (
    <div className="pt-3">
      <h2>Customer Details</h2>
      <SuccessToast showToast={showToast} setShowToast={setShowToast} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          addCustomer(values);
        }}
      >
        {({ values, handleChange }) => (
          <Form className="mb-3">
            <Row>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerCompanyName"
              >
                <BootstrapForm.Label>Company Name</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  name="customerCompanyName"
                  value={values.customerCompanyName}
                  as={BootstrapForm.Control}
                />
                <ErrorMessage name="customerFirstName" component="div" />
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerPrimaryPhone"
              >
                <BootstrapForm.Label>Primary Phone</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  name="customerPrimaryPhone"
                  value={values.customerPrimaryPhone}
                  as={BootstrapForm.Control}
                />
                <ErrorMessage name="customerPrimaryPhone" component="div" />
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerSecondaryPhone"
              >
                <BootstrapForm.Label>Secondary Phone</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  name="customerSecondaryPhone"
                  value={values.customerSecondaryPhone}
                  as={BootstrapForm.Control}
                />
                <ErrorMessage name="customerSecondaryPhone" component="div" />
              </BootstrapForm.Group>
            </Row>
            <Row className="mb-3">
              <BootstrapForm.Group
                as={Col}
                md="6"
                controlId="customerFirstName"
              >
                <BootstrapForm.Label>Customer First Name</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  name="customerFirstName"
                  value={values.customerFirstName}
                  as={BootstrapForm.Control}
                />
                <ErrorMessage name="customerFirstName" component="div" />
              </BootstrapForm.Group>

              <BootstrapForm.Group as={Col} md="6" controlId="customerLastName">
                <BootstrapForm.Label>Customer Last Name</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  name="customerLastName"
                  value={values.customerLastName}
                  as={BootstrapForm.Control}
                />
                <ErrorMessage name="customerLastName" component="div" />
              </BootstrapForm.Group>
            </Row>

            <Row className="mb-3">
              <BootstrapForm.Label>Pickup Address:</BootstrapForm.Label>
              <BootstrapForm.Group controlId="customerPickupAddress">
                <BootstrapForm.Label>Address</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerPickupAddress}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage name="customerPickupAddress" component="div" />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerPickupAddressCity"
              >
                <BootstrapForm.Label>City</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerPickupAddressCity}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage
                  name="customerPickupAddressCity"
                  component="div"
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerPickupAddressState"
              >
                <BootstrapForm.Label>State</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerPickupAddressState}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage
                  name="customerPickupAddressState"
                  component="div"
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerPickupAddressZip"
              >
                <BootstrapForm.Label>Zip</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerPickupAddressZip}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage name="customerPickupAddressZip" component="div" />
              </BootstrapForm.Group>
            </Row>

            <Row className="mb-3">
              <BootstrapForm.Label>Customer Address:</BootstrapForm.Label>
              <BootstrapForm.Group controlId="customerAddress">
                <BootstrapForm.Label>Address</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerAddress}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage name="customerAddress" component="div" />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerAddressCity"
              >
                <BootstrapForm.Label>City</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerAddressCity}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage name="customerAddressCity" component="div" />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerAddressState"
              >
                <ErrorMessage name="customerAddressState" component="div" />
                <BootstrapForm.Label>State</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerAddressState}
                  as={BootstrapForm.Control}
                  required
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Col}
                md="4"
                controlId="customerAddressZip"
              >
                <BootstrapForm.Label>Zip</BootstrapForm.Label>
                <Field
                  size="sm"
                  type="text"
                  value={values.customerAddressZip}
                  as={BootstrapForm.Control}
                  required
                />
                <ErrorMessage name="customerAddressZip" component="div" />
              </BootstrapForm.Group>
            </Row>
            <Col md={4}>
              <BootstrapForm.Label>Customer Status</BootstrapForm.Label>
              <BootstrapForm.Select
                name="status"
                value={values.status}
                onChange={handleChange}
                required
                size="sm"
              >
                <option value="" disabled>
                  Choose customer status
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </BootstrapForm.Select>
              <ErrorMessage name="status" component="div" className="error" />
            </Col>

            <Stack gap={2} className="col-md-5 mx-auto mb-3 mt-3">
              <Button type="submit" variant="primary">
                Add Customer
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomerDetails;
